import React from "react";
import GiftSection7 from "../GIftSection/GiftSection7";

const GiftSectionMain = ({  product, socialmedia, gift_section , company}) => {
  switch (gift_section) {
    case "GS1":
      return <GiftSection7 product={product}  company={company} socialmedia={socialmedia} />;
 
    default:
      return <GiftSection7 product={product} company={company} socialmedia={socialmedia} />;
  }
};

export default GiftSectionMain;
