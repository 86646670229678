import { createSlice } from "@reduxjs/toolkit";

const removeAddress = createSlice({
  name: "address",
  initialState: {
    address: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    removeAddressStart: (state) => {
      state.isFetching = true;
    },
    removeAddressSuccess: (state, action) => {
      state.isFetching = false;
      state.address = action.payload;
    },
    removeAddressFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    emptyRemoveAddress: (state, action) => {
      state.address = action.payload;
    }
  },
});

export const {
  removeAddressStart,
  removeAddressSuccess,
  removeAddressFail,
  emptyRemoveAddress
} = removeAddress.actions;
export default removeAddress.reducer;
