import React from "react";
import ProductsAndService1 from "../Products & Services/ProductsAndService1";
const ProductsAndServiceMain = ({product_service , product_type}) => {
  switch (product_type) {
    case "PS1":
      return <ProductsAndService1 product_service={product_service} />;

    default:
      return <ProductsAndService1 product_service={product_service} />;
  }
};

export default ProductsAndServiceMain;
