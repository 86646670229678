import React, {useState} from "react";
import CheckoutStepper from "../Checkout/CheckoutStepper";
import CheckoutCard from "../Checkout/CheckoutCard";
import styled from "styled-components";

const CheckoutMain = () => {

  const [totalAmount, setTotalAmount] = useState(0);

  return (
    <CheckoutMainAlign>
      <div className="checkout_main">
        <div className="Checkout_Left">
          <CheckoutStepper totalAmount={totalAmount} />
        </div>
        <div className="Checkout_Right">
          <CheckoutCard setTotalAmount={setTotalAmount} totalAmount={totalAmount} />
        </div>
      </div>
    </CheckoutMainAlign>
  );
};

export default CheckoutMain;

const CheckoutMainAlign = styled.div`
 overflow-x: hidden;

margin: 5% 0 10% 0;

  .checkout_main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .Checkout_Left {
    width: 70%;
  }

  .Checkout_Right {
    width: 28%;
    
  }

  @media screen and (max-width: 500px) {
    .checkout_main {
      display: grid;
      grid-column: 1fr;
    }

    .Checkout_Left {
      width: 90% !important;
      padding: 0;
    }
    .Checkout_Right {
      width: 90% !important;
      padding: 5px;
    }
  }

  


`;
