import React from "react";
import OurFavourites from "../Our Favourites/OurFavourites";


const OurFavouriteMain = ({parallax_type, favourites}) => {
  switch (parallax_type) {
    case "OfferBanner":
      return <OurFavourites favourites={favourites}/>;

    default:
      return <OurFavourites />;
  }
};

export default OurFavouriteMain;

