import React, {useState} from "react";
import styled from "styled-components";
import image1 from "../../Assets/Images/GirlsHub Template/cat-1.png";
import image2 from "../../Assets/Images/GirlsHub Template/cat-2.png";
import image3 from "../../Assets/Images/GirlsHub Template/cat-3.png";
import image4 from "../../Assets/Images/GirlsHub Template/cat-4.png";
import HoverImage from "../../Assets/Images/GirlsHub Template/girls.png";
import { Link } from "react-router-dom";
import {Button} from "antd";
import zIndex from "@mui/material/styles/zIndex";
import API from "../../ApiService/ApiService"

const TC10 = ({category}) => {
  // console.log("category", category)
  const api = new API()
  const [hover, setHover] = useState(false);

  // const category = [
  //   {
  //     image: image3,
  //     name: "Maternity Leggins",
  //   },
  //   {
  //     image: image2,
  //     name: "Maternity Leggins",
  //   },
  //   {
  //     image: image3,
  //     name: "Maternity Leggins",
  //   },
  //   {
  //     image: image4,
  //     name: "Maternity Leggins",
  //   },
  // ];

  return (
    <CategoryAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{category?.section_title}</p>
        </div>
        <div className="Category_Main">
          {category?.category_id?.map((item, index) => (
            <Link key={index} to={`/Products?catName=${item?.name
              ?.replace(" ", "-")
              ?.toLowerCase()
              ?.replace(/ /g, "")
              ?.replace(/[^\w-]+g/, "")}`}>
              <div
                className="Category_Box"
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(null)}
                key={index}
              >
                <div
                  className="Category_Image"
                  style={{
                    borderRadius: hover === index ? "380px" : "0",
                    background:
                      hover === index
                        ? "linear-gradient(0deg, rgba(99, 97, 97, 0.5) 0%, rgba(133, 128, 128, 0.5) 100%), #cfcbcb 50% / cover no-repeat"
                        : "none",
                        zIndex: hover === index ? -1 : 0
                  }}
                >
                  <img src={api?.rootUrl1 + item?.image} alt="" />
                  {hover === index && (
                    <div className="Category_Hover_Image">
                      <img src={HoverImage} alt="" />
                    </div>
                  )}
                </div>
                <div className="Category_Name">
                  <p className="Name">{item?.name}</p>
                </div>
                {hover === index && (
                  <div className="Category_Button">
                    <Button className="Button">Shop Now</Button>
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </CategoryAlign>
  );
};

export default TC10;

const CategoryAlign = styled.div`
  .home_wrapper {
    padding: 0 40px;
  }

  .Top_Title .Title {
    color: #000;
    font-family: Moneta;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .Category_Main {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
  }

  .Category_Box {
    width: 300px;
    height: 480px;
    position: relative;
  }

  .Category_Image {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }

  .Category_Hover_Image {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Category_Name .Name {
    color: #fff;
    text-align: center;
    font-family: Moneta;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    position: absolute;
    bottom: 150px;
    left: 0;
    right: 0;
  }

  .Category_Button {
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }

  .Category_Button .Button {
    width: 130px;
    height: 40px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: #fff;
    color: #2f4076;
    border: none;
  }

  .Category_Button .Button:hover {
    background-color: #fff;
    color: #2f4076;
  }
`;
