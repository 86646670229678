import { createSlice } from "@reduxjs/toolkit";

const banner = createSlice({
  name:'banner',
  initialState:{
    banner:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    bannerGetAllStart:(state)=>{
      state.isFetching = true;
    },
    bannerGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.banner = action.payload;
    },
    bannerGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {bannerGetAllStart,bannerGetAllSuccess,bannerGetAllFail} = banner?.actions;
export default banner.reducer;



