import { createSlice } from "@reduxjs/toolkit";

const aboutus = createSlice({
  name: "aboutus",
  initialState: {
    aboutus: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    registerGetAllStart: (state) => {
      state.isFetching = true;
    },
    registerGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.aboutus = action.payload;
    },
    registerGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    aboutusGetAllStart: (state) => {
      state.isFetching = true;
    },
    aboutusGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.aboutus = action.payload;
    },
    aboutusGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    logoutaboutus: (state, action) => {
      state.aboutus = action.payload;
    },
  },
});

export const {
  aboutusGetAllStart,
  aboutusGetAllSuccess,
  aboutusGetAllFail,
  logoutaboutus,
  registerGetAllStart,
  registerGetAllSuccess,
  registerGetAllFail,
} = aboutus?.actions;
export default aboutus.reducer;
