import {createSlice} from "@reduxjs/toolkit";

const profile = createSlice({
  name: "profile",
  initialState: {
    profile: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    profileGetAllStart: (state) => {
      state.isFetching = true;
    },
    profileGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.profile = action.payload;
    },
    profileGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {profileGetAllStart, profileGetAllSuccess, profileGetAllFail} =
  profile.actions;
export default profile.reducer;
