import React from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import {Button, Rate} from "antd";
import pro1 from "../../Assets/Images/GirlsHub Template/pro-1.png";
import pro2 from "../../Assets/Images/GirlsHub Template/pro-2.png";
import pro3 from "../../Assets/Images/GirlsHub Template/pro-3.png";
import pro4 from "../../Assets/Images/GirlsHub Template/pro-4.png";
import styles from "../../ApiService/Data";
import API from "../../ApiService/ApiService";

let user = "";

const FP10 = ({featureProduct}) => {
  // console.log("featureProduct", featureProduct);

  const getUser = localStorage.getItem("user");

  const api = new API();

  const navigate = useNavigate();

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  const Featured_Products = [];

  if (featureProduct && featureProduct?.product_id) {
    featureProduct?.product_id.forEach((item) => {
      // console.log("item", item?.product_id[0]?._id);
      if (
        !Featured_Products.some(
          (newItem) => newItem?.product_id?._id === item?.product_id?._id
        )
      ) {
        Featured_Products.push(item);
      }
    });
  }

  // console.log("Featured_Products", Featured_Products);
  const FavouritiesProduct = [
    {
      image: pro1,
      title: "Pregnancy Printed Leggings",
      MRP: "2,499",
      SP: "1,799",
      rating: "4",
      ratingCount: "3.4",
      offer: "30% oFF",
    },
    {
      image: pro2,
      title: "Pregnancy Printed Leggings",
      MRP: "2,499",
      SP: "1,799",
      rating: "4",
      ratingCount: "4.5",
    },
    {
      image: pro3,
      title: "Pregnancy Printed Leggings",
      MRP: "2,499",
      SP: "1,799",
      rating: "4",
      ratingCount: "4.4",
    },
    {
      image: pro4,
      title: "Pregnancy Printed Leggings",
      MRP: "2,499",
      SP: "1,799",
      rating: "4",
      ratingCount: "3",
    },
  ];

  return (
    <FavouritesAlign>
      <div className="home_wrapper">
        <div className="Top_Section">
          <div className="Left">{featureProduct?.section_title}</div>
          <div className="Right">
            <Button className="Button">
              View all{" "}
              <svg
                className="svg"
                width="25"
                height="8"
                viewBox="0 0 33 8"
                fill="#000"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.3536 4.35355C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464466C28.9763 0.269204 28.6597 0.269204 28.4645 0.464466C28.2692 0.659728 28.2692 0.976311 28.4645 1.17157L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53553C28.6597 7.7308 28.9763 7.7308 29.1716 7.53553L32.3536 4.35355ZM0 4.5H32V3.5H0V4.5Z"
                  fill="black"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="Fav_Product_Main">
          {Featured_Products?.map((item, index) => (
            <>
              <div className="Fav_Product_Box" key={index} onClick={() => navigate(`/product/${item?.product_id?._id}`)}>
                <div className="Fav_Product_Image">
                  <img src={api?.rootUrl1 + item?.product_id?.images} alt="" />
                </div>
                <div className="Fav_Product_Title">
                  <span>{item.title}</span>
                </div>
                <div className="Prices">
                  <span className="Sp">{styles?.currency + item?.sp}</span>
                  <span className="Mrp">{styles?.currency + item?.mrp}</span>
                </div>
                <div className="Fav_Product_Rating">
                  <span>
                    <Rate
                      value={item?.rating}
                      defaultValue={item?.rating}
                      allowHalf
                    />
                  </span>
                  <span>{item?.ratingCount}</span>
                </div>
                {item?.offer && (
                  <div className="Top_offer">
                    <span className="Offer_Badge">{item?.offer}</span>
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    </FavouritesAlign>
  );
};

export default FP10;

const FavouritesAlign = styled.div`
  .home_wrapper {
    padding: 0 60px;
  }

  .Top_Section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
  }

  .Top_Section .Left {
    color: #000;
    font-family: Moneta;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .Top_Section .Right .Button {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    text-transform: uppercase;
    border: none;
    outline: none;
  }

  .Top_Section .Right .svg {
    margin-left: 5px;
    color: #000;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .Fav_Product_Main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }

  .Fav_Product_Main .Fav_Product_Box {
    line-height: 30px;
  }

  .Fav_Product_Main .Fav_Product_Title {
    color: #000;
    font-family: Moneta;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 10px;
  }

  .Fav_Product_Main .Prices .Sp {
    color: #f75d81;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    padding: 0 10px 0 0;
  }

  .Fav_Product_Main .Prices .Mrp {
    color: #9c9c9c;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 12.154px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-decoration-line: line-through;
  }

  .ant-rate {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000;
    /* color: #fadb14; */
    font-size: 15px;
    line-height: unset;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    display: inline-block;
    outline: none;
  }

  .Top_offer {
    width: 91.301px;
    /* height: 36.255px; */
    flex-shrink: 0;
    border-radius: 24px;
    background: #fff;
    position: relative;
    top: -460px;
    left: 185px;
    display: grid;
    place-items: center;
    height: 30px;
  }

  .Top_offer .Offer_Badge {
    color: #2f4076;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
  }
`;


