import React, {useRef} from "react";
import styled from "styled-components";
import API from "../../ApiService/ApiService";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const AboutUsFurniture = ({  About_us }) => {
// console.log("About_us-->", About_us);
 const api = new API();
  const swiperRef = useRef(null);

 

  return (
    <AboutUsAlign>
      <div className="home_wrapper">
        <div className="About_Main">
          <div className="Top_Tile">
            <p className="Title">{About_us?.section_title}</p>
          </div>
          <div className="Mid_Tile">
            <p className="Title">{About_us?.subtitle}</p>
          </div>
          <div className="Content">
            <p>
              {About_us?.description}
            </p>
          </div>
          <div className="Slider_Section">
            {/* <div className="Left">
              <svg
                onClick={() => swiperRef?.current?.slidePrev()}
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.0908 17.6505H6.69708M6.69708 17.6505C6.69708 17.6505 13.0447 13.476 14.3345 10.0131M6.69708 17.6505C6.69708 17.6505 12.3086 21.573 14.3345 25.288"
                  stroke="#1E1E1E"
                  strokeWidth="2.82869"
                  strokeLinecap="square"
                />
              </svg>
            </div> */}
            <div className="Slider_Container">
              <Swiper
                effect={"coverflow"}
                centeredSlides={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                // spaceBetween={20}
                // navigation={{
                //   Right: '.swiper-button-next',
                //   Left: '.swiper-button-prev',
                // }}
                pagination={{clickable: true}}
                autoplay={{delay: 5000}}
                slidesPerView={
                  window.innerWidth < 767 ? 1 : window.innerWidth < 1024 ? 2 : 3
                }
                modules={[EffectCoverflow, Navigation, Pagination, Autoplay]}
                className="mySwiper"
                draggable
                ref={swiperRef}
              >
                {About_us?.aboutus_id?.map((image, index) => (
                  <SwiperSlide key={index} className="Slide">
                    <img src={api.rootUrl1 + image?.image} alt={`slider-${index + 1}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            {/* <div className="Right">
              <svg
                onClick={() => swiperRef?.current?.slideNext()}
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.80078 18.005H28.1945M28.1945 18.005C28.1945 18.005 21.8469 13.8305 20.5571 10.3676M28.1945 18.005C28.1945 18.005 22.583 21.9275 20.5571 25.6425"
                  stroke="#1E1E1E"
                  strokeWidth="2.82869"
                  strokeLinecap="square"
                />
              </svg>
            </div> */}
          </div>
        </div>
      </div>
    </AboutUsAlign>
  );
};

export default AboutUsFurniture;

const AboutUsAlign = styled.div`
  .About_Main {
    width: 100%;
    height: 650px;
    flex-shrink: 0;
    background: #f4ffec;
    padding: 20px 0;

  }

  .About_Main .Top_Tile {
    color: #505050;
    text-align: center;
    font-family: Flama Trial;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 46.8px */
    letter-spacing: -0.36px;
    text-transform: capitalize;
  }

  .About_Main .Mid_Tile {
    color: #000;
    text-align: center;
    font-family: Flama Trial;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 62.4px */
    letter-spacing: -0.48px;
    text-transform: capitalize;
  }

  .About_Main .Content {
    width: 100%;
    flex-shrink: 0;
    color: #707070;
    text-align: center;
    font-family: Flama Trial;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    display: grid;
    place-content: center;
    padding: 0 40px;
  }

  .Slider_Section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }

  .Slider_Container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }

  .Slider_Section .Left {
    display: flex;
    padding: 15.558px;
    align-items: flex-start;
    gap: 14.143px;
    position: relative;
    left: 71.781px;
    /* bottom: 10px; */
    border-radius: 33.944px;
    background: #fff;
    box-shadow: 0px 9.149px 25.414px 0px rgba(0, 0, 0, 0.06);
  }

  .Slider_Section .Right {
    display: flex;
    padding: 15.558px;
    align-items: flex-start;
    gap: 14.143px;
    position: relative;
    right: 71.781px;
    /* bottom: 10px; */
    border-radius: 33.944px;
    background: #fff;
    box-shadow: 0px 9.149px 25.414px 0px rgba(0, 0, 0, 0.06);
  }

  .Slider_Section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .Slider {
    width: 100%;
    max-width: 800px; /* Adjust as needed */
    overflow: hidden;
    border-radius: 15px;
  }

  .Slider img {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }

  .swiper-container {
    width: 100%;
    overflow: hidden;
  }

  .swiper-wrapper {
    display: flex;
    transition: transform 0.5s ease;
  }

  .swiper-slide {
    width: 100%;
    flex-shrink: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 9.149px 25.414px 0px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: #1e1e1e;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  @media screen and (max-width: 767px) {
    .About_Main {
      min-height: 600px;
    }

    .About_Main .Top_Tile,
    .About_Main .Mid_Tile {
      font-size: 25px;
    }

    .About_Main .Mid_Tile {
      font-size: 35px;
    }

    .About_Main .Content {
      font-size: 20px;
    }

    .About_Main .Slider_Section {
      width: 100%;
      margin: 20% 0;
    }

    .About_Main .Slider_Section .Slider {
      border: 1px solid red !important;
    }

    .Slider_Section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .Slider_Container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .About_Main {
      min-height: 600px;
    }

    .About_Main .Top_Tile,
    .About_Main .Mid_Tile {
      font-size: 25px;
    }

    .About_Main .Mid_Tile {
      font-size: 35px;
    }

    .About_Main .Content {
      font-size: 20px;
    }
  }
`;
