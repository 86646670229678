import React from "react";
import styled from "styled-components";
import Image1 from "../../Assets/Images/Frame 9.jpg";
import Image2 from "../../Assets/Images/Frame 10.jpg";
import Image3 from "../../Assets/Images/Frame 11.jpg";
import Image4 from "../../Assets/Images/Frame 12.jpg";
import {Button} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import "../../Assets/Css/style.css";
import API from "../../ApiService/ApiService";

const ProductsAndService1 = ({product_service}) => {
  // console.log("product_service=---------->", product_service);

  const api = new API();

  return (
    <ProductServiceAlign>
      <div className="home_wrapper">
        <Section_Title>
          <div className="Section_Title">
            <p className="Left_Title">{product_service?.section_title}</p>
          </div>
        </Section_Title>
        <ProductService_Grid>
          <div className="ProductService_Grid">
            {product_service?.productservice_id?.map(
              (item, index) => (
                // console.log("image", item),
                (
                  <div key={index} className="ProductBox">
                    <div className="Service_Image">
                      <img src={api.rootUrl1 + item?.image} />
                    </div>
                    <div className="Service_Title">
                      <p>{item?.card_title}</p>
                    </div>
                    <div className="Service_Description">
                      <p>{item?.card_description}</p>
                    </div>
                    <div className="Service_Button">
                      <Button
                        href={item?.button_link}
                        className="Service_Button"
                        
                      >
                        {item?.button_text} <ArrowRightOutlined />
                      </Button>
                    </div>
                  </div>
                )
              )
            )}
          </div>
        </ProductService_Grid>
      </div>
    </ProductServiceAlign>
  );
};

export default ProductsAndService1;

const ProductServiceAlign = styled.div`
  .home_wrapper {
    max-width: 2000px;

  }
`;

const Section_Title = styled.div`
margin: 50px 0;
  text-align: center;
  margin-bottom: 30px;
  .Section_Title .Left_Title {
    color: #3d418b;
    font-family: "Poppins";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }

  .Section_Title .Left_Title span {
    color: #0174be;
    font-family: "Yellowtail";
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .Section_Title {
      /* display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 40px 70px; */
    }

    .Section_Title .Left_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }

    .Section_Title .Left_Title {
      text-align: center;
      color: #3d418b;
      font-family: "Poppins";
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .Section_Title .Left_Title span {
      color: #0174be;
      font-family: "Yellowtail";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .Section_Title {
      /* text-align: justify; */
      /* margin: 40px 70px; */
    }
  }
`;

const ProductService_Grid = styled.div`
  width: 100%;

  .ProductBox {
    padding: 10px;
    width: 286px;
    height: 340px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #f4f4f4;
  }
  .ProductService_Grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
    padding: 0 46px 0 46px;
  }

  .ProductBox .Service_Image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .ProductBox .Service_Image img {
    padding: 20px;
    border-radius: 10px;
  }

  .ProductBox .Service_Title {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 5px;
  }

  .ProductBox .Service_Description {
    /* width: 231px;
    height: 112px; */
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 200%;
  }

  .ProductBox .Service_Button {
    text-align: center;
  }

  .Service_Button {
    border: 1px solid red;
    color: #f6a82d;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
  }

  @media screen and (min-width: 360px) and (max-width: 692px) {
    .ProductService_Grid {
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      justify-content: center;
      justify-items: center;
    }

    .ProductBox {
    }
  }

  @media screen and (min-width: 692px) and (max-width: 767px) {
    .ProductService_Grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: space-evenly;
      gap: 30px;
      padding: 0 46px 0 46px;
    }

    .ProductBox {
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .ProductService_Grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-items: center;
      justify-content: center;
      gap: 30px;
      /* margin-right: 400px; */
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1023px) {
    .ProductService_Grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      gap: 30px;
      /* margin-right: 400px; */
    }
  }
`;
