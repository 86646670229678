import { createSlice } from "@reduxjs/toolkit";

const cities = createSlice({
  name: "cities",
  initialState: {
    cities: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    citiesGetAllStart: (state) => {
      state.isFetching = true;
    },
    citiesGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.cities = action.payload;
    },
    citiesGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { citiesGetAllStart, citiesGetAllSuccess, citiesGetAllFail } =
  cities?.actions;
export default cities.reducer;
