import React from "react";
import Header1 from "../Header/Header1";
import Header2 from "../Header/Header2";
import Header3 from "../Header/Header3";
import Header4 from "../Header/Header4";
import Header5 from "../Header/Header5";
import Header6 from "../Header/Header6";
import Header7 from "../Header/Header7";
import Header8 from "../Header/Header8";
import Header9 from "../Header/Header9";
import Header10 from "../Header/Header10";
import Header11 from "../Header/Header11";

const HeaderMain = ({
  header_type,
  socialmedia,
  home_custom,
  company,
}) => {

  // header_type = "Theme-3"
  // console.log("home_custom",home_custom)
  

  switch (header_type) {
    // case "Theme-1":
    //   return <Header1 socialmedia={socialmedia} />;
    // case "Theme-2":
    //   return <Header2 socialmedia={socialmedia} />;
    // case "Theme-3":
    //   return <Header3 socialmedia={socialmedia} />
    // case "Theme-4":
    //   return <Header4 socialmedia={socialmedia} />;
    // case "Theme-5":
    //   return <Header5 socialmedia={socialmedia} />;
    // case "Theme-6":
    //   return <Header6 socialmedia={socialmedia} />;
    case "Theme-1":
      return <Header7 socialmedia={socialmedia} home_custom={home_custom?.theme_1} company={company} />;
    case "Theme-2":
      return <Header8 socialmedia={socialmedia} home_custom={home_custom?.theme_2} />;
    case "Theme-3":
      return <Header9 socialmedia={socialmedia} home_custom={home_custom?.theme_3?.top_offer} />;
    case "Theme-4":
      return <Header10 socialmedia={socialmedia}  home_custom={home_custom?.theme_4}/>;
    case "Theme-5":
      return <Header11 socialmedia={socialmedia} />;

    // default:
      return (
        <Header10
          socialmedia={socialmedia}
        />
      );
  }
};

export default HeaderMain;
