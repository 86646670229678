import React, { useState, useEffect } from "react";
import styled from "styled-components";
import fp from "../../Assets/Images/Agri/fp.jpg";
import fpcart from "../../Assets/Images/Agri/fp_cart.png";
import fpbg from "../../Assets/Images/Agri/bg_1.png";
import fpbg1 from "../../Assets/Images/Agri/fp_bg.jpg";
import headbg from "../../Assets/Images/Agri/head_icon.png";
import { styles } from "../../ApiService/Data";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/default.png";
import { Link } from "react-router-dom";
import { base_url } from "../../config";

const FP5 = ({ featuredproduct_type }) => {
  return (
    <>
    {featuredproduct_type?.length > 1 ? (
  <FPSection>
    <div className="fp_section">
      <div className="wrapper">
        <div className="agri_head">
          <h1>Featured Products</h1>
        </div>
        <ul>
          {featuredproduct_type
            ?.slice(0, 8)
            .map((item) => {
              const category =
                item?.category[0]?.category_name;
              const description = item?.description;
              const productId = item?._id;

              const categoryUrl =
                category?.toLowerCase()?.replace(/ /g, "-")?.replace(/[^\w-]+/g, "");
              const descriptionUrl =
                description?.toLowerCase()?.replace(/ /g, "-")?.replace(/[^\w-]+/g, "");

              return (
                <li key={productId}>
                  <Link
                    to={`/${categoryUrl}/${descriptionUrl}?pid=${productId}`}
                  >
                    <img
                      src={
                        item?.prospecdocs[0]?.product_images[0]?.image_path
                          ? base_url +
                            item?.prospecdocs[0]?.product_images[0]?.image_path
                          : Default
                      }
                      alt="Products"
                    />
                  </Link>
                  <h4>{item?.product_name}</h4>
                  <div className="price_box">
                    <div className="left">
                      <span className="sp">
                        {styles?.currency}
                        {item?.prospecdocs[0]?.sp}
                      </span>
                      <span className="mrp">
                        {styles?.currency}
                        {item?.prospecdocs[0]?.mrp}
                      </span>
                    </div>
                    <div className="right">
                      <Link
                        to={`/${categoryUrl}/${descriptionUrl}?pid=${productId}`}
                      >
                        <img src={fpcart} alt="Cart" />
                      </Link>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  </FPSection>
) : (
  ""
)}

    </>
  );
};

export default FP5;

const FPSection = styled.section`
  * {
    font-family: ${styles?.r_regular};
  }
  width: 100%;
  display: inline-block;
  position: relative;
  .fp_section {
    width: 100%;
    display: inline-block;
    position: relative;
    background: url(${fpbg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top left;
    padding: 80px 0;
    &::before {
      content: "";
      background: url(${fpbg1});
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-position: top left;
      z-index: -1;
    }
  }
  .agri_head {
    width: 100%;
    display: inline-block;
    margin: 0 0 55px;

    h1,
    h2 {
      margin: 0 !important;
      font-family: ${styles?.r_regular} !important;
      font-weight: 600;
      letter-spacing: 0px;
      font-size: 37px;
      text-transform: uppercase;
      text-align: center;
      padding: 55px 0 0 0;
      position: relative;
      &::before {
        content: "";
        background: url(${headbg});
        background-repeat: no-repeat;
        position: absolute;
        height: 48px;
        width: 38px;
        background-size: contain;
        background-position: center top;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }

  .fp_section ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px 30px;
    padding: 0;
  }
  .fp_section ul li {
    width: 100%;
    display: inline-block;
  }
  .fp_section ul li img {
    width: 100%;
  }
  .fp_section ul li h4 {
    margin: 15px 0 25px 0 !important;
    font-size: 18px;
    text-align: center;
    padding: 0 20px;
    line-height: 1.4;
  }
  .fp_section .price_box img {
    width: auto;
    height: 30px;
    padding: 0 20px 0 0;
    cursor: pointer;
  }
  .fp_section .price_box {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .fp_section .price_box .left {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
  }
  .fp_section .price_box span.sp {
    font-size: 22px;
    font-weight: 600;
    color: #000;
  }
  .fp_section .price_box span.mrp {
    font-size: 16px;
    font-weight: 600;
    color: #959595;
  }

  @media screen and (max-width: 1200px) {
    .fp_section {
      padding: 60px 0;
    }
    .fp_section ul {
      gap: 40px 15px;
    }
  }

  @media screen and (max-width: 768px) {
    .fp_section {
      background: none;
      padding: 45px 0;
    }
    .agri_head {
      h1,
      h2 {
        font-size: 30px !important;
        padding: 42px 0 0 0;
      }
    }
    .agri_head {
      margin: 0 0 40px;
    }
    .agri_head h1::before,
    .agri_head h2::before {
      height: 37px;
    }
    .fp_section ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 480px) {
    .fp_section ul li h4 {
      margin: 10px 0 15px 0 !important;
      font-size: 13px;
      padding: 0 10px;
    }
    .fp_section .price_box span.sp {
      font-size: 17px;
    }
    .fp_section .price_box span.mrp {
      font-size: 13px;
    }
    .fp_section .price_box .left {
      gap: 3px;
    }
    .fp_section .price_box img {
      height: 23px;
      padding: 0 6px 0 0;
    }
  }
`;
