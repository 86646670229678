import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { RightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user)?.setColor;

const TC2 = ({ category }) => {
  return (
    <>
     <TopCategorySection>
        <section className="Temp3_Top_Category">
          <div className="wrapper">
            <H2>Popular Category</H2>
            <div className="Temp3_Top_Category_Align">
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={30}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                breakpoints={{
                  1200: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                    speed: 1500,
                  },
                  768: {
                    spaceBetween: 20,
                    slidesPerView: 2,
                  },
                  580: {
                    spaceBetween: 20,
                    slidesPerView: 2,
                  },
                  320: {
                    spaceBetween: 20,
                    slidesPerView: 1,
                  },
                }}
                className="Temp3_Top_Category_Slider"
              >
                {category?.slice(0, 8).map((e, i) => {
                  return (
                    <SwiperSlide key={`TC3_${i}`}>
                      <div className="Temp3_Top_Category_Box">
                        <div>
                          <h4>{e?.name}</h4>

                          <Link
                            to={`/${e?.name
                              .toLowerCase()
                              .replace(/[-\s]+/g, "-")
                              .replace(/[-\s]+/g, "-")}`}
                            className="Temp3_Top_Category_Link"
                          >
                            View All
                            <RightOutlined />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
      </TopCategorySection>
    </>
  )
}

export default TC2;


const H2 = styled.h2`
  font-size: ${styles?.h2};
  font-family: ${styles?.font} !important;
  color: ${colorCustom?.color ? colorCustom?.color : styles?.color} !important;
  line-height: 1.4;
  margin: 0 0 25px;
  text-align: center;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;
const TopCategorySection = styled.div`

.Temp3_Top_Category {
  display: inline-block;
  margin: 0px 0;
  width: 100%;
  position: relative;

}

.Temp3_Top_Category .Temp3_Top_Category_Align {
  list-style: none;
  width: 100%;
  padding: 0;
}
.Temp3_Top_Category .Temp3_Top_Category_Slider {
  padding: 0 10px;
}
.Temp3_Top_Category .Temp3_Top_Category_Align .Temp3_Top_Category_Box {
 box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    width: 100%;
    display: flex;
    margin: 15px 0 40px 0;
    padding: 20px 20px;
    text-align: center;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

.Temp3_Top_Category .Temp3_Top_Category_Align .Temp3_Top_Category_Box h4 {
  font-size: 25px;
  line-height: 1.5;
  margin: 0 0 10px;
  font-weight: 700;
  color: #000;
}
.Temp3_Top_Category .Temp3_Top_Category_Align .Temp3_Top_Category_Box .Temp3_Top_Category_Link {
  font-size: 14px;
  color: ${styles?.colorapi};
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  width: fit-content;
  margin: auto;
}
.Temp3_Top_Category .swiper-pagination {
  bottom: -6px !important;
}
.Temp3_Top_Category .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background: ${styles?.colorapi};
}

`