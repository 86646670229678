import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { styled } from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import { base_url } from "../../config";
import {Link} from 'react-router-dom'



const Banner6 = ({banner}) => {
  return (
    <BannerWrapper>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {banner?.map((e, i) => (
          <SwiperSlide key={`banner${i}`}>
            <Link>
              <img
                src={base_url + e?.image}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </BannerWrapper>
  );
};

export default Banner6;

const BannerWrapper = styled.section`
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 100%;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

 

`;
