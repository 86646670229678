import React from "react";
import Brand1 from "../Brand/Brand1";

const BrandMain = ({ brand_type, brand }) => {
  switch (brand_type) {
    case "Brand1":
      return <Brand1 brand={brand} />;
   
    default:
      return <Brand1 brand={brand} />;
  }
};

export default BrandMain;
