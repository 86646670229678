import React from "react";
import HomeProduct1 from "../HomeProductSection/HomeProduct1";
import HomeProduct2 from "../HomeProductSection/HomeProduct2";


const HomeProductMain = ({ Home_product, homecustom , product}) => {
  // console.log("homecustom", homecustom)
  switch (Home_product) {
    case "HP1":
      return <HomeProduct1 product={product} />;
    case "HP2":
      return <HomeProduct2 homecustom={homecustom} />;
   
    default:
      return <HomeProduct2 homecustom={homecustom} />;
  }
};

export default HomeProductMain;
