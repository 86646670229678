import React, { useEffect, useState } from 'react'
import { Button } from "antd";
import styled from 'styled-components';
import { styles } from '../../ApiService/Data'
import { Link } from 'react-router-dom'
import { RightCircleOutlined } from "@ant-design/icons";
import API from "../../ApiService/ApiService";
import Default from '../../Assets/Images/default.png';
import { base_url } from '../../config';

const Hc34 = ({props}) => {
  return (
    <>
     <Section>
                <Wrapper>
                    <HeadText>
                        <H2>{props?.title}</H2>
                        <Button>
                            View All
                            <RightCircleOutlined />
                        </Button>
                    </HeadText>
                    <Row>
                        <Col1>
                            {props?.content?.slice(0, 2).map((data) => {
                                return (
                                    <Box key={data?._id}>
                                        <BG>
                                            <Img src={data?.image ? base_url + data?.image : Default} />
                                        </BG>
                                        <Content>
                                            <Title>{data?.title}</Title>
                                            <Link to={data?.link}>
                                                <Button>{data?.link_text}</Button>
                                            </Link>
                                        </Content>
                                    </Box>
                                )
                            })}


                        </Col1>
                        <Col2>
                            {props?.content?.slice(2, 3).map((data) => {
                                return (
                                    <Box key={data?._id}>
                                        <BG>
                                            <Img src={base_url + data?.image} />
                                        </BG>
                                        <Content>
                                            <Title>{data?.title}</Title>
                                            <Link to={data?.link}>
                                                <Button>{data?.link_text}</Button>
                                            </Link>
                                        </Content>
                                    </Box>
                                )
                            })}
                        </Col2>
                        <Col3>
                            {props?.content?.slice(3, 5).map((data) => {
                                return (
                                    <Box key={data?._id}>
                                        <BG>
                                            <Img src={base_url + data?.image ? base_url + data?.image : Default} />
                                        </BG>
                                        <Content>
                                            <Title>{data?.title}</Title>
                                            <Link to={data?.link}>
                                                <Button>{data?.link_text}</Button>
                                            </Link>
                                        </Content>
                                    </Box>
                                )
                            })}
                        </Col3>
                    </Row>


                </Wrapper>
            </Section>
    </>
  )
}

export default Hc34;

const Section = styled.section`
width: 100%;
position: relative;
display: inline-block;
box-sizing: border-box;
`;
const Wrapper = styled.div`
max-width: 1200px;
padding: 0 10px;
margin: auto;
box-sizing: border-box;
`;

const HeadText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const H2 = styled.h2`
  color: ${styles.color};
  font-size: ${styles.h2};
`;

const Row = styled.div`
display: flex;
flex-wrap: wrap;
gap: 30px;
margin: 25px 0 0 0;
`;
const Col1 = styled.div`
flex: 1;
display: flex;
flex-direction: column;
gap: 30px;
`;
const Col2 = styled.div`
flex: 2;

`;
const Col3 = styled.div`
flex: 1;
display: flex;
flex-direction: column;
gap: 30px;
`;
const Box = styled.div`
border: 1px solid ${styles.light};
`;
const BG = styled.div``;
const Img = styled.img`
max-width: 100%;
`;
const Content = styled.div`
padding: 20px;
display: flex;
flex-direction: column;
gap: 15px;

button {
    width:fit-content
}
`;
const Title = styled.div`
font-size: 22px;
color: ${styles.color};
font-weight: 600;
`;