import React from "react";
import NewArrival1 from "../NewArrivals/NewArrival1";
import NewArrival2 from "../NewArrivals/NewArrival2";

const NewArrivalSectionMain = ({ new_arraival, banner, homecustom }) => {
  switch (new_arraival) {

    case "NA1":
      return <NewArrival1 homecustom={homecustom} />;
    case "NA2":
      return <NewArrival2 new_arraival={new_arraival} />;
   
    default:
      return <NewArrival2 new_arraival={new_arraival} />;
  }
};

export default NewArrivalSectionMain;
