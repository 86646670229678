import React from 'react'
import { styles } from '../../ApiService/Data';
import logo from "../../Assets/Images/logo.png"
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "../../ApiService/ApiService";
import { MailFilled, PhoneFilled, MobileFilled } from "@ant-design/icons";
import { Layout, Row, Col, Avatar, Tooltip, Space } from "antd";
import instgram from "../../Assets/Images/furniture/s2.png"
import facebook from "../../Assets/Images/furniture/s1.png"
import email from "../../Assets/Images/dry/email.png"
import address from "../../Assets/Images/dry/address.png"
import call from "../../Assets/Images/dry/call.png"
import { base_url } from '../../config';

const Footer2 = ({ company,socialmedia }) => {

  // console.log('COMPANY', company);

    const year = new Date().getFullYear();
    const api = new API();
    // const [socialMediaLink, setSocialMediaLink] = useState([]);
  
    const FooterLinks = [
      { path: "/", title: "Home" },
      { path: "/about", title: "About Us" },
      { path: "/contact", title: "Contact Us" },
      { path: "/enquiry", title: "Enquiry" },
    ];
  
    const OtherLinks = [
      { path: "/privacy-policy", title: "Privacy Policy" },
      { path: "/terms", title: "Terms and Conditions" },
      { path: "/refund-policy", title: "Refund Policy" },
      { path: "/delivery-policy", title: "Delivery Policy" },
      { path: "/return-policy", title: "Return Policy" },
      { path: "/cancellation-policy", title: "Cancellation Policy" },
    ];
  
  return (
    <>
     <FooterSection>
        <footer className="Temp2_Footer">
          <div className="Wrapper">
            <div className="footer_align">
              <div className="footer_1">
                <div className="footer-contact">
                  {company && (
                    <div className="address">
                      <div>
                        <img className="logo" src={base_url + company?.logo} />
                      </div>
                      <p>
                        {company?.company_addresss},
                         {company?.city},<br /> Pincode -
                        {company?.pin_code},
                        
                        {company?.state}.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="footer_2">
                <div className="footer-contact">
                  <div className="mail-phone">
                    <h3>Contact Info</h3>
                    {company?.email_id && (
                      <p>
                        <a href={"mailto:" + company?.email_id}>
                          <MailFilled style={{ color: "#434242" }} />{" "}
                          {company?.email_id}
                        </a>
                      </p>
                    )}
                   
                    {company?.mobile_number && (
                      <p>
                        <a href={"tel:+91" + company?.mobile_number}>
                          <MobileFilled style={{ color: "#434242" }} />{" "}
                          {company?.mobile_number}
                        </a>
                      </p>
                    )}
                  </div>
                 
                </div>
              </div>

              <div className="footer_3">
                <h3>Quick Links</h3>
                <ul className="quickLinks">
                  {OtherLinks?.map((e, i) => (
                    <li key={`qL_${i}`}>
                      <Link to={e?.path}>{e?.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="footer_4">
                {company?.map && (
                  <iframe
                    src={company?.map}
                    width="100%"
                    height="200"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
          <section className="bl-footer-1">
            &copy; {year} Designed by{" "}
            <a href="https://blazon.in" target="_blank">
              Blazon
            </a>
          </section>
        </footer>
      </FooterSection>
    </>
  )
}

export default Footer2;


const FooterSection = styled.div`



.Temp2_Footer {
  display: inline-block;
  padding: 40px 0 0px 0;
  position: relative;
  width: 100%;
  margin: 40px 0 0 0;
  background: ${styles?.light};
}
.Temp2_Footer .footer_align {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 50px;
}
.Temp2_Footer .footer_align .footer_1 {
  width: 22%;
  display: inline-block;
}
.Temp2_Footer .footer_align .footer_2 {
  width: fit-content;
  display: inline-block;
}
.Temp2_Footer .footer_align .footer_3 {
  width: fit-content;
  display: inline-block;
}
.Temp2_Footer .footer_align .footer_4 {
  width: 22%;
  display: inline-block;
}
.Temp2_Footer .footer_align a {
  display: flex;
  gap: 10px;
  align-items: center;
}
.Temp2_Footer .footer_align .footer_1 img {
  margin: 0 0 25px;
}



@media screen and (max-width:992px) {

.Temp2_Footer .footer_align .footer_1 {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 0 50px;
}
.Temp2_Footer .footer_align .footer_1 img {
  margin: auto auto 25px;
}



}

@media screen and (max-width:576px) {

.Temp2_Footer .footer_align .footer_4 {
  width: 100%;
  margin: 40px 0 0 0;
}
.Temp2_Footer .footer_align .footer_2 {
  width: 50%;
  padding: 0 10px 0 0;
}
.Temp2_Footer .footer_align .footer_3 {
  width: 50%;
  padding: 0 0 0 10px;
}



}









`;