import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/banner_default.png";
import { CaretRightFilled, CaretLeftFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { base_url } from '../../config'
import { styles } from "../../ApiService/Data";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Banner3 = ({ banner }) => {

  const CustomDot = ({ onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"}>
        <button onClick={() => onClick()}></button>
      </li>
    );
  };
  const CustomRight = ({ onClick }) => (
    <button className="bannerarrow right" onClick={onClick}>
      <CaretRightFilled />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="bannerarrow left" onClick={onClick}>
      <CaretLeftFilled />
    </button>
  );
  const [images, setImage] = useState([]);
  const api = new API();


  return (
    <>
   <BannerSection>
  <section className="Temp1_Banner">
    {banner && banner?.length > 0 ? (
      <Carousel
        infinite
        arrows={false}
        customLeftArrow={<CustomLeft />}
        customRightArrow={<CustomRight />}
        pauseOnHover
        showDots
        autoPlay
        partialVisible={true}
        itemClass="image-item"
        responsive={responsive}
        autoPlaySpeed={5000}
        slidesToSlide={1}
        swipeable
        dotListClass="bannerListDot"
        customDot={<CustomDot />}
      >
        {banner?.map((e, i) => {
          return (
            <Link to={e?.link} key={`ban_${i}`}>
              <img
                draggable={false}
                style={{ width: "100%", height: "100%" }}
                src={base_url + e?.image}
              />
            </Link>
          );
        })}
      </Carousel>
    ) : (
      <p>No banners available</p>
    )}
  </section>
</BannerSection>
    </>
  )
}

export default Banner3;

const BannerSection = styled.div`
  
.react-multi-carousel-track .react-multi-carousel-item.react-multi-carousel-item--active.image-item img {
  transition: all 12s ease-in-out;
	animation: h_zoom 50s ease-in-out infinite;
}

@-webkit-keyframes h_zoom {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.07);
	}
	50% {
		transform: scale(1);
	}
	75% {
		transform: scale(1.07);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes h_zoom {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.07);
	}
	50% {
		transform: scale(1);
	}
	75% {
		transform: scale(1.07);
	}
	100% {
		transform: scale(1);
	}
}

.bannerListDot {
  bottom: auto !important;
  position: absolute !important;
  right: 20px !important;
  top:50% !important;
  transform: translate(0, -50%) !important;
  left: auto !important;
  display: grid !important;
  gap: 5px;
}

.bannerListDot li button {
  margin: 0 0px;
  width: 16px !important;
  height: 16px !important;
  background: transparent;
  border: 2px solid ${styles?.colorapi};
  cursor: pointer;
  transition: 0.5s;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
}
.bannerListDot li.active button {
  background: ${styles?.colorapi};
  border: 2px solid  ${styles?.colorapi};
}

@media screen and (max-width:580px) {
.bannerListDot {
  display: flex !important;
  right: auto !important;
  bottom: 10px !important;
  top:auto !important;
  left:50% !important;
  transform: translate(-50%,0) !important;
}

.bannerListDot li button {
  height: 11px !important;
  width: 11px !important;
}

  
}

`;