import React from "react";
import GiftProductSection from "../GiftProducts/GiftProductSection";
const GiftProductsSectionMain = ({product, gift_product, company, socialmedia}) => {
  switch (gift_product) {
    case "GP1":
      return <GiftProductSection product={product} company={company} socialmedia={socialmedia} />;

    default:
      return <GiftProductSection product={product} company={company} socialmedia={socialmedia} />;
  }
};

export default GiftProductsSectionMain;
