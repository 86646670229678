import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";

import "swiper/css";
import "swiper/scss/pagination";
import "react-multi-carousel/lib/styles.css";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/default.png";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { base_url } from "../../config";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const Hc22 = ( { props } ) => {
  // console.log('props', props);
  return (
    <>
    <Hc1>
        <section className="Temp2_HC1">
          <div className="Wrapper">
            {props?.content?.title && <H2>{props?.content?.title}</H2>}
            <div className="Temp2_HC1_Align">
              {props?.content?.map((e, i) => {
                return (
                  <div className="Temp2_HC1_Box" key={`hc1_1_${i}`}>
                    <div className="Temp2_HC1_Box_Grid">
                      <img
                        src={base_url + e?.image ? base_url + e?.image : Default}
                        alt={e?.sub_title}
                      />
                      <h4>{e?.description ? e?.description : <br />}</h4>
                      <Link
                        className="Temp2_HC1_Box_Link"
                        to={e?.link
                          .toLowerCase()
                          .replace(/ /g, "-")
                          .replace(/[^\w-]+/g, "")}
                      >
                        <Button>{e?.link_text}</Button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </Hc1>
    </>
  )
}

export default Hc22;

const H2 = styled.h2`
  font-size: ${styles?.h2};
  font-family: ${styles?.font} !important;
  color: ${colorCustom?.color ? colorCustom?.color : styles?.color} !important;
  line-height: 1.4;
  margin: 0 0 25px;
  text-align: center;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;
const Hc1 = styled.div`


.Temp2_HC1 {
  width: 100%;
  display: inline-block;
  position: relative;
}

h1.Head_Text_Temp2, h2.Head_Text_Temp2 {
  text-align: center;
  margin:  0 0 25px;
}
.Temp2_HC1 .Temp2_HC1_Align {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 40px 25px;
}
.Temp2_HC1 .Temp2_HC1_Align .Temp2_HC1_Box {
  width: 100%;
  display: inline-block;
  padding: 30px;
  border: 1px solid ${styles?.light};
  background: ${styles?.white} !important;
}
.Temp2_HC1 .Temp2_HC1_Align .Temp2_HC1_Box .Temp2_HC1_Box_Grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.Temp2_HC1 .Temp2_HC1_Align .Temp2_HC1_Box .Temp2_HC1_Box_Grid img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}
.Temp2_HC1 .Temp2_HC1_Align .Temp2_HC1_Box .Temp2_HC1_Box_Grid h4 {
  font-size: 28px;
  color: ${styles?.color};
  font-family: ${styles?.medium} !important;
  line-height: 1.3;
  margin: 0;
  text-align: center;
}
.Temp2_HC1 .Temp2_HC1_Align .Temp2_HC1_Box .Temp2_HC1_Box_Grid .Temp2_HC1_Box_Link {
  width: 100%;
  display: inline-block;
}
.Temp2_HC1 .Temp2_HC1_Align .Temp2_HC1_Box .Temp2_HC1_Box_Grid .Temp2_HC1_Box_Link button {
    background: ${styles?.color};
    border: 1px solid ${styles?.color};
    display: flex;
    margin: auto;
}

@media screen and (max-width:768px) {


.Temp2_HC1 .Temp2_HC1_Align {
  grid-template-columns: repeat(1,1fr);
}
  
}



`