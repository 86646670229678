import React from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import API from "../../ApiService/ApiService";

const Brand1 = ({ brand }) => {

  const api = new API();
  // console.log("brand-----?>", brand);


  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">
           {brand?.section_title}
          </p>
        </div>
        <div className="BrandSection">
          <div className="Brand_Main">
            {brand?.brand_id?.map((e, i) => (
              <div className="Brand_Box" key={i}>
                <img src={api.rootUrl1 + e?.image} alt="Loading..." />
              </div>
            ))}
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default Brand1;

const BannerAlign = styled.div`
  .Top_Title {
    text-align: center;
    margin-bottom: 40px;
  }

  .Top_Title .Title {
    color: #3d418b;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Top_Title .Span_Title {
    padding-left: 10px;
    color: #0174be;
    font-family: Yellowtail;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .BrandSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Brand_Main {
    width: 80%;
    display: flex;
    /* grid-template-columns: repeat(6, 1fr); */
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 46px 0 46px;
  }

  .Brand_Main .Brand_Box {
    width: 140px;
   display: flex;
   justify-content: center;
   align-items: center;
  }

  .Brand_Main .Brand_Box img {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }


  @media screen and (max-width: 767px) {
    .Brand_Main{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      align-items: center;
      gap: 20px;
    }

    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }

    .Top_Title .Title {
    font-size: 30px;
  
  }

  .Top_Title .Span_Title {
    font-size: 40px;
  }
  }

  @media screen and (max-width: 592px) {
    .Brand_Main{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      padding: 0 10px;
    }
  }
  

`;
