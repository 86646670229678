
import React from 'react';
import { useSelector } from 'react-redux'
import BannerMain from './BannerMain';
import DynamicSection from './DynamicSection';
import ParallaxMain from './ParallaxMain';
import TopCategoryMain from './TopCategoryMain';
import FeatureProductMain from './FeatureProductMain';
import ProductsAndServiceMain from './ProductsAndServicesMain';
import styled from 'styled-components';
import Blog from './Blogs/Blog';
import GiftSection7 from '../GIftSection/GiftSection7';
import GiftSectionMain from './GiftSectionMain';
import GiftStoriesSectionMain from './GiftStoriesSectionMain';
import OfferBannerMain from './OfferBannerMain';
import GiftProductsSectionMain from './GiftProductsSectionMain';
import WhyWeUsSectionMain from './WhyWeUsSectionMain';
import BrandMain from './BrandMain';
import TemplateMain from './TemplateMain';



const Home = () => {
  const homecustom = useSelector(
    (state) => state?.homeCustomSlice?.homecustom?.data[0]
  );
  const banner = useSelector((state) => state?.banner?.banner?.data);
  const category = useSelector((state) => state?.category?.category?.data);
  // const product = useSelector((state) => state?.product?.product?.data)
  const product = useSelector((state) => state?.product?.product?.data[0].data);

  // console.log("homeeeeeee",homecustom)
  return (
    <HomeSection>
      <TemplateMain theme={homecustom?.theme}/>
      {/* <TopCategoryMain topcategory_type={homecustom?.topcategory_type ? homecustom?.topcategory_type : ""} category={category} />
      <BannerMain banner_type={homecustom?.banner_type ?homecustom?.banner_type : ""} banner={banner} />
      <div className='section_wrap'>
      <ProductsAndServiceMain />
      <GiftSectionMain />
      <GiftStoriesSectionMain />
      <OfferBannerMain />
      <GiftProductsSectionMain />
      <WhyWeUsSectionMain />
      <BrandMain />
      <FeatureProductMain featuredproduct_type={homecustom?.featuredproduct_type ? homecustom?.featuredproduct_type : ""} featureProduct={product} />
      <DynamicSection   />
      <Blog />
      </div> */}

      {/* <ParallaxMain parallax_type={homecustom?.parallax_type?homecustom?.parallax_type:""} /> */}
    </HomeSection>
  );
};

export default Home;

const HomeSection = styled.section`
  overflow-x: hidden;
  .section_wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 20px 0;
    gap: 100px 0;
  }
`;
