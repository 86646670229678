import React from "react";
import styled from "styled-components";
import {Row, Col, Rate} from "antd";
import styles from "../../ApiService/Data";
import "../../Assets/Css/style.css";
import API from "../../ApiService/ApiService";
import { useNavigate } from "react-router-dom";

const GiftSection7 = ({ product }) => {

 const api = new API();
 const navigate = useNavigate()

  // console.log("product", product)
  // const product = [
  //   {
  //     image: Product1,
  //     content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
  //     rating: "4.5",
  //     ratingCount: "(7894)",
  //     sp: "645",
  //     mrp: "999",
  //     offer: "(50% off)",
  //   },
  //   {
  //     image: Product2,
  //     content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
  //     rating: "4.9",
  //     ratingCount: "(7894)",
  //     sp: "645",
  //     mrp: "999",
  //     offer: "(50% off)",
  //   },
  //   {
  //     image: Product2,
  //     content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
  //     rating: "4",
  //     ratingCount: "(7894)",
  //     sp: "645",
  //     mrp: "999",
  //     offer: "(50% off)",
  //   },
  //   {
  //     image: Product2,
  //     content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
  //     rating: "3",
  //     ratingCount: "(7894)",
  //     sp: "645",
  //     mrp: "999",
  //     offer: "(50% off)",
  //   },
  // ];

  return (
    <GiftSectionAlign>
    <div className="home_wrapper">
      <div className="Section_Title">
        <p className="Left_Title">
         {product?.product_title}
        </p>
      </div>
      <Row gutter={[20, 20]} justify="center">
        {product?.product_id?.map((e, i) => (
          // console.log("e-->",e),

          <Col key={i} xs={24} sm={12} md={8} lg={6}>
            <div className="ProductCard" onClick={()=>navigate(`/product/${e?._id}`)}>
              <div className="Product_Image">
                <img src={ api.rootUrl1 + e?.images} alt={`product ${i + 1}`} />
              </div>
              <div className="Product_Content">
                <p>{e?.product_name}</p>
              </div>
              {/* <div className="Product_rating">
                <p>
                  <Rate disabled value={parseFloat(e?.rating)} className="rating" />
                  <span className="ratingCount">{e?.ratingCount}</span>
                </p>
              </div>
              <div className="Product_price">
                <p className="Product_Sp_Mrp">
                  <span className="Product_Sp">
                    {styles?.currency + e?.sp}
                  </span>
                  <span className="Product_Mrp">
                    {styles?.currency + e?.mrp}
                  </span>
                  <span className="Product_Offer">{e?.offer}</span>
                </p>
              </div> */}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  </GiftSectionAlign>
  );
};

export default GiftSection7;

const GiftSectionAlign = styled.div`
  .home_wrapper {
    /* max-width: 2000px; */
    width: 80%;
    margin: 0 auto;
  }

  .Section_Title {
    text-align: center;
  }

  .Section_Title .Left_Title {
    color: #3d418b;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Section_Title .Left_Title span {
    padding-left: 10px;
    color: #0174be;
    font-family: Yellowtail;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ProductCard_Section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    padding: 0 46px 0 46px;
  }

  .Product_Content p {
    padding-top: 5px;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
 
  }

  .Product_Content{
    cursor: pointer;
  }
  .Product_Image{
    cursor: pointer;
  }

  .ant-rate .ant-rate-star {
    width: "16px";
    height: "16px";
  }

  .Product_rating .ratingCount {
    color: #9e9e9e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .Product_price .Product_Sp_Mrp {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .Product_price .Product_Sp {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .Product_price .Product_Mrp {
    color: #8d8a8a;
    /* font-family: Poppins; */
    font-size: 14px;
    text-decoration: line-through;
  }

  .Product_price .Product_Offer {
    color: #fa0404;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media screen and (max-width: 767px) {
    .ProductCard_Section {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      border: 1px solid red;
      align-items: center;
      justify-content: space-evenly;
      gap: 20px;
    }

    .Product_Content p {
      content: "";
      white-space: pre-line;
      word-wrap: break-word;
    }
  }

  @media screen and (max-width: 1023px) {
    .ProductCard_Section {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
      align-items: center;
      justify-content: space-evenly;
      gap: 20px;
    }
  }
`;
