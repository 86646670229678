import { createSlice } from "@reduxjs/toolkit";

const review = createSlice({
    name: "review",
    initialState: {
        review: null,
        isFetching: false,
        error: null
    },
    reducers:{
        addReviewStart: (state) => {
            return{
                ...state, 
                isFetching: true,
            };
        },
        addReviewSuccess: (state, action) => {
            return{
                ...state,
                isFetching: false,
                review: action.payload
            }
        },
        gaddReviewFail: (state, action) => {
            return{
                ...state,
                isFetching: false,
                error: action.payload
            }
        }
    }
})

export const {
    addReviewStart,
    addReviewSuccess,
    addReviewFail
} = review?.actions;

export default review.reducer;