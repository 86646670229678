import React from "react";
import PriceList from "../../Ecommerce/PriceList";
import styled from "styled-components";

const CheckoutCard = ({setTotalAmount, totalAmount}) => {
  return (
    <CardAlign>
      <div className="checkout">
        <PriceList setTotalAmount={setTotalAmount} totalAmount={totalAmount} />
      </div>
    </CardAlign>
  );
};

export default CheckoutCard;

const CardAlign = styled.div`
  width: 100% !important;

  @media screen and (max-width: 768px) {
    .checkout {
      width: 100% !important;
    }
  }
`;
