import React from "react";
import TrendingOfMonth1 from "../Trending of Month/TrendingOfMonth1";

const TrendingOfMonthMain = ({trending}) => {
  // console.log("offer_Banner", trending)
  switch (trending) {
    case "TOM1":
      return <TrendingOfMonth1 trending={trending?.tranding_products} />;
   

    default:
      return <TrendingOfMonth1 trending={trending?.tranding_products} />;
  }
};

export default TrendingOfMonthMain;
