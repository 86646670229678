import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import styled from "styled-components";

import {
  List,
  Divider,
  Checkbox,
  Form,
  message,
  Modal,
  Input,
  Row,
  Col,
  Select,
} from "antd";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import Button from "@mui/material/Button";
import {
  addressGetAllFail,
  addressGetAllStart,
  addressGetAllSuccess,
} from "../../Redux/slices/userAddress";
import {
  updateAddressStart,
  updateAddressSuccess,
  updateAddressFail,
} from "../../Redux/slices/updateAddress";
import {
  statesGetAllStart,
  statesGetAllSuccess,
  statesGetAllFail,
} from "../../Redux/slices/states";
import {
  citiesGetAllStart,
  citiesGetAllSuccess,
  citiesGetAllFail,
} from "../../Redux/slices/cities";
import {
  removeAddressStart,
  removeAddressSuccess,
  removeAddressFail,
} from "../../Redux/slices/removeAddress";
import {styles} from "../../ApiService/Data";
import API from "../../ApiService/ApiService";
import UpdateAddress from "../../Ecommerce/UpdateAddress";
import {useForm} from "antd/lib/form/Form";

const {Option} = Select;

const api = new API();

const CheckoutAddress = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [address, setAddress] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateList = useSelector((state) => state?.states?.states?.states);
  const cityList = useSelector((state) => state?.cities?.cities);

  // const id = address?._id;

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  let userAddressList = useSelector(
    (state) => state?.userAddress?.address?.data
  );
  const user = JSON.parse(localStorage.getItem("user"));

   const defaulAddress = userAddressList?.find((item) => item?.is_default_address === true);
  //  localStorage.setItem("Default Address", JSON.stringify(defaulAddress));
  useEffect(() => {
    getUserAddress();
  }, []);

  useEffect(() => {
    getCountry();
    getDistrict();
    getCity();
    getState();
  }, [selectedCountry, selectedState, selectedDistrict]);

  const getCountry = () => {
    api.getCountry().then((res) => {
      setCountries(res?.data?.data);
    });
  };

  const getState = () => {
    api.getState(selectedCountry).then((res) => {
      setStates(res?.data?.data);
    });
  };

  const getDistrict = () => {
    api.getdistrict(selectedState).then((res) => {
      setDistrict(res?.data?.data);
    });
  };

  const getCity = () => {
    api.getArea(selectedDistrict).then((res) => {
      setCities(res?.data?.data);
    });
  };

  const handleDistrcitChange = (value) => {
    setSelectedDistrict(value);
  };

  const getUserAddress = async () => {
    setIsLoading(true);
    await api.getAll(
      dispatch,
      [addressGetAllStart, addressGetAllSuccess, addressGetAllFail],
      "buyerbillingdetails"
    );
   


    setIsLoading(false);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  //delete user address
  const handleDelete = async (id) => {
    const res = await api.remove(
      dispatch,
      [removeAddressStart, removeAddressSuccess, removeAddressFail],
      "buyerbillingdetails",
      id
    );
    if (res?.data?.success) {
      message.success("Successfully Deleted");
      getUserAddress();
    }
    getUserAddress();
  };

  //delivery address setting
  const setDeliveryAddress = async (id) => {
    const res = await api.update(
      dispatch,
      [updateAddressStart, updateAddressSuccess, updateAddressFail],
      "defaultaddress",
      id
    );
    // console.log("ressss---->", res)
    if (res?.data?.success) {
      message.success(`Delivery Address Updated`);
      localStorage.setItem("Default Address", JSON.stringify(res?.data?.data));
      window.location.reload();
    } else {
      message.warning("Check your Data");
    }
    getUserAddress();
  };

  

  const OnfinishAddress = async (values) => {
    console.log(values);
    setIsLoading1(true);
    const response = await api.createCommon("buyerbillingdetails", values);
    if (response?.data?.success) {
      setIsLoading1(false);
      form.resetFields();
      message.success("Successfully Added");
      setAddAddressModal(false);
      getUserAddress();
    } else {
      setIsLoading1(false);
      message.error(response?.data?.message);
    }
  };

  const customFormItemStyle = {
    marginBottom: "8px", // Adjust the margin as per your requirement
  };

  return (
    <BillingAddressSection>
      <UpdateAddress
        getUserAddress={getUserAddress}
        address={address}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="wrapper">
        <div className="billing_address_section">
          <div className="sidebar">{/* <SideBar /> */}</div>
          <div className="billing_address_head">
            <Button
              onClick={() => setAddAddressModal(true)}
              variant="contained"
              sx={{mt: 1, mr: 1}}
            >
              Add Address
            </Button>
            <Modal
              title="Add Address"
              name="basic"
              centered
              open={addAddressModal}
              onCancel={() => setAddAddressModal(false)}
              footer={null}
              width={900}
            >
              <Form
                onFinish={OnfinishAddress}
                form={form1}
                autoComplete="off"
                layout="vertical"
                className="custom-form"
              >
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Mobile Number"
                      name="buyer_delivery_contact_number"
                      style={customFormItemStyle}
                      rules={[
                        {
                          required: true,
                          message: "Please input your mobile number!",
                        },
                        {
                          min: 10,
                          max: 10,
                          message: "Phone number must be in 10 digits.",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Alternate Mobile Number"
                      name="buyer_delivery_alternate_number"
                      style={customFormItemStyle}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Address 1"
                      name="buyer_address_line_1"
                      style={customFormItemStyle}
                      rules={[
                        {
                          required: true,
                          message: "Please input your address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Address 2"
                      name="buyer_address_line_2"
                      style={customFormItemStyle}
                      rules={[
                        {
                          required: true,
                          message: "Please input your address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Pincode"
                      name="buyer_pincode"
                      style={customFormItemStyle}
                      rules={[
                        {
                          type: "regexp",
                          pattern: new RegExp("^d{4}$|^d{6}$"),
                          message: "Format is wrong",
                        },
                        {
                          required: true,
                          message: "Please input your pincode",
                        },
                        {
                          min: 6,
                          max: 6,
                          message: "Pincode must be in 6 digits.",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Landmark"
                      name="landmark"
                      style={customFormItemStyle}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                  <Form.Item label="Country" name="buyer_country">
                    <Select onChange={handleCountryChange} showSearch>
                      {countries?.map((country) => (
                        <Option key={country?._id} 
                        // value={country?.country}
                        value={country?._id}
                        >
                          {country?.country}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item
                    label="State"
                    name="buyer_state"
                    rules={[
                      {
                        required: true,
                        message: "Please select state",
                      },
                    ]}
                  >
                    <Select showSearch allowClear onChange={handleStateChange}>
                      {states?.map((state) => (
                        <Option key={state?._id} 
                        // value={state?.state}
                        value={state?._id}
                        >
                          {state?.state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                  <Form.Item
                    label="District"
                    name="buyer_district"
                    rules={[
                      {
                        required: true,
                        message: "Please select city",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      onChange={handleDistrcitChange}
                    >
                      {district?.map((district) => (
                        <Option key={district?._id} 
                        // value={district?.district}
                        value={district?._id}
                        >
                          {district?.district}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item
                    label="City"
                    name="buyer_city"
                    rules={[
                      {
                        required: true,
                        message: "Please select city",
                      },
                    ]}
                  >
                    <Select showSearch allowClear>
                      {cities?.map((city) => (
                        <Option key={city?._id} 
                        // value={city?.area}
                        value={city?._id}
                        >
                          {city?.area}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  variant="contained"
                  htmlType="submit"
                  loading={isLoading1}
                >
                  Save Address
                </Button>
              </Form>
            </Modal>

            <Divider />
            <List
              className="edit-address"
              loading={isLoading}
              itemLayout="horizontal"
              dataSource={userAddressList}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    // <Link to={`/edit-address?id=${item._id}`}>
                    <div
                      className="edit"
                      onClick={() => {
                        setShowModal(true);
                        setAddress(item);

                        form.setFieldValue(address);
                      }}
                    >
                      <EditOutlined /> Edit
                    </div>,
                    <div
                      className="delete"
                      onClick={() => {
                        handleDelete(item._id);
                      }}
                    >
                      <DeleteOutlined /> Delete
                    </div>,
                    // </Link>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Checkbox
                        checked={item?.is_default_address}
                        onClick={() => setDeliveryAddress(item._id)}
                      ></Checkbox>
                    }
                    title={`${user?.buyer_name}- ${item?.buyer_delivery_contact_number}`}
                    description={
                      // "test, test, Coimbatore, test, Tamil Nadu, INDIA - 656512. Alternate Mobile :6369021751"
                      `${item?.buyer_address_line_1}, ${item?.buyer_address_line_2}, ${item?.buyer_city?.area} - ${item?.buyer_pincode}, ${item?.buyer_state?.state}, ${item?.buyer_country?.country} `
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
      <Divider />
    </BillingAddressSection>
  );
};

export default CheckoutAddress;

const BillingAddressSection = styled.section`
  margin: 40px 0 0 0;

  .custom-form .ant-form-item {
    margin-bottom: 0px;
  }

  .css-1f5ro5o-MuiButtonBase-root-MuiButton-root {
    background-color: ${styles.appColor};
    color: ${styles.white};
  }

  .css-1f5ro5o-MuiButtonBase-root-MuiButton-root:hover {
    background-color: ${styles.appColor};
  }

  .warpper {
    max-width: 1200px;
  }

  .billing_address_section {
    border-radius: 10px;
    background: ${styles?.background};
    padding: 20px 80px;
    filter: drop-shadow(5px 5px 10px rgba(180, 180, 180, 0.3));
    width: 100%;
    margin: auto;
    display: flex;
    gap: 30px;
  }
  .billing_address_head {
    text-align: right;
    width: 100%;
  }
  .ant-list-item-meta-content {
    text-align: left;
  }
  .ant-list .ant-list-item {
    align-items: flex-start;
  }
  .edit {
    font-size: 13px;
    cursor: pointer;
  }
  .delete {
    cursor: pointer;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  color: ${styles.color};
  margin: 30px 0 20px 0;
`;
