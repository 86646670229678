import React from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import Image1 from "../../Assets/Images/GirlsHub Template/Banner-3.png";
import Image2 from "../../Assets/Images/GirlsHub Template/Banner-4.png";
import styled from "styled-components";
import API from "../../ApiService/ApiService";

const HCGirlsHub2 = ({banner}) => {
  // console.log("banner", banner);

  const api = new API();
  const navigate = useNavigate();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        {(<div className="Banner_Main">
          <div className="Left_Section" >
            <Link to={banner?.banner_link_1} style={{textDecoration: "none"}}>
            <img src={api?.rootUrl1 + banner?.banner_image_1?.image} alt="Banner" />
            </Link>
          </div>
          <div className="Right_Section">
            <Link to={banner?.banner_link_2} style={{textDecoration: "none"}}>
            <img src={api?.rootUrl1 + banner?.banner_image_2?.image} alt="Banner" />
            </Link>
          </div>
        </div>)}
      </div>
    </BannerAlign>
  );
};

export default HCGirlsHub2;

const BannerAlign = styled.div`

 .Banner_Main {
    display: flex;
    align-items: center;
 }


`;
