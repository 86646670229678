import React from "react";
import ClientReview1 from "../ClientReviews/ClientReview1";
import ClientReview2 from "../ClientReviews/ClientReview2";
import ClientReview3 from "../ClientReviews/ClientReview3";


const ClientReviewMain = ({review_type, banner, homecustom}) => {
  switch (review_type) {
    case "review_1":
      return <ClientReview1 homecustom={homecustom} />;
    case "review_2":
      return <ClientReview2 homecustom={homecustom} />;
    case "review_3":
      return <ClientReview3 banner={banner} />;
    default:
      return <ClientReview3 banner={banner} />;
  }
};

export default ClientReviewMain;

