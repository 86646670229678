import React from "react";
import WhyUsSection from "../WhyUS/WhyUsSection";

import WhyUsSection2 from "../WhyUS/WhyUsSection2";

import WhyUsSection3 from "../WhyUS/WhyUsSection3";
import WhyUsSection4 from "../WhyUS/WhyUsSection4";


const WhyWeUsSectionMain = ({why_us_type, why_us, whyus_banner}) => {
  switch (why_us_type) {
    case "why_us_1":
      return <WhyUsSection why_us={why_us}/>;
    case "why_us_2":
      return <WhyUsSection2 why_us={why_us}/>;
    case "why_us_3":
      return <WhyUsSection3 why_us={why_us} />;
    case "why_us_4":
      return <WhyUsSection4 whyus_banner={whyus_banner}/>;
    default:
      return <WhyUsSection4 />;

  }
};

export default WhyWeUsSectionMain;
