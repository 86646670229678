import React, { useState, useEffect } from 'react'
import rightarrow from "../../Assets/Images/dry/right_arrow.png"
import hmain from "../../Assets/Images/dry/hmain.png"
import hc1 from "../../Assets/Images/dry/hc1.png"
import styled from 'styled-components';
import { styles } from '../../ApiService/Data';
import API from "../../ApiService/ApiService";
import { Link } from "react-router-dom";
import Default from '../../Assets/Images/default.png'
import { base_url } from '../../config';

const Hc33 = ({ props }) => {
  return (
   <>
  <Hc3Section>
        <div className='hc3_section'>
          <div className='wrapper'>
            {props?.title && <H2>{props?.title}</H2>}
            <div className='hc3_align'>
              <div className='hc3_left'>
                <ul>
                  {props?.content?.slice(0, 1).map((e, i) => {
                    return (
                      <li key={`hc3_1a_${i}`}>
                        <div className='hc3_box'>
                          <span className='trand_product'>{e?.sub_title}</span>
                          <div className='hc3_img'>
                            <Link to={e?.link.toLowerCase()
                              .replace(/ /g, "-")
                              .replace(/[^\w-]+/g, "")}>
                              <img src={e?.image ? base_url + e?.image : Default} alt={e?.title} />
                            </Link>
                          </div>
                          <div className='hc3_content'>
                            <h4>{e?.title}</h4>
                            <Link to={e?.link.toLowerCase()
                              .replace(/ /g, "-")
                              .replace(/[^\w-]+/g, "")}><button>
                                <img src={rightarrow} alt="" />
                              </button></Link>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className='hc3_right'>
                <ul>
                {props?.content?.slice(1, 5).map((e, i) => {
                    return (
                  <li key={`hc3_1_${i}`}>
                    <div className='hc3_box'>
                      <span className='trand_product'>{e?.sub_title}</span>
                      <div className='hc3_img'>
                      <Link to={e?.link?.toLowerCase()
                              .replace(/ /g, "-")
                              .replace(/[^\w-]+/g, "")}>
                              <img src={e?.image ? base_url + e?.image : Default} alt={e?.title} />
                            </Link>
                      </div>
                      <div className='hc3_content'>
                      <h4>{e?.title}</h4>
                            <Link to={e?.link.toLowerCase()
                              .replace(/ /g, "-")
                              .replace(/[^\w-]+/g, "")}><button>
                                <img src={rightarrow} alt="" />
                              </button></Link>
                      </div>
                    </div>
                  </li>
                   );
                  })}
                </ul>
              </div>
            </div>

          </div>
        </div>
      </Hc3Section>
   </>
  )
}

export default Hc33;

const H2 = styled.h2`
   font-size:32px;
   margin : 0 0 50px;
   font-family: ${styles?.q_bold} !important;
   text-align: center;
   padding: 55px 0 0 0;
   &::before {
    content: "";
    position: absolute;
    background: url(${hmain});
    background-repeat: no-repeat;
    height: 46px;
    width: 46px;
    background-size: contain;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0px);
   }

   @media screen and (max-width:768px) {
    text-align: center;
   }

`


const Hc3Section = styled.section`
    display: inline-block;
    width: 100%;
    position: relative;

    .hc3_align {
        display: flex;
        
        gap: 20px;
        width: 100%;
    }

    .hc3_left {
        flex: 1.2;
        
    }
    .hc3_right {
        flex: 2;
       
    }
    .hc3_left ul {
        margin: 0;
        padding: 0;
        grid-template-columns: repeat(1,1fr);
        gap: 0;
        display: grid;
        height: 100%;
    }
    .hc3_left ul li {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #ededed;
        padding: 20px;
        border-radius: 5px;
    }
    .hc3_left ul li .hc3_box {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.hc3_left ul li .hc3_box .hc3_img {
    margin: auto;
}



    .hc3_right ul {
        margin: 0;
        padding: 0;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
        display: grid;
        height: 100%;
    }
    .hc3_right ul li {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #ededed;
        padding: 20px;
        border-radius: 5px;
    }

.hc3_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 0 0;
}
.hc3_right ul li img {
    padding: 0 20px;
}

    .hc3_align h4 {
    margin: 0 !important;
    width: 75%;
    text-align: left;
    line-height: 1.4;
    font-family: ${styles?.q_bold};
    font-size: 18px;
}
.hc3_align button {
    border: 0;
    background: transparent;
    outline: none;
    img {
        padding: 0 !important;
        height: 25px;
    }
}

.trand_product {
display: block;
    width: fit-content;
    text-align: end;
    background: ${styles?.themegreen};
    color: #fff;
    padding: 4px 15px;
    border-radius: 12px 1px 12px 1px;
    margin: 0 0 20px auto;
    font-size: 12px;
}

@media screen and (max-width:768px) {
    .hc3_align {
        flex-direction: column;
    }
    .hc3_left, .hc3_right {
        flex: auto;
        width: 100%;
    }
}

@media screen and (max-width:480px) {
    .hc3_right ul {
        grid-template-columns: repeat(1,1fr);
    }
}





`

