import React from "react";
import styled from "styled-components";

const CustomizableCollection = ({ homecustom }) => {
  return (
    <Section>
      <div className="container">
        <div className="left_side">
          <h2>
           {homecustom?.content}
          </h2>
          <div className="color_stripe">
            <span className="yellow stripe"></span>
            <span className="green stripe"></span>
            <span className="violet stripe"></span>
            <span className="orange stripe"></span>
          </div>
        </div>
        <div className="right_side">
          <button
          onClick={() => window.location.href = homecustom?.button_link}
          >{homecustom?.button_text}</button>
        </div>
      </div>
    </Section>
  );
};

export default CustomizableCollection;

const Section = styled.section`
  .container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;

    .left_side {
      h2 {
        color: #000;
        font-family: Brevia;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 30px;
      }

      .color_stripe {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .stripe {
          width: 100%;
          height: 8px;
        }

        .yellow {
          background-color: #fed325;
        }
        .green {
          background-color: #15e89b;
        }
        .violet {
          background-color: #6b00dc;
        }
        .orange {
          background-color: #f55b30;
        }
      }
    }
    .right_side {
      display: flex;
      align-items: end;
      justify-content: center;

      button {
        width: 150px;
        padding: 12px 12px 15px 12px;
        border: none;
        background-color: #01c834;
        color: white;

        font-family: Brevia;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  @media screen and (max-width: 786px) {

    .left_side {
      h2 {
        font-size: 30px !important;
        margin-bottom: 10px !important;
      }
    }

    button {
        width: 110px !important;
        padding: 10px 10px 11px 10px !important;
        font-size: 16px !important;

      }
  }
  @media screen and (max-width: 480px) {
    .container {
      grid-template-columns: repeat(1, 1fr);
    }

    .left_side {
      h2 {
        font-size: 20px !important;
        margin-bottom: 10px !important;
      }
    }
  }
`;

