
import React from 'react'
import { 
  Spin,
} from "antd";
import Template1 from '../Templates/Template1'
import Template2 from '../Templates/Template2'
import Template3 from '../Templates/Template3'
import Template4 from "../Templates/Template4"
import Template5 from "../Templates/Template5"
import Template6 from "../Templates/Template6"
import Template7 from "../Templates/Template7"
import Template8 from '../Templates/Template8'
import Template9 from '../Templates/Template9'
import Template10 from '../Templates/Template10'

import { useSelector } from "react-redux"

const TemplateMain = ({ theme }) => {



  const homecustom = useSelector((state) => state?.homeCustomSlice?.homecustom?.data[0]);
  const banner = useSelector((state) => state?.banner?.banner?.data);
  const category = useSelector((state) => state?.category?.category?.data);
  // const product = useSelector((state) => state?.product?.product?.data)
  const product = useSelector((state) => state?.product?.product?.data[0].data);
 

  switch (theme) {
    // case 'Theme-1':
    //   return <Template1 homecustom={homecustom} banner={banner} category={category} product={product}/>
    // case 'Theme-2':
    //   return <Template2  banner={banner} category={category} product={product}/>
    // case 'Theme-3':
    //   return <Template3 homecustom={homecustom} banner={banner} category={category} product={product}/>
    // case 'Theme-4':
    //   return <Template4 homecustom={homecustom} banner={banner} category={category} product={product}/>
    // case 'Theme-5':
    //   return <Template5 homecustom={homecustom} banner={banner} category={category} product={product}/>
    // case 'Theme-6':
    //   return <Template6 homecustom={homecustom} banner={banner} category={category} product={product}/>
    case 'Theme-1':
      return <Template7 homecustom={homecustom?.theme_1} banner={banner} category={category} product={product}/>
    case 'Theme-2':
      return <Template8 homecustom={homecustom} banner={banner} category={category} product={product}/>
    case 'Theme-3':
      return <Template9 homecustom={homecustom?.theme_3} banner={banner} category={category} product={product} />
    case 'Theme-4':
      return <Template10 homecustom={homecustom?.theme_4} banner={banner} category={category} product={product} dynamic="HCGirlsHub2" />
    // default:
    //   return <Template9 homecustom={homecustom} banner={banner} category={category} product={product}  />
  }
}

export default TemplateMain