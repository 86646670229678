import React, {useState, useEffect} from "react";
import {Card, Typography, Row, Col} from "antd";
import styled from "styled-components";
import {styles} from "../../../ApiService/Data";
import banner from "../../../Assets/Images/banner.webp";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import crud_service from "../../../ApiService/CrudService";
import PageUnderConstruction from "../../Pages/PageUnderConstruction";

const {Title, Paragraph, Text} = Typography;

const Ab1 = ({AboutUs}) => {
  // console.log("AboutUs", AboutUs);

  const [getpage, setGetpage] = useState([]);

  //api service
  const crud = new crud_service();
  const navigate = useNavigate();
  // const AboutUs = useSelector((state) => state?.aboutus?.aboutus);
  const htmlContent = AboutUs?.data[0]?.description;
  // console.log("AboutUs", AboutUs?.data);

  return (
    <MainSection>
      {AboutUs?.data.length ? <div className="about_container">
        <Row className="about_detail">
          <Col span={24}>
            <img src={process.env.REACT_APP_BASE + AboutUs?.data[0].image} width="100%" />
          </Col>
          <Col xs={24} lg={24} sm={24} xxl={24} className="our_mission">
            <h4 style={{textAlign: "center"}}>{AboutUs?.data[0].title}</h4>
            <div className="about_content" dangerouslySetInnerHTML={{__html: htmlContent}} />
          </Col>
          {/* <Col xs={24} lg={12} sm={24} xxl={12} className='our_mission'>
            <h4>Our Mission</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh.</p>
          </Col> */}
        </Row>
      </div> : <PageUnderConstruction />}
    </MainSection>
  );
};

export default Ab1;

const MainSection = styled.section`
 
  .about_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */

    .about_detail {
      max-width: 1500px;
      line-height: 30px;
      color: grey;
    }

    .about_content {
      padding: 10px
    }

    .our_mission {
      margin: 50px 0px;
    }
  }
`;
