import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { RightOutlined, LeftOutlined} from "@ant-design/icons";
import "swiper/css";
import "swiper/scss/pagination";
import "swiper/css/navigation";
import DefaultImg from "../../Assets/Images/default.png";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { base_url } from "../../config";
import Default from '../../Assets/Images/default.png'
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const TC4 = ({ category }) => {
  // console.log('category',category );
  return (
    <>
     <TopCategory>
        <section className="Temp5_Top_Category">
          <div class="container" style={{ position: "relative" }}>
          <H2>Top Categories</H2>
            <div className="Wrapper_Full">
              <Swiper
                slidesPerView={5}
                spaceBetween={20}
                freeMode={true}
                pagination={false}
                loop={false}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  380: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                modules={[Pagination, Autoplay, Navigation]}
                className="Temp5_Category_Slider"
              >
                {category?.map((e, i) => {
                  return (
                    <SwiperSlide
                      className="Temp5_Category_Slider_Box"
                      key={`Temp5_Category_${i}`}
                    >
                      <div className="Temp5_Category_Slider_Link">
                        <Link
                          to={`/${e?.name
                            .replace(" ", "-")
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")}`}
                        >
                          <img
                            className="TC5_image"
                            alt={e?.name}
                            src={e?.images ? base_url + e?.images : Default}
                          />
                          <p
                            style={{
                              textAlign: "center",
                              marginTop: "15PX",
                              marginBottom: "0PX",
                            }}
                          >
                            {e?.name}
                          </p>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div class="swiper-button-prev"><LeftOutlined /></div>
            <div class="swiper-button-next"><RightOutlined /></div>
          </div>
        </section>
      </TopCategory>
    </>
  )
}

export default TC4;

const H2 = styled.h1`
  font-size: ${styles?.h2};
  font-family: ${styles?.font} !important;
  color: ${colorCustom?.color ? colorCustom?.color : styles?.color} !important;
  line-height: 1.4;
  margin: 0 0 25px;
  text-align: center;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const TopCategory = styled.div`

.Temp5_Top_Category {
  margin: 0;
}

.TC5_image {
  width: 50%;
  margin: auto;
}

.Temp5_Top_Category .swiper-button-prev {
  font-size: 14px;
}

.Temp5_Top_Category .swiper-button-prev::after {
  font-size: 14px !important;
  color: #000;
}

.Temp5_Top_Category .swiper-button-next::after {
  font-size: 14px !important;
  color: #000;
}

.Temp5_Top_Category .swiper-button-prev {
  background: #827b7b52;
  padding: 10px 18px;
  border-radius: 50%;
}

.Temp5_Top_Category .swiper-button-next {
  background: #827b7b52;
  padding: 10px 18px;
  border-radius: 50%;
}
`