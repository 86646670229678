import React from "react";
import AboutUsClient from "../AboutUs(Client Say)/AboutUsClient";
import AboutUsFurniture from "../AboutUs(Client Say)/AboutUsFurniture";

const AboutUsClientSectionMain = ({ about_us_client, review, About_us }) => {
  switch (about_us_client) {
    case "AC1":
      return <AboutUsClient review={review} />;
    case "AC2":
      return <AboutUsFurniture review={review} About_us={About_us}/>;
   
    default:
      return <AboutUsFurniture review={review} About_us={About_us} />;
  }
};

export default AboutUsClientSectionMain;


