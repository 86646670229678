import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import {
  Tag,
  Button,
  Divider,
  Popconfirm,
  message,
  Empty,
  Input,
  Spin,
  Table,
} from "antd";
import { DeleteOutlined, InfoOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Default from "../Assets/Images/Agri/b2.jpg";
import styles from "../ApiService/Data";
import "../../src/Assets/Css/style.css";
import { Spinner } from "react-bootstrap";


import { cartSuccess, cartStart, cartFailure } from "../Redux/slices/cart";

const WishList = () => {
  const api = new API();
  const [wishList, setWishList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token") || ""
  const [cartUpdate, setCartUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const loginTrue = localStorage.getItem("login");

  const dispatch = useDispatch()

  // console.log("user", user?.company_code);


  useEffect(() => {
    getAllWishList();
  }, []);
  const getAllWishList = () => {
    api.getWishList(user?._id).then((res) => {
      setWishList(res?.data?.data || []);
    });
  };

  const deleteItem = (id) => {
    api.deleteWishList(id).then((res) => {
      message.success("Deleted Successfully");
      getAllWishList()

    });
  };

  const addToCart = (item) => {
    // console.log(item)
    // setAddToCartLoading(true);

    if (!loginTrue) {
      // setAddToCartLoading(false);
    } else {
      const combinedData = {
        buyer_id: item?._id,
        qty: item?.qty,
        product_id: item?.product_id?._id,
        product_spec: item?.product_spec?._id,
        company_code: user?.company_code,
      };

      api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          // deleteItem();
          // setAddToCartLoading(false);
        } else {
          dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          // setAddToCartLoading(false);
        }
      });
    }
  };
  

  const data = [];
  wishList?.map((item) => {

    data.push({
      thumb: item?.product_spec?.product_images[0],
      title: item?.product_id?.product_name,
      price: item?.product_spec?.sp,
      item,
      remove: item?._id,
    });
  });

  const columns = [
    {
      title: "THUMBNAIL",
      dataIndex: "thumb",
      key: "thumb",
      // fixed: "left",
      render: (value, records) => {
        // console.log(value)
        return <img width={50} src={api.rootUrl1 + value?.image_path} />;
      },
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      // fixed: "left",
    },
    {
      title: "SPECIFICATION",
      dataIndex: "item",
      key: "item",
      render: (value)=>{
        return(
          <>
          {value?.product_spec?.specification?.map((item)=>{
            return(<Tag>{item?.specification_details}</Tag>)
          })}
          </>
        )
      }
      // fixed: "left",
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "ADD TO CART",
      dataIndex: "cart",
      key: "cart",
      render: (item, records) => {
        // console.log(records)
        return <Button onClick={()=>addToCart(records?.item)}>Add to Cart</Button>;
      },
    },
    {
      title: "REMOVE",
      dataIndex: "remove",
      key: "remove",
      render: (action) => {
        // console.log(action)
        return <DeleteOutlined onClick={() => deleteItem(action)} />;
      },
    },
  ];

  return (
    <CartSection>
      <div className="wrapper" style={{ padding: "60px 40px" }}>
        <h1>Wish List</h1>
        {wishList?.length < 1 ? (
          <Empty />
        ) : cartUpdate ? (
          <Spin fullScreen />
        ) : (
          <div className="cart_section">
            <div className="cart_left">
              <div className="cart_list" id="style-10">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                  responsive={true}
                  scroll={{
                    x: 1000,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </CartSection>
  );
};

export default WishList;

const CartSection = styled.section`
  width: 100%;
  margin: 60px 0;
  display: inline-block;
`;
