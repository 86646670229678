import React from "react";
import BannerFurniture from "./BannerFurniture.jsx/BannerFurniture";

const BannerSingleFurniture = ({banner_type, homecustom}) => {
  switch (banner_type) {
    case "BF1":
      return <BannerFurniture homecustom={homecustom} />;
    default:
      return <BannerFurniture homecustom={homecustom} />;
  }
};

export default BannerSingleFurniture;
