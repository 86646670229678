import React from "react";
import Banner1 from "../Banner/Banner1";
import Banner2 from "../Banner/Banner2";
import Banner3 from "../Banner/Banner3";
import Banner4 from "../Banner/Banner4";
import Banner5 from "../Banner/Banner5";
import Banner6 from "../Banner/Banner6";
import Banner7 from "../Banner/Banner7";
import Banner9 from "../Banner/Banner9";
import Banner8 from "../Banner/Banner8";
import Banner10 from "../Banner/Banner10";


const BannerMain = ({ banner_type, banner, homecustom}) => {
  // console.log("homecustom", banner_type, banner)

  switch (banner_type) {

    // case "BA1":
    //   return <Banner1 banner={banner} />;
    // case "BA2":
    //   return <Banner2 banner={banner} />;
    // case "BA3":
    //   return <Banner3 banner={banner} />;
    // case "BA4":
    //   return <Banner4 banner={banner} />;
    // case "BA5":
    //   return <Banner5 banner={banner} />;
    // case "BA6":
    //   return <Banner6 banner={banner} />;
    case "Banner1":
      return <Banner7 banner={banner} />;
    case "BA8":
      return <Banner8 homecustom={homecustom} />;
     case "BA9":
      return <Banner9 banner={banner} />;
     case "BA10":
      return <Banner10 banner={banner?.banner}  offertext={banner?.top_offer}/>;
    // default:
    //   return <Banner10 banner={banner} />;

  }
};

export default BannerMain;
