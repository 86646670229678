import { createSlice } from "@reduxjs/toolkit";

const returnpolicy = createSlice({
  name: "returnpolicy",
  initialState: {
    returnpolicy: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    registerGetAllStart: (state) => {
      state.isFetching = true;
    },
    registerGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.returnpolicy = action.payload;
    },
    registerGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    returnpolicyGetAllStart: (state) => {
      state.isFetching = true;
    },
    returnpolicyGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.returnpolicy = action.payload;
    },
    returnpolicyGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    logoutreturnpolicy: (state, action) => {
      state.returnpolicy = action.payload;
    },
  },
});

export const {
  returnpolicyGetAllStart,
  returnpolicyGetAllSuccess,
  returnpolicyGetAllFail,
  logoutreturnpolicy,
  registerGetAllStart,
  registerGetAllSuccess,
  registerGetAllFail,
} = returnpolicy?.actions;
export default returnpolicy.reducer;
