import React from "react";
import styled from "styled-components";
import Product1 from "../../Assets/Images/GiftProduct/product-1.png";
import Product2 from "../../Assets/Images/GiftProduct/Product-2.png";
import {Button, Rate} from "antd";
import styles from "../../ApiService/Data";
import "../../Assets/Css/style.css";
import API from "../../ApiService/ApiService";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const BestSeller1 = ({ best_selling }) => {
 
  const api = new API();
  // console.log("best_selling---->", best_selling)  

  const navigate = useNavigate()


  const Products = [
    {
      image: Product1,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "4.5",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
    {
      image: Product2,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "4.9",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
    {
      image: Product2,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "4",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
    {
      image: Product2,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "3",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
    {
      image: Product1,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "4.5",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
    {
      image: Product2,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "4.9",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
    {
      image: Product2,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "4",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
    {
      image: Product2,
      content: "Let Love Grow - 2-Layer Bamboo Plant With Pot - Personalized",
      rating: "3",
      ratingCount: "(7894)",
      sp: "645",
      mrp: "999",
      offer: "(50% off)",
    },
  ];

  return (
    <GiftSectionAlign>
      <div className="home_wrapper">
        <div className="Section_Title">
          <p className="Left_Title">
           {best_selling?.section_title}
          </p>
        </div>
        <div className="ProductCard_Section">
          {best_selling?.product_id?.map(
            (e, i) => (
              // console.log("e", e),
              (
                <div key={i} className="ProductCard">
                  <div className="Product_Image">
                    <Link to={`/product/${e?._id}`}><img src={api.rootUrl1 + e?.images} /></Link>
                  </div>
                  <div className="Product_Content">
                    <p>{e?.product_name}</p>
                  </div>
                  {/* <div className="Product_rating">
                    <p>
                      <Rate disabled value={e?.rating} className="rating" />
                      <span className="ratingCount">{e?.ratingCount}</span>
                    </p>
                  </div> */}
                  {/* <div className="Product_price">
                    <p className="Product_Sp_Mrp">
                      <span className="Product_Sp">
                        {styles?.currency + e?.sp}
                      </span>
                      <span className="Product_Mrp">
                        {styles?.currency + e?.mrp}
                      </span>
                      <span className="Product_Offer">{e?.offer}</span>
                    </p>
                  </div> */}
                </div>
              )
            )
          )}
        </div>
        <div className="View_All_Section">
          <Button className="View_All" onClick={()=>navigate("/products")}>
            View More
            </Button>
        </div>
      </div>
    </GiftSectionAlign>
  );
};

export default BestSeller1;

const GiftSectionAlign = styled.div`
  .home_wrapper {
    max-width: 2000px;
  }

  .Section_Title {
    text-align: center;
    margin-bottom: 40px;
  }

  .Section_Title .Left_Title {
    color: #3d418b;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Section_Title .Left_Title span {
    padding-left: 10px;
    color: #0174be;
    font-family: Yellowtail;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ProductCard_Section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 100px 0 100px;
  }

  .Product_Image{
    img{
      width: 100%;
    }
  }

  .Product_Content p {
    padding-top: 5px;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .ant-rate .ant-rate-star {
    width: "16px";
    height: "16px";
  }

  .Product_rating .ratingCount {
    color: #9e9e9e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .Product_price .Product_Sp_Mrp {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .Product_price .Product_Sp {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .Product_price .Product_Mrp {
    color: #8d8a8a;
    /* font-family: Poppins; */
    font-size: 14px;
    text-decoration: line-through;
  }

  .Product_price .Product_Offer {
    color: #fa0404;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .View_All_Section {
    margin-top: 20px;
    display: grid;
    place-content: center;
  }

  .View_All_Section .View_All {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #f6a82d;
    color: #fafafa;
    border-radius: 0 !important;
    text-decoration: none;
  }

  .View_All_Section .View_All:focus {
    background: #f6a82d;
    color: #fafafa;
  }

  .View_All_Section .View_All:hover {
    background: #f6a82d !important;
    color: #fafafa !important;
  }
  @media screen and (max-width: 960px) {
    .ProductCard_Section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      padding: 0 30px;
    }
  }

  @media screen and (max-width: 767px) {
    .ProductCard_Section {
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
      gap: 20px;
    }
  }
  @media screen and (max-width: 560px) {
    .ProductCard_Section {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      padding: 0 50px;
    }
  }
`;
