import React from "react";
import Footer1 from "../Footer/Footer1";
import Footer2 from "../Footer/Footer2";
import Footer3 from "../Footer/Footer3";
import Footer4 from "../Footer/Footer4";
import Footer5 from "../Footer/Footer5";
import Footer6 from "../Footer/Footer6";
import Footer7 from "../Footer/Footer7";
import Footer9 from "../Footer/Footer9";
import Footer8 from "../Footer/Footer8";
import Footer10 from "../Footer/Footer10";
import Footer11 from "../Footer/Footer11";

import { useSelector } from "react-redux";

const FooterMain = ({footer_type, company, socialmedia}) => {

  switch (footer_type) {
    // case "Theme-1":
    //   return <Footer1 company={company} socialmedia={socialmedia} />;
    // case "Theme-2":
    //   return <Footer2 company={company} socialmedia={socialmedia} />;
    // case "Theme-3":
    //   return <Footer3 company={company} socialmedia={socialmedia} />;
    // case "Theme-4":
    //   return <Footer4 company={company} socialmedia={socialmedia} />;
    // case "Theme-5":
    //   return <Footer5 company={company} socialmedia={socialmedia} />;
    // case "Theme-6":
    //   return <Footer6 company={company} socialmedia={socialmedia} />;
    case "Theme-1":
      return <Footer7 company={company} socialmedia={socialmedia} />;
    case "Theme-2":
      return <Footer8 company={company} socialmedia={socialmedia} />;
    case "Theme-3":
      return <Footer9 company={company} socialmedia={socialmedia} />;
    case "Theme-4":
      return <Footer10 company={company} socialmedia={socialmedia} />;
    case "Theme-5":
      return <Footer11 company={company} socialmedia={socialmedia} />;
    // default:
    //   return <Footer10 company={company} socialmedia={socialmedia} />;
  }
};

export default FooterMain;
