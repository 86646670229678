import React from "react";
import TC1 from "../TopCategory/TC1";
import TC2 from "../TopCategory/TC2";
import TC3 from "../TopCategory/TC3";
import TC4 from "../TopCategory/TC4";
import TC5 from "../TopCategory/TC5";
import TC6 from "../TopCategory/TC6";
import TC7 from "../TopCategory/TC7";
import TC10 from "../TopCategory/TC10";

const TopCategoryMain = ({topcategory_type, category}) => {
  // console.log("topcategory_type", category);

  switch (topcategory_type) {
    case "TC1":
      return <TC1 category={category} />;
    case "TC2":
      return <TC2 category={category} />;
    case "TC3":
      return <TC3 category={category} />;
    case "TC4":
      return <TC4 category={category} />;
    case "TC5":
      return <TC5 category={category} />;
    case "TC6":
      return <TC6 category={category} />;
    case "TC7":
      return <TC7 category={category} />;
    case "TC10":
      return <TC10 category={category} />;
    default:
      return <TC10 category={category} />;
  }
};

export default TopCategoryMain;
