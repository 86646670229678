import React from "react";
import QuestionBanner from "../QuestionBanner/QuestionBanner";

const HaveQuestionMain = ({ have_question, contact }) => {
  switch (have_question) {
    case "QuestionBanner":
      return <QuestionBanner contact={contact} />;
   
    default:
      return <QuestionBanner contact={contact} />;
  }
};

export default HaveQuestionMain;
