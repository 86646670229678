import React from 'react'
import Features1 from '../Features/Features1';
import Features2 from '../Features/Features2';

const FeaturesMain = ({features_type, homecustom}) => {
    switch (features_type) {
        case "F1":
          return <Features1 homecustom={homecustom} />;
        case "F2":
          return <Features2 homecustom={homecustom} />;
        default:
          return <Features2 />;
      }
}

export default FeaturesMain