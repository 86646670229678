import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  HomeOutlined,
  AppstoreOutlined,
  BarsOutlined,
} from "@ant-design/icons";

import {
  Breadcrumb,
  Menu,
  Slider,
  Segmented,
  Select,
  Button,
  Pagination,
  Divider,
  Row,
  Col,
  Input,
  Empty,
  Form
} from "antd";

import { font, theme } from "../ApiService/Theme";
import crud_service from "../ApiService/ApiService";
import API from "../ApiService/ApiService";
import { base_url } from "../config";
// import { useHistory } from 'react-router-dom';

import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "../Redux/slices/product";
import { styles } from "../ApiService/Data";

import { debounce } from "lodash";

const { Option } = Select;

const api = new API();
let price_Range = [];

const Shop = () => {
  //to get the params value
  const location = useLocation();
  // const history = useHistory();
  const params = new URLSearchParams(location.search);
  const catName = params.get("catName");
  const [pageView, setPageView] = useState("grid");
  const catId = params.get("catId");
  const product_name = params.get("product_name");

  // console.log("price_Range", price_Range)

  const navigate = useNavigate();

  // console.log("catName", catName)

  const dispatch = useDispatch();


  //getting categoty from redux
  const category = useSelector((state) => state?.CetgoryMenu?.CetgoryMenu);

  // const tax = useSelector((state) => state?.tax?.data);

  const products = useSelector(
    (state) => state?.product?.product?.data[0].data
  );
  // console.log("products", products)

  const totalProducts = useSelector(
    (state) => state?.product?.product?.data[0].totalCount[0]?.total
  );



  // const [menu, setMenu] = useState(category);

  //states for product filter
  const [categoryId, setCategoryId] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [priceRange, setPriceRange] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); //holding current page number for pagination
  const [perPage, setPerPage] = useState(10); //holding how much data should be available in single page
  const [categoryName, setcategoryName] = useState("");
  const [minMax, setMinMax] = useState([0, price_Range?.max_price]);



  //to check if any filter applied
  const anyFilterApplied =
    categoryId !== "" ||
    sortOption !== "" ||
    priceRange.length > 0 ||
    categoryName !== "" ||
    searchQuery !== "";

  // console.log("anyFilterApplied", anyFilterApplied);


  //clear all filters
  const clearAllFilters = () => {
    const currentPathname = window.location.pathname.toLowerCase();
    const currentSearch = window.location.search.toLowerCase();

    // console.log("currentPathname", currentPathname);
    // console.log("currentSearch", currentSearch);

    if (currentSearch) {
      // console.log("/products-->");
      navigate('/products');
      setCategoryId("");
      setSortOption("");
      setPriceRange([]);
      setSearchQuery("");
      setcategoryName("")
    } else {
      // console.log("currentSearch-->");
      setCategoryId("");
      setSortOption("");
      setPriceRange([]);
      setSearchQuery("");
      // setcategoryName("")
      navigate('/products');
    }


    // setCategoryId("");
    // setSortOption("");
    // setPriceRange([]);
    // setSearchQuery("");
  };

  // console.log("minMax", minMax)

  const getPriceRange = () => {
    api.getPriceRange().then((res) => {
      // console.log("price range", res)
      price_Range = res?.data?.data[0]
      // console.log("priceRange", priceRange)
      setMinMax([price_Range?.min_price, price_Range?.max_price])
    })
  };

  useEffect(() => { getPriceRange() }, [])

  const itemNav = [
    {
      href: "/",
      title: <HomeOutlined />,
    },
    // {
    //   href: "/products",
    //   title: "Shop",
    // },
  ];

  const itemNav1 = [
    {
      href: "/products",
      title: "Clear all",
    },
  ];

  //to calculate the total page number
  const totalPageCount = Math.ceil(totalProducts / perPage);

  //get params value if someone try to access the product using catogery name
  const setPrams = () => {
    if (catName) {
      setcategoryName(catName);
    } else if (catId) {
      setCategoryId(catId);
    }
  };

  // console.log("categoryId", categoryId);
  // console.log("sortOption", sortOption);
  // console.log("priceRange[0]", priceRange[0]);
  // console.log("priceRange[1]", priceRange[1]);
  // console.log("categoryName", categoryName);


  //when the filter apllies it takes the filter value in the params and get the products from api
  const getProducts = useCallback(async () => {
  //   console.log("getProducts");
  //   console.log("categoryId--", categoryId);
  // console.log("sortOption--", sortOption);
  // console.log("priceRange[0]--", priceRange[0]);
  // console.log("priceRange[1]--", priceRange[1]);
  // console.log("categoryName--", categoryName);
    const data = await api.getAll(
      dispatch,
      [productGetAllStart, productGetAllSuccess, productGetAllFail],
      "products",
      {
        category: categoryId,
        page: currentPage,
        perpage: perPage,
        sortfilter: sortOption,
        min: priceRange[0],
        max: priceRange[1],
        search: searchQuery || product_name,
        brandname: "",
        fields: "",
        categoryname: categoryName,
      },
      (err, res) => {

      }
    );

    // const price = data?.data?.data[0]?.priceRange[0]
    // setMinMax([price?.minPrice, price?.maxPrice])

    // console.log("product from api", data);
    getPriceRange()
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [
    categoryId,
    currentPage,
    perPage,
    sortOption,
    priceRange,
    searchQuery,
    categoryName,
    product_name,
  ]);

  // console.log("price", minMax)
  useEffect(() => {
    getProducts();
    // set
  }, [getProducts]);

  useEffect(() => {
    setPrams();
  }, [catName, catId]);

  //function handle price filter
  const handlePriceFilter = (value) => {
    setPriceRange(value);
  };

  //handle sort filter
  const handleSortProduct = (option) => {
    setSortOption(option);
  };

  //function handle pagination
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPerPage(pageSize);
  };

  const handleViewChange = (value) => {
    // console.log("value", value);
    setPageView(value);
  };

  return (
    <ShopSection>
      <div className="wrapper" style={{ padding: "60px 40px" }}>
        <div className="shop_section">
          <div className="shop_head_bar_align">
            <div className="shop_head_bar_left">
              <h1>Products</h1>
            </div>
            <div className="shop_head_bar_right">
              <Breadcrumb items={itemNav} />
            </div>
          </div>
          <div className="shop_filter_align">
            {/* <div className="shop_filter_left">
              <Input
                placeholder="Search Products..."
                allowClear
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <Divider />
              <h2>Category</h2>
              <Menu
                mode="inline"
                items={category}
                selectedKeys={[categoryId]}
                onSelect={(e) => {
                  setCategoryId(e?.key);
                }}
              />
              <Divider />
              <h2>Filter by Price</h2>
              <Slider
                range={{
                  draggableTrack: true,
                }}               
                onChange={handlePriceFilter}                
              />
              <Divider />              
            </div> */}

            <div className="shop_filter_right">
              <div className="shop_filter_right_top">
                <Input
                  placeholder="Search Products..."
                  allowClear
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  style={{
                    width: "180px",
                  }}
                />

                <Select
                  allowClear
                  label="Category"
                  placeholder="Select Category"
                  mode="inline"
                  value={categoryId ? categoryId : "--Select--"}
                  onChange={(value) => {
                    setCategoryId(value);
                  }}
                  style={{
                    width: "140px",
                  }}
                  // defaultValue={categoryId ? categoryId : "--Select--"}
                >
                  {category?.map((item) => (
                    <Option key={item?.key} value={item?.key}>
                      {item?.label}
                    </Option>
                  ))}
                </Select>


                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 10 }}>Price: </span>
                  <Slider
                    min={minMax[0]}
                    max={minMax[1]}
                    range={{
                      draggableTrack: true,
                    }}
                    onChange={handlePriceFilter}
                    style={{
                      width: "180px",
                    }}
                    defaultValue={minMax}
                  />
                </div>

                <div className="shop_filter_right_top_left">
                  <p className="shop_filter_right_top_left_totalProducts">
                    Showing of {totalProducts} results
                  </p>
                  <p
                    className="shop_filter_right_top_left_clear"
                    onClick={clearAllFilters}
                  >
                    {anyFilterApplied ? "Clear all" : ""}
                  </p>
                </div>

                <Row>
                  <Col span={24}>
                    Sort : &nbsp;
                    <Select
                      onChange={handleSortProduct}
                      placeholder="Select to Sort "
                      value={sortOption}
                    >
                      <Option key="0" value="">
                        Default Sort
                      </Option>
                      <Option key="1" value="hightolow">
                        High to Low
                      </Option>
                      <Option key="2" value="lowtohigh">
                        Low to High
                      </Option>
                      {/* <Option key="3" value="newtoold ">
                        New to Old
                      </Option>
                      <Option key="4" value="oldtonew">
                        Old to New
                      </Option> */}
                    </Select>
                  </Col>
                </Row>
                {/* <Segmented
                  options={[
                    {
                      value: "List",
                      icon: <BarsOutlined />,
                    },
                    {
                      value: "grid",
                      icon: <AppstoreOutlined />,
                    },
                  ]}
                  onChange={(value) => handleViewChange(value)}
                /> */}
              </div>
              <br />
              <br />
              {products?.length > 0 && (
                <div className="product_align_filter">
                  {products?.length > 0 &&
                    products?.map((product) => (
                      <Link to={`/product/${product?._id}`} key={product?._id} style={{ textDecoration: "none" }}>
                        <div className="product_align_filter_box">
                          <div className="p_image">
                            <img
                              src={
                                product?.images && base_url + product?.images
                              }
                              alt="Default"
                            />
                          </div>
                          <div className="p_content">
                            <h2 style={{ color: "black"}}>{product?.product_name}</h2>
                            <div className="price">
                              <div className="sp">
                                {product?.prospecdocs &&
                                  product?.prospecdocs?.length > 0
                                  ? styles?.currency +
                                  (Number(product?.prospecdocs[0]?.sp) + Number((product?.prospecdocs[0]?.sp*product?.taxdocs
                                  [0]?.tax_percentage)/100))
                                  : 0}
                              </div>
                              <div className="mrp">
                                {product?.prospecdocs &&
                                  product?.prospecdocs?.length > 0
                                  ? styles?.currency +
                                 ( product?.prospecdocs[0]?.mrp +  Number((product?.prospecdocs[0]?.sp*product?.taxdocs
                                    [0]?.tax_percentage)/100))
                                  : 0}
                              </div>
                            </div>
                            <div>
                              <Button size="medium">View Detail</Button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              )}
              {products?.length == 0 && <Empty />}

              <div className="shop_pagination">
                <Pagination
                  Current={currentPage}
                  size="small"
                  onChange={handlePaginationChange}
                  pageSizeOptions={[10, 20, 30]}
                  showSizeChanger
                  showLessItems={totalPageCount <= 2}
                  total={totalProducts}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ShopSection>
  );
};

export default Shop;

const ShopSection = styled.section`
  margin: 60px 0;
  width: 100%;
  display: inline-block;
  position: relative;
  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding: 0;
    padding-left: 0 !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
  .ant-menu-light
    > .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-submenu-title:active {
    background-color: transparent;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-submenu-title {
    margin: 0;
    width: 100%;
  }
  .ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
  .ant-menu-light.ant-menu-root.ant-menu-vertical,
  .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: 0px;
  }
  .ant-menu-inline .ant-menu-submenu,
  .ant-menu-vertical .ant-menu-submenu,
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline,
  .ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: transparent;
  }
  .ant-menu .ant-menu-submenu,
  .ant-menu .ant-menu-submenu-inline {
    background: transparent;
  }
  .shop_head_bar_left {
  }

  .shop_section {
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .shop_head_bar_align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px;
  }
  .shop_filter_align {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .shop_filter_left {
    width: 21%;
    display: inline-block;
    border: 1px solid #f5f5f5;
    border-radius: 7px;
    padding: 25px;
  }
  .shop_filter_right {
    width: 100%;
    display: inline-block;
  }
  .shop_filter_left h2 {
    font-size: 19px;
    margin: 0 0 25px;
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 10px;
  }
  .ant-divider {
    margin: 35px 0;
  }
  .shop_filter_right_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .shop_filter_right_top_left {
      display: flex;
      .shop_filter_right_top_left_totalProducts {
        width: 180px;
      }
      .shop_filter_right_top_left_clear {
        cursor: pointer;
        color: #ff0000;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }

  /* .product_align_filter .product_align_filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 25px 0 50px 0;
  } */

  .product_align_filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 25px 0 50px 0;
    justify-content: center;
  }

  .product_align_filter_List {
    width: 100%;
    gap: 20px;
  }

  .product_align_filter_box_List {
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .p_image_List img {
    width: 35%;
    height: auto;
    border-radius: 4px;
  }

  .p_content_List {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    list-style-type: none;
  }

  .price_List {
    display: flex;
    gap: 20px;
    line-height: 60px;
    margin-top: 8px;
  }

  .price_List .sp {
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: #000;
    font-family: ${font?.bold};
  }

  .price_List .sp {
    color: #000;
    font-size: 18px;
    text-decoration: none;
  }

  .price_List .mrp {
    text-decoration: line-through;
    color: #c9c7c7;
  }

  .product_align_filter_box {
    width: 100%;
    border-radius: 7px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
  }
  .p_image {
    width: 100%;
    display: inline-block;
  }
  .p_image img {
    width: 100%;
  }
  .p_content {
    padding: 20px 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-decoration: none;
  }
  .p_content h2 {
    font-size: 17px;
    font-family: ${font?.bold};
    color: ${font?.color};
    text-decoration: none;
  }
  .price {
    display: flex;
    align-items: center;
    gap: 8px 11px;
  }
  .price div:nth-child(1) {
    color: ${theme?.color};
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
  }
  .price div:nth-child(2) {
    font-size: 14px;
    color: ${theme?.gray};
    text-decoration: line-through;
  }
  .p_content button {
    padding: 2px 9px;
    height: auto;
    font-size: 14px;
  }
  .p_content button * {
    font-size: 14px;
  }
  .shop_pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    .shop_filter_align {
      flex-direction: column;
      gap: 20px;
    }

    .shop_filter_left,
    .shop_filter_right {
      width: 100%;
    }

    .product_align_filter {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const LoginSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }
  .primary_btn {
    background: ${styles?.background1};
    /* border: 1px solid ${styles?.colorapi}; */
  }
`;
const LoginAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  min-height: 300px;

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const LoginRight = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 35px 35px;
  input {
    width: 100%;
    padding: 8px 14px;
  }
  .Forgot_Password {
    width: 100%;
    text-align: right;
    margin: 0;
    Link {
      color: #000;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  input[type="password"] {
    width: 100%;
    padding: 4px 0px;
  }
  .ant-space {
    width: 100%;
    margin: 0 0 10px;
  }
  button {
    padding: 7px 20px;
    height: auto;
    font-size: 15px;
    background: ${styles.background};
    border: 1px solid ${styles.background};
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }

  @media screen and (max-width: 580px) {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ForgetPassword = styled.div`
  margin: 25px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
`;
const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
