import React from 'react'
import FP1 from '../FeatureProduct/FP1'
import FP2 from '../FeatureProduct/FP2'
import FP3 from '../FeatureProduct/FP3'
import FP4 from '../FeatureProduct/FP4'
import FP5 from '../FeatureProduct/FP5'
import FP6 from '../FeatureProduct/FP6'
import FP10 from '../FeatureProduct/FP10'


const FeatureProductMain = ({featuredproduct_type, featureProduct}) => {
  // console.log("featureProduct---?", featureProduct)
  // console.log("featuredproduct_type", featuredproduct_type)
  switch (featureProduct) {
    case 'FP1':
      return <FP1 featuredproduct_type={featureProduct} />
    case 'FP2':
      return <FP2 featuredproduct_type={featureProduct} />
    case 'FP3':
      return <FP3 featuredproduct_type={featureProduct} />
    case 'FP4':
      return <FP4 featuredproduct_type={featureProduct} />
    case 'FP5':
      return <FP5 featuredproduct_type={featureProduct} />
    case 'FP6':
      return <FP6 featuredproduct_type={featureProduct} />
    case 'FP10':
      return <FP10 featureProduct={featureProduct} />
    default:
      return <FP10 featureProduct={featureProduct} />
  }
}

export default FeatureProductMain