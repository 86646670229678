import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import {Form, Input, Radio, Button, message} from "antd";
import SideBar from "../../Ecommerce/SideBar";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../../Redux/slices/user";
import API from "../../ApiService/ApiService";
import {styles} from "../../ApiService/Data";
import {useSelector, useDispatch} from "react-redux";
import { logoutUser } from "../../Redux/slices/user";
import crud_service from "../../ApiService/CrudService";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const crud = new crud_service();
  const profile = useSelector((state) => state?.profile?.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginTrue = useSelector((state) => state?.user?.user?.token);
  const user = JSON.parse(localStorage.getItem("user"));
  const api = new API();
  const userName = localStorage.getItem("user_name");
  const password = localStorage.getItem("password");  

  const logout = () => {
    dispatch(logoutUser(null));    
    localStorage.clear();
    navigate("/login");
    // window.location.reload();
  };

  const myProfileForm = async (values) => {
    try {
      const resetPasswordData = {
        ...values,
        buyer_id: user?._id,
      };
        const res = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "changepassword",
        resetPasswordData
      );
      if (res?.status === 200) {
        message.success(res?.data?.message);
        logout();
      } else {
        message.error(res?.response?.data?.message);
      }
    } catch (error) {
      message.error("Something went wrong");
      console.error("Login error:", error);
    }
  };
  

  return (
    <MyProfileSection>
      <div className="wrapper" style={{padding:"60px 40px"}}>
        <div>
          <Title>Change Password</Title>
          <div className="my_profile_section">
            <div className="">
              <SideBar />
            </div>
            <div className="my_profile_right">
              <div className="my_profile_form">
                <div className="my_profile_form_left">
                  <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={myProfileForm}
                    autoComplete="off"
                  >
                    
                    <Form.Item
                      label="Current Password"
                      name="current_password"
                      rules={[
                        {                          
                          required: true,
                          message: "Please input your Current Password",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="New Password"
                      name="new_password"
                      readOnly
                      rules={[
                        {
                          required: true,
                          message: "Please enter your New Password!",
                        },
                        {
                          pattern: new RegExp (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/),
                          message: 'Password must contain At least 8 characters, one uppercase letter, one lowercase letter, and one number and one special Character.',
                        }
                      ]}  
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="my_profile_form_right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyProfileSection>
  );
};

export default ChangePassword;

const MyProfileSection = styled.section`
  margin-top: 40px;
  .my_profile_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .my_profile_section {
    margin-top: 20px;
  }
  .my_profile_form {
    padding: 25px 20px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }

  @media screen and (max-width: 500px) {
    .my_profile_section .my_profile_right {
      width: 100% !important;
    }

    .my_profile_section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      flex-direction: column !important;
    }
  }

  @media screen and (min-width: 760px) and (max-width: 1024px) {
    .my_profile_section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      flex-direction: row !important;
    }
  }
`;

const Title = styled.h1`
  font-size: 30px;
  color: ${styles.color};
  margin: 30px 0 10px;
`;
