import React from 'react'
import BannerMain from '../Pages/BannerMain'
import TopCategoryMain from '../Pages/TopCategoryMain'
import FeatureProductMain from '../Pages/FeatureProductMain'
import DynamicSection from '../Pages/DynamicSection'
import Blog from '../Pages/Blogs/Blog'
import styled from 'styled-components'
import GiftProductsSectionMain from '../Pages/GiftProductsSectionMain'
import GiftSectionMain from '../Pages/GiftSectionMain'
import OfferBannerMain from '../Pages/OfferBannerMain'
import GiftStoriesSectionMain from '../Pages/GiftStoriesSectionMain'
import ProductsAndServiceMain from '../Pages/ProductsAndServicesMain'
import WhyWeUsSectionMain from '../Pages/WhyWeUsSectionMain'
import BrandMain from '../Pages/BrandMain'
import BestSellerMain from '../Pages/BestSellerMain'
import HaveQuestionMain from "../Pages/HaveQuestionMain"
import AboutUsClientSectionMain from "../Pages/AboutUsClientSectionMain"

const Template7 = ({homecustom, banner, category, product}) => {
  

// console.log("homecustom----------", homecustom)
  return (
    <HomeSection>
      <TopCategoryMain topcategory_type="TC7" category={homecustom?.top_category} />
       <BannerMain banner_type={homecustom?.banner_type?.banner_type} banner={homecustom?.banner_type} />
      <div className='section_wrap'>
        <ProductsAndServiceMain product_service={homecustom?.product_service} />
        <GiftSectionMain gift_section="GS1" product={homecustom?.unique_gifts} />
        <GiftStoriesSectionMain gift_stories_sec="GSC1" category={homecustom?.category}/>
        <OfferBannerMain offer_type="OB1" offer_banner={homecustom?.offer_banner} />
        <GiftProductsSectionMain gift_product="GP1" product={homecustom?.home_product} />
        <WhyWeUsSectionMain why_us_type="why_us_1" why_us={homecustom?.whychoose_us}/>
        <BrandMain brand_type="Brand1" brand={homecustom?.top_brand} />
        <BestSellerMain best_seller="BestSeller1" best_selling={homecustom?.best_selling} />
        <HaveQuestionMain have_question="QuestionBanner" contact={homecustom?.contactus_banner}/>
        <AboutUsClientSectionMain about_us_client="AC1" review={homecustom?.review}/> 
      </div>
    </HomeSection>
  )
}

export default Template7

const HomeSection = styled.section`
overflow-x: hidden;
  .section_wrap {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin: 100px 0;
    gap: 40px 0;
  }


`