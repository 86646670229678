import { createSlice } from "@reduxjs/toolkit";

const updateAddress = createSlice({
  name: "address",
  initialState: {
    address: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    updateAddressStart: (state) => {
      state.isFetching = true;
    },
    updateAddressSuccess: (state, action) => {
      state.isFetching = false;
      state.address = action.payload;
    },
    updateAddressFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    emptyUpdateAddress: (state, action) => {
      state.address = action.payload;
    }
  },
});

export const {
  updateAddressStart,
  updateAddressSuccess,
  updateAddressFail,
  emptyUpdateAddress,
} = updateAddress.actions;
export default updateAddress.reducer;
