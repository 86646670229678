import { createSlice } from "@reduxjs/toolkit";

const cancellationpolicy = createSlice({
  name: "cancellationpolicy",
  initialState: {
    cancellationpolicy: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    registerGetAllStart: (state) => {
      state.isFetching = true;
    },
    registerGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.cancellationpolicy = action.payload;
    },
    registerGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    cancellationpolicyGetAllStart: (state) => {
      state.isFetching = true;
    },
    cancellationpolicyGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.cancellationpolicy = action.payload;
    },
    cancellationpolicyGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    logoutcancellationpolicy: (state, action) => {
      state.cancellationpolicy = action.payload;
    },
  },
});

export const {
  cancellationpolicyGetAllStart,
  cancellationpolicyGetAllSuccess,
  cancellationpolicyGetAllFail,
  logoutcancellationpolicy,
  registerGetAllStart,
  registerGetAllSuccess,
  registerGetAllFail,
} = cancellationpolicy?.actions;
export default cancellationpolicy.reducer;
