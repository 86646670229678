import React, { useEffect, useState, useCallback } from "react";
import crud_service from "./ApiService/CrudService";
import { useDispatch } from "react-redux";
import {
  homeGetAllStart,
  homeGetAllSuccess,
  homeGetAllFail,
} from "./Redux/slices/homeSlice";
import {
  aboutusGetAllStart,
  aboutusGetAllSuccess,
  aboutusGetAllFail,
} from "./Redux/slices/aboutus";
import {
  privacypolicyGetAllStart,
  privacypolicyGetAllSuccess,
  privacypolicyGetAllFail,
} from "./Redux/slices/privacypolicy";
import {
  refundpolicyGetAllStart,
  refundpolicyGetAllSuccess,
  refundpolicyGetAllFail,
} from "./Redux/slices/refundpolicy";
import {
  deliverypolicyGetAllStart,
  deliverypolicyGetAllSuccess,
  deliverypolicyGetAllFail,
} from "./Redux/slices/deliverypolicy";
import {
  returnpolicyGetAllStart,
  returnpolicyGetAllSuccess,
  returnpolicyGetAllFail,
} from "./Redux/slices/returnpolicy";
import {
  cancellationpolicyGetAllStart,
  cancellationpolicyGetAllSuccess,
  cancellationpolicyGetAllFail,
} from "./Redux/slices/cancellationpolicy";
import {
  homeCustomGetAllStart,
  homeCustomGetAllSuccess,
  homeCustomGetAllFail,
} from "./Redux/slices/homeCustomSlice";
import {
  socialMediaGetAllStart,
  socialMediaGetAllSuccess,
  socialMediaGetAllFail,
} from "./Redux/slices/socialMedia";
import {
  companyGetAllStart,
  companyGetAllSuccess,
  companyGetAllFail,
} from "./Redux/slices/company";
import {
  bannerGetAllStart,
  bannerGetAllSuccess,
  bannerGetAllFail,
} from "./Redux/slices/banner";
import {
  categoryGetAllStart,
  categoryGetAllSuccess,
  categoryGetAllFail,
} from "./Redux/slices/category";
import {
  CetgoryMenuGetAllStart,
  CetgoryMenuGetAllSuccess,
  CetgoryMenuGetAllFail,
} from "./Redux/slices/categoryMenu";
import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "./Redux/slices/product";
import {
  taxGetAllStart,
  taxGetAllSuccess,
  taxGetAllFail,
} from "./Redux/slices/tax";
import {
  getSigleProductStart,
  getSigleProductSuccess,
  getSigleProductFail,
} from "./Redux/slices/singleProduct";
import {
  profileGetAllStart,
  profileGetAllSuccess,
  profileGetAllFail,
} from "./Redux/slices/profile";
import {
  contactusGetAllStart,
  contactusGetAllSuccess,
  contactusGetAllFail,
} from "./Redux/slices/contactus";
import "./Assets/Css/style.css";
import FooterMain from "./Components/Pages/FooterMain";
import HeaderMain from "./Components/Pages/HeaderMain";
import {
  termsAndConditionGetAllStart,
  termsAndConditionGetAllSuccess,
  termsAndConditionGetAllFail,
} from "./Redux/slices/termsAndCondition";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { Alert, Flex, Spin } from "antd";
import { commonRouter } from "./ApiService/DynamicRouter";
import { useSelector } from "react-redux";
import Blog from "./Components/Pages/Blogs/Blog";
import "./App.css";

const App = () => {
  const crud = new crud_service();
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  //getInitRender
  const getInitRender = async () => {
    await crud.getAll(
      dispatch,
      [homeGetAllStart, homeGetAllSuccess, homeGetAllFail],
      "section",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [aboutusGetAllStart, aboutusGetAllSuccess, aboutusGetAllFail],
      "aboutus",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        privacypolicyGetAllStart,
        privacypolicyGetAllSuccess,
        privacypolicyGetAllFail,
      ],
      "privacypolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        refundpolicyGetAllStart,
        refundpolicyGetAllSuccess,
        refundpolicyGetAllFail,
      ],
      "refundpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        deliverypolicyGetAllStart,
        deliverypolicyGetAllSuccess,
        deliverypolicyGetAllFail,
      ],
      "deliverypolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        termsAndConditionGetAllStart,
        termsAndConditionGetAllSuccess,
        termsAndConditionGetAllFail,
      ],
      "terms-and-condition",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        returnpolicyGetAllStart,
        returnpolicyGetAllSuccess,
        returnpolicyGetAllFail,
      ],
      "returnpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        cancellationpolicyGetAllStart,
        cancellationpolicyGetAllSuccess,
        cancellationpolicyGetAllFail,
      ],
      "cancellationpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [contactusGetAllStart, contactusGetAllSuccess, contactusGetAllFail],
      "contact",
      "",
      (err, res) => {}
    );

    // await crud.getAllTax(
    //   dispatch,
    //   [taxGetAllStart, taxGetAllSuccess, taxGetAllFail],
    //   "tax",
    //   "",
    //   (err, res) => {}
    // );
    await crud.getAll(
      dispatch,
      [profileGetAllStart, profileGetAllSuccess, profileGetAllFail],
      "profile",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [homeCustomGetAllStart, homeCustomGetAllSuccess, homeCustomGetAllFail],
      "templatecustom",
      "",
      (err, res) => {
        // console.log("res------------->",res)
      }
    );
    await crud.getAll(
      dispatch,
      [socialMediaGetAllStart, socialMediaGetAllSuccess, socialMediaGetAllFail],
      "socialmedia",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [companyGetAllStart, companyGetAllSuccess, companyGetAllFail],
      "company",
      "",
      (err, res) => {
        // console.log(res)
      }
    );
    await crud.getAll(
      dispatch,
      [bannerGetAllStart, bannerGetAllSuccess, bannerGetAllFail],
      "banner",
      "",
      (err, res) => {}
    );

    await crud.getAll(
      dispatch,
      [categoryGetAllStart, categoryGetAllSuccess, categoryGetAllFail],
      "category",
      "",
      (err, res) => {}
    );
    await crud.getAllCategory(
      dispatch,
      [CetgoryMenuGetAllStart, CetgoryMenuGetAllSuccess, CetgoryMenuGetAllFail],
      "category",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [productGetAllStart, productGetAllSuccess, productGetAllFail],
      "products",
      {
        page: 1,
        perpage: 10,
      },
      // "",
      (err, res) => {}
    );
    setIsLoading(false);
  };
  const Wrapper = ({ children }) => {
    const location = useLocation();
    React.useLayoutEffect(() => {
      document.documentElement.scrollTo(500, 0);
    }, [location?.pathname]);
    return children;
  };
  //init
  useEffect(() => {
    getInitRender();
  }, []);
  const homecustom = useSelector(
    (state) => state?.homeCustomSlice?.homecustom?.data[0]
  );

  // console.log("homecustom from app", homecustom);
  const company = useSelector((state) => state?.company?.company?.data[0]);
  const socialmedia = useSelector(
    (state) => state?.socialMedia?.socialmedia?.data
  );

  return (
    <>
      {isLoading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 999,
          }}
        >
          <Router>
          <Wrapper>
            <HeaderMain
              header_type={homecustom?.theme ? homecustom?.theme : ""}
              socialmedia={socialmedia}
              home_custom={homecustom}
              company={company}
            />
            <Routes>
              {commonRouter?.map((e, i) => (
                <>
                  <Route key={i} exact path={e?.path} element={e?.element} />
                </>
              ))}
            </Routes>

            <FooterMain
              footer_type={homecustom?.theme ? homecustom?.theme : ""}
              company={company}
              socialmedia={socialmedia}
            />
          </Wrapper>
        </Router>
        </Spin>
      ) : (
        <Router>
          <Wrapper>
            <HeaderMain
              header_type={homecustom?.theme ? homecustom?.theme : ""}
              socialmedia={socialmedia}
              home_custom={homecustom}
              company={company}
            />
            <Routes>
              {commonRouter?.map((e, i) => (
                <>
                  <Route key={i} exact path={e?.path} element={e?.element} />
                </>
              ))}
            </Routes>

            <FooterMain
              footer_type={homecustom?.theme ? homecustom?.theme : ""}
              company={company}
              socialmedia={socialmedia}
            />
          </Wrapper>
        </Router>
      )}
    </>
  );
};

export default App;
