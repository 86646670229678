import React from "react";
import styled from "styled-components";
import {Input} from "antd";
import image1 from "../../Assets/Images/GirlsHub Template/f-1.png";
import image2 from "../../Assets/Images/GirlsHub Template/f-2.png";
import image3 from "../../Assets/Images/GirlsHub Template/f-3.png";
import image4 from "../../Assets/Images/GirlsHub Template/f-4.png";
import image5 from "../../Assets/Images/GirlsHub Template/f-5.png";
import image6 from "../../Assets/Images/GirlsHub Template/f-6.png";
import image7 from "../../Assets/Images/GirlsHub Template/f-7.png";
import image8 from "../../Assets/Images/GirlsHub Template/f-8.png";
import image9 from "../../Assets/Images/GirlsHub Template/f-9.png";
import image10 from "../../Assets/Images/GirlsHub Template/f-10.png";
import image11 from "../../Assets/Images/GirlsHub Template/f-11.png";
import image12 from "../../Assets/Images/GirlsHub Template/f-12.png";
import image13 from "../../Assets/Images/GirlsHub Template/f-13.png";
import image14 from "../../Assets/Images/GirlsHub Template/f-14.png";
import { Link } from "react-router-dom"
import { base_url } from "../../config";

const Footer10 = ({company}) => {
  console.log("company=====>",company)
  const Icons = [
    {
      image: image1,
    },
    {
      image: image2,
    },
    {
      image: image3,
    },
    {
      image: image4,
    },
    {
      image: image5,
    },
    {
      image: image6,
    },
    {
      image: image7,
    },
    {
      image: image8,
    },
    {
      image: image9,
    },
    {
      image: image10,
    },
    {
      image: image11,
    },
    {
      image: image12,
    },
    {
      image: image13,
    },
    {
      image: image14,
    },
  ];

  return (

    <Section>
      <div className="container">
        <div className="left_footer">
          <div className="nav_links_container">
            <h3>Help</h3>
            <ul className="nav_links">
              <li><Link to="/my-order" style={{ textDecoration: 'none' , color: '#000'}}>Track Your Order</Link></li>
              <li><Link to="/return-policy" style={{ textDecoration: 'none' , color: '#000'}}>Exchange / Return Policy</Link></li>
              <li><Link to="/privacy-policy" style={{ textDecoration: 'none' , color: '#000'}}>Privacy & Policy</Link></li>
              {/* <li>Shipping Policy</li> */}
              <li><Link to="/terms-and-condition" style={{ textDecoration: 'none' , color: '#000'}}>Terms & Conditions</Link></li>
            </ul>
          </div>
          <div className="nav_links_container">
            <h3>About Us</h3>
            <ul className="nav_links">
              <li><Link to="/about" style={{ textDecoration: 'none' , color: '#000'}}>Our Story</Link></li>
              {/* <li>FAQ’s</li> */}
              <li><Link to="/contactus" style={{ textDecoration: 'none' , color: '#000'}}>Contact Us</Link></li>
            </ul>
          </div>
          <div className="nav_links_container">
            <h3>Help</h3>
            <ul className="nav_links">
              <li><Link to="/products" style={{ textDecoration: 'none' , color: '#000'}}>Maternity Leggins</Link></li>
              <li><Link to="/products" style={{ textDecoration: 'none' , color: '#000'}}>Zipless Feeding T-shirts</Link></li>
              <li><Link to="/products" style={{ textDecoration: 'none' , color: '#000'}}>Zipless Feeding Gown</Link></li>
              <li><Link to="/products" style={{ textDecoration: 'none' , color: '#000'}}>Feeding Kaftan Wear</Link></li>
            </ul>
          </div>
        </div>
        <div className="right_footer">
          <p className="title">SIGN UP FOR GIRLSHUB Closet STYLE NEWS</p>
          <div className="input_container">
            <input placeholder="your email" />
            <button>SUBSCRIBE </button>
          </div>
          <u></u>
          <p className="subscription">
            By clicking the SUBSCRIBE button, you are agreeing to our{" "}
            <u><Link to="/privacy-policy" style={{ textDecoration: 'none' , color: '#000'}}>Privacy & Cookie Policy</Link></u>
          </p>
        </div>
      </div>
      <div className="footer_bottom_Main">
        <div className="footer_bottom_left">
          <p className="rights">
            ©2010-2022 Laura’s Closet All Rights Reserved
          </p>
          <ul className="Bottom_List">
            <li><Link to="/privacy-policy" style={{ textDecoration: 'none' , color: '#000'}}>Privacy Center</Link></li>
            <li><Link to="/privacy-policy" style={{ textDecoration: 'none' , color: '#000'}}>Privacy & Cookie Policy</Link></li>
            <li>Manage Cookies</li>
            <li><Link to="/terms-and-condition" style={{ textDecoration: 'none' , color: '#000'}}>Terms & Conditions</Link></li>
            <li>Copyright Notice</li>
            <li>Imprint</li>
          </ul>
        </div>
        <div className="footer_bottom_right">
          <p className="Title">We accept</p>
          <div className="icons_Main">
            {Icons.map((icon, index) => (
              <div className="icon" key={index}>
                <img src={icon.image} alt="icons" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="Footer_Bottom_bar">
        <div className="Left_Bar">Copyright reserved@Girl Hub</div>
        <div className="Right_Bar">Designed by Blazon</div>
      </div>
    </Section>
  );
};

export default Footer10;

const Section = styled.section`
  background-color: #fbfbfb;
  padding-top: 100px;
  /* padding-bottom: 50px; */

  .container {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    .left_footer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 60%;

      .nav_links_container {
        h3 {
          color: #000;
          /* font-family: Euclid Circular A; */
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 26.4px */
        }
        ul {
          margin: 0;
          padding: 0;

          li {
            font-size: 14px;
            margin: 7px 0;
          }
        }
      }
    }
    .right_footer {
      width: 40%;

      .input_container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;

        input {
          width: 60%;
          padding-left: 10px;
        }

        button {
          background: var(--grey-grey-800, #1f2937);
          display: flex;
          padding: 7px 30px;
          align-items: flex-start;
          gap: 10px;
          color: white;
          border: none;
        }
      }

      .title {
        color: #000;
        /* font-family: Euclid Circular A; */
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .subscription {
        color: #000;
        /* font-family: Euclid Circular A; */
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
      }
    }
  }
  .bottom_footer {
    padding-top: 25px;

    .rights {
    }
  }

  .footer_bottom_Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
  }

  .footer_bottom_Main .footer_bottom_right {
    width: 30%;
  }

  .footer_bottom_Main .footer_bottom_right .icons_Main {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
  }

  .footer_bottom_Main .footer_bottom_left {
    width: 50%;
  }

  .footer_bottom_Main .footer_bottom_left .Bottom_List {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .footer_bottom_Main .footer_bottom_left .Bottom_List li {
    color: #000;

    font-family: Euclid Circular A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    text-decoration-line: underline;
  }

  .footer_bottom_Main .footer_bottom_left .rights {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    padding-left: 20px;
  }

  .Footer_Bottom_bar {
    width: 100%;
    height: 44.441px;
    flex-shrink: 0;
    background: #f65c7f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffff;
    padding: 0 100px;
    margin-top: 40px;
  }

  .Footer_Bottom_bar .Left_Bar,
  .Footer_Bottom_bar .Right_Bar {
    color: #fff;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
