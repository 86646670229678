import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {Tag, Button, Divider, Popconfirm, message} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import API from "../ApiService/ApiService";
import Default from "../Assets/Images/default.png";
import styles from "../ApiService/Data";

const CartList = () => {
  const api = new API();
  const [cart, setCart] = useState([]);

  useEffect(() => {
    getAllCart();
  }, []);

  const getAllCart = () => {
    api.getcart().then((res) => {
      setCart(res?.data?.data);
    });
  };

  const handleDelete = (id) => {
    api.deletecart(id).then((res) => {
      // console.log("delete-->", res);
      message.success("deleted Successfully");
      getAllCart();
    });
  };       

  return (
    <CartListSection>
      <div className="section_align">
        <div className="cart_list" id="style-10">
          {cart?.cart_item?.map((item) => {
            // console.log("item--->", item);
            return (
              <div className="cart_box" key={item?._id}>
                <div className="cart_box_left">
                  <img
                    src={
                      api.rootUrl1 +
                      item?.product_spec?.product_images[0]?.image_path
                    }
                    alt="Cart"
                  />
                </div>
                <div className="cart_box_right">
                  <h2>{item?.product_id?.product_name}</h2>
                  <h4>{item?.product_id?.sku}</h4>
                  <div className="cart_specification">
                    {item?.product_spec?.specification?.map((spec, index) => {
                      // console.log("spec-->", spec);
                      const foundSpec = item?.product_spec?.specification?.find(
                        (s) => s?._id === spec?._id
                      );
                      return (
                        <Tag color="green" key={spec?._id}>
                          {foundSpec?.specification_details}
                        </Tag>
                      );
                    })}
                  </div>
                  <div className="price">
                    <div className="sp">
                      {styles?.currency + (Number(((item?.product_spec?.sp - ((item?.product_spec?.sp *item?.per_product_discount_amount)/100)))) + Number(
                           ( item?.product_spec?.sp- ((item?.product_spec?.sp *item?.per_product_discount_amount)/100)) * ((item.tax)/100)
                          ))}
                    </div>
                    <div className="mrp">
                      {styles?.currency + item?.product_spec?.mrp}
                    </div>
                    <div className="qty">
                      x&nbsp;{ item?.qty} Qty{" "}
                    </div>
                  </div>
                  <div className="cart_price_description">
                  {item?.discount_amount > 0 ? (
                        <>
                        <div className="Tax" style={{ color: "green" }}>
                          Discount Applicable                       {item?.discount_amount > 0 ? " (" + styles.currency + item?.discount_amount + ")" : ""}
                        </div>
                        <br/>
                        </>

                      
                      ) : null}
                    <p>
                      {" "}
                      Item Total :
                      {styles?.currency + (Number(((item?.product_spec?.sp - ((item?.product_spec?.sp *item?.per_product_discount_amount)/100)))) + Number(
                           ( item?.product_spec?.sp- ((item?.product_spec?.sp *item?.per_product_discount_amount)/100)) * ((item.tax)/100)
                          )) * item?.qty}
                    </p>
                    
                  </div>

                  <div className="remove_cart">
                    <Popconfirm
                      title="Delete Product"
                      description="Are you sure to delete this product?"
                      onConfirm={() => handleDelete(item?._id)}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </CartListSection>
  );
};

export default CartList;

const CartListSection = styled.section`
  .cart_list {
    max-height: 500px !important;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    border-radius: 10px;
    background: ${styles?.background};
    padding: 20px 80px;
    filter: drop-shadow(5px 5px 10px rgba(180, 180, 180, 0.3));
  }

  #style-10::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
    padding-left: 5px;
  }

  #style-10::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
    padding-left: 10px;
  }

  #style-10::-webkit-scrollbar-thumb {
    padding-left: 10px;
    background-color: #aaa;
    border-radius: 10px;
    /* background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent) */
  }

  .section_align {
    max-width: 100%;
    margin: 50px auto auto;
    display: flex;
  }

  .cart_box {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }
  .cart_box:not(:last-child) {
    margin: 0 0 27px 0;
    padding: 0 0 27px 0;
  }
  .cart_box:not(:last-child)::after {
    content: "";
    border: 0;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    /* border-style: dashed; */
  }

  .cart_box_left {
    width: 20%;
    display: inline-block;
    border: 1px solid #f5f5f5;
  }

  .cart_box_right {
    width: 77%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding: 0 155px 0 0;
  }

  .cart_box_right h2 {
    font-size: 16px;
    font-family: var(--semibold);
  }
  .cart_box_right h4 {
    font-size: 13px;
    color: #888;
    font-family: var(--light);
  }
  .cart_specification {
  }
  .price {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 11px;
    margin: 5px 0;
  }
  .price div:nth-child(1) {
    font-size: 17px;
    font-family: var(--semibold);
  }
  .price div:nth-child(2) {
    font-size: 14px;
    font-family: var(--light);
    color: #888;
    text-decoration: line-through;
  }
  .cart_quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 9px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .cart_quantity button {
    padding: 0;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cart_quantity button span {
    font-size: 16px;
    font-family: var(--semibold);
  }
  .cart_quantity input {
    padding: 5px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 60px;
    text-align: center;
    outline: none;
  }
  .remove_cart {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
    cursor: pointer;
  }
  .remove_cart span {
    color: red;
  }

  @media (max-width: 500px) {
    .cart_box {
      width: 100% !important;
    }

    .cart_box_right {
      width: 100%;
      padding: 0;
    }
  }
`;
