import React from "react";
import styled from "styled-components";
import API from "../../ApiService/ApiService";

const Features1 = ({homecustom}) => {
  // console.log("Features1-------->", homecustom);
  const api = new API();

  return (
    <Section>
      <div className="main_container">
        {homecustom?.map((item, index) => (
          <div className="features" key={index}>
            <div className="feature_img">
              <img src={api.rootUrl1 + item?.image} alt="food-safe-material" />
            </div>
            <p>{item?.feature_name}</p>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Features1;

const Section = styled.section`
  .main_container {
    /* width: 1200px; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    .features {
      display: flex;
      align-items: center;
      padding: 20px 0;

      p {
        margin-bottom: 0;
        margin-left: 15px;

        color: #000;
        font-family: Brevia;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .feature_img {
        width: 50px;
        img {
          width: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .main_container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (max-width: 425px) {
    .main_container {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      justify-items: start;
      .features {
        padding: 10px 0;
        p {
          font-size: 13px;
          margin-left: 5px;
        }
      }
    }
  }
`;
