import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ShoppingCartOutlined } from "@ant-design/icons";

import API from "../../ApiService/ApiService";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { base_url } from "../../config";
import Default from "../../Assets/Images/default.png";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const FP4 = ({ featuredproduct_type }) => {
  return (
    <>
      <FESection>
        {featuredproduct_type?.length > 1 ? (
          <section className="FP_4_Feature_Product">
            <div className="wrapper_full">
              <H2>Featured Product</H2>
              <div className="FP_4_Align">
                {featuredproduct_type?.slice(0, 8).map?.((e, i) => {
                  let img;
                  if (e?.images?.length > 0) img = e?.images[0];

                  let link = "";
                  let n = e?.category?.length;
                  if (n > 0) {
                    link = `${e.category[n - 1].category_name
                      ?.toLowerCase()
                      .replace(/ /g, "-")
                      .replace(/[^\w-]+/g, "")}/${e?.description
                      ?.toLowerCase()
                      .replace(/ /g, "-")
                      .replace(/[^\w-]+/g, "")}?pid=${e?._id}`;
                  }
                  return (
                    <div className="FP_4_Box" key={`FE4_${i}`}>
                      <Link to={link}>
                        <div className="FP_4_Box_PTitle">
                          <h4>{e?.description}</h4>
                          <h6>{e?.category[0]?.category_name}</h6>
                        </div>
                        <div className="FP_4_Box_Image">
                          <img
                            alt={e?.description}
                            src={e?.prospecdocs[0]?.product_images[0]
                              ?.image_path
                              ? base_url +
                              e?.prospecdocs[0]?.product_images[0]?.image_path
                              : Default}
                          />
                        </div>
                        <div className="FP_4_Box_Content">
                          <div className="ant-card-meta-description">
                            <span className="price">
                              <span className="sp">₹{e?.prospecdocs[0]?.sp}</span>
                              <span className="mrp">₹{e?.prospecdocs[0]?.mrp}</span>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </FESection>
    </>
  );
};

export default FP4;

const H2 = styled.h1`
  font-size: ${styles?.h2};
  font-family: ${styles?.font} !important;
  color: ${colorCustom?.color ? colorCustom?.color : styles?.color} !important;
  line-height: 1.4;
  margin: 0 0 25px !important;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const FESection = styled.div`
  .FP_4_Feature_Product * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .FP_4_Feature_Product .FP_4_Align .FP_4_Box a {
    display: flex;
    flex-direction: column;
  }
  .FP_4_Feature_Product .wrapper_full {
    padding: 0 20px;
  }
  .FP_4_Feature_Product {
    padding: 0px 0;
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .FP_4_Feature_Product img {
    max-width: 100%;
  }
  .FP_4_Feature_Product .FP_4_Align {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .FP_4_Feature_Product .FP_4_Align .FP_4_Box {
    background: ${styles?.white};
    padding: 30px 20px;
    border: 1px solid ${styles?.border};
    display: grid;
  }
  .FP_4_Feature_Product .FP_4_Align .FP_4_Box a {
    display: grid;
  }
  .FP_4_Feature_Product .FP_4_Align .FP_4_Box .FP_4_Box_PTitle {
    width: 100%;
    display: inline-block;
    position: relative;
  }
  .FP_4_Feature_Product .FP_4_Align .FP_4_Box .FP_4_Box_PTitle h4 {
    color: ${styled?.color};
    font-size: 20px;
    line-height: 1.3;
    margin: 0 0 8px;
    max-width: 90%;
    font-weight: 700;
  }
  .FP_4_Feature_Product .FP_4_Align .FP_4_Box .FP_4_Box_PTitle h6 {
    font-size: 13px;
    line-height: 1.5;
    color: ${styles?.gray};
    letter-spacing: 0.4px;
  }
  .FP_4_Feature_Product .FP_4_Align .FP_4_Box .FP_4_Box_Image {
    display: flex;
    align-items: center;
    height: 250px;
    width: 80%;
    margin: 25px auto 25px auto;
  }
  .FP_4_Feature_Product .FP_4_Align .FP_4_Box .FP_4_Box_Image img {
    height: 100%;
    width: auto;
    object-fit: contain;
    margin: auto;
    display: block;
  }
  .FP_4_Feature_Product
    .FP_4_Align
    .FP_4_Box
    .FP_4_Box_Content
    .ant-card-meta-description
    span.price {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
  }

  .FP_4_Feature_Product
    .FP_4_Align
    .FP_4_Box
    .FP_4_Box_Content
    .ant-card-meta-description
    span.price
    span.sp {
    /* color: #40a9ff; */
    font-size: 20px;
    font-weight: 700;
  }

  .FP_4_Feature_Product
    .FP_4_Align
    .FP_4_Box
    .FP_4_Box_Content
    .ant-card-meta-description
    span.price
    span.mrp {
    /* color: #ff7875; */
    font-size: 15px;
    font-weight: 700;
     text-decoration: line-through;
  }

  @media screen and (max-width: 992px) {
    .FP_4_Feature_Product .FP_4_Align {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 580px) {
    .FP_4_Feature_Product .FP_4_Align .FP_4_Box .FP_4_Box_Image {
      height: 140px;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .FP_4_Feature_Product .FP_4_Align {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;