import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import footerImage from "../../Assets/Images/footer_card.png";
import { Link } from "react-router-dom"
import { base_url } from "../../config";

const Footer7 = ({company}) => {
  // console.log("company=====>",company?.logo)
  return (
    <FooterAlign>
      <div className="home_wrapper">
        <div className="Footer_Section">
          <div className="Footer_Top">
            <div className="Footer_Top_Main">
              <div className="Footer_Top1">
                <div className="Logo_Image">
                  <Link to="/">
                  <img src={company?.logo ? base_url + company?.logo : require("../../Assets/Images/logo.png")} alt="logo" />
                  </Link>
                </div>
                <div className="content">
                  <p>
                    An online gift shop for unique, funny and creative gifts for
                    guys and girls. At bigsmall.in, explore the most unique
                    gifts for any kind of person, or occasion.
                  </p>
                </div>
                <div className="Online_Card">
                  <img src={footerImage} alt="" />
                </div>
              </div>
              <div className="Footer_Top2">
                <p className="Footer_Top2_Title">Reach us</p>
                <p>
                  <svg
                    className="svg_Mobile"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21.7388 16.4136C21.5716 17.6839 20.9477 18.85 19.9837 19.6939C19.0196 20.5379 17.7813 21.0021 16.5 20.9998C9.05626 20.9998 3.00001 14.9436 3.00001 7.49981C2.99771 6.21852 3.4619 4.98021 4.30588 4.01615C5.14986 3.05209 6.31592 2.42822 7.58626 2.26106C7.9075 2.22184 8.2328 2.28756 8.51362 2.44841C8.79444 2.60926 9.0157 2.85662 9.14438 3.15356L11.1244 7.57387V7.58512C11.2229 7.81242 11.2636 8.06058 11.2428 8.30744C11.222 8.5543 11.1404 8.79217 11.0053 8.99981C10.9884 9.02512 10.9706 9.04856 10.9519 9.072L9.00001 11.3857C9.7022 12.8126 11.1947 14.292 12.6403 14.9961L14.9222 13.0545C14.9446 13.0357 14.9681 13.0181 14.9925 13.002C15.2 12.8636 15.4387 12.7792 15.687 12.7562C15.9353 12.7333 16.1854 12.7727 16.4147 12.8707L16.4269 12.8764L20.8434 14.8554C21.1409 14.9837 21.3889 15.2047 21.5503 15.4856C21.7116 15.7664 21.7778 16.092 21.7388 16.4136Z"
                      fill="#F6A82D"
                    />
                  </svg>
                  <span className="Mobile_Number">
                    {company?.contact_number}, {company?.mobile_number}
                  </span>
                </p>
                <p>
                  <svg
                    className="svg_Mobile"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21.4163 8.37562L12.4163 2.37562C12.293 2.29339 12.1482 2.24951 12 2.24951C11.8518 2.24951 11.707 2.29339 11.5837 2.37562L2.58375 8.37562C2.48101 8.44417 2.39679 8.53703 2.33857 8.64595C2.28034 8.75488 2.24992 8.87649 2.25 9V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V9C21.7501 8.87649 21.7197 8.75488 21.6614 8.64595C21.6032 8.53703 21.519 8.44417 21.4163 8.37562ZM3.75 18.75V10.4559L9.92813 14.8622C10.0553 14.953 10.2077 15.0019 10.3641 15.0019H13.6359C13.7923 15.0019 13.9447 14.953 14.0719 14.8622L20.25 10.4559V18.75H3.75Z"
                      fill="#F6A82D"
                    />
                  </svg>
                  <span className="Mobile_Number">{company?.email_id}</span>
                </p>
                <p>
                  <svg
                    className="svg_Mobile"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 1.5C9.81273 1.50248 7.71575 2.37247 6.16911 3.91911C4.62247 5.46575 3.75248 7.56273 3.75 9.75C3.75 16.8094 11.25 22.1409 11.5697 22.3641C11.6958 22.4524 11.846 22.4998 12 22.4998C12.154 22.4998 12.3042 22.4524 12.4303 22.3641C12.75 22.1409 20.25 16.8094 20.25 9.75C20.2475 7.56273 19.3775 5.46575 17.8309 3.91911C16.2843 2.37247 14.1873 1.50248 12 1.5ZM12 6.75C12.5933 6.75 13.1734 6.92595 13.6667 7.25559C14.1601 7.58524 14.5446 8.05377 14.7716 8.60195C14.9987 9.15013 15.0581 9.75333 14.9424 10.3353C14.8266 10.9172 14.5409 11.4518 14.1213 11.8713C13.7018 12.2909 13.1672 12.5766 12.5853 12.6924C12.0033 12.8081 11.4001 12.7487 10.8519 12.5216C10.3038 12.2946 9.83524 11.9101 9.50559 11.4167C9.17595 10.9234 9 10.3433 9 9.75C9 8.95435 9.31607 8.19129 9.87868 7.62868C10.4413 7.06607 11.2044 6.75 12 6.75Z"
                      fill="#F6A82D"
                    />
                  </svg>
                  <span className="Mobile_Number">
                   {company?.company_addresss} - {company?.pin_code}
                  </span>
                </p>
              </div>
              <div className="Footer_Top3">
                <p className="Footer_Top3_title">Helpful Links</p>
                <ul className="Footer_Top3_links">
                  <li><Link to="/contact" style={{textDecoration: "none", color: "white"}}>Contact us</Link> </li>
                  <li><Link to="/privacy-policy" style={{textDecoration: "none", color: "white"}}>Privacy Policy</Link></li>
                  <li><Link to="/terms-and-condition" style={{textDecoration: "none", color: "white"}}>Terms And Condition</Link> </li>
                  <li><Link to="/cancellation-policy" style={{textDecoration: "none", color: "white"}}>Cancellation Policy</Link> </li>
                  <li><Link to="/return-policy" style={{textDecoration: "none", color: "white"}}>Return Policy</Link> </li>
                  <li><Link to="/refund-policy" style={{textDecoration: "none", color: "white"}}>Refund policy</Link></li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="Footer_Middle">
            <div className="Footer_Middle_Main">
              <div className="Footer_Middle1">
                <p className="Footer_Middle_title">Our Company</p>
                <ul className="Footer_Middle_links">
                  <li>About Us </li>
                  <li> Media Coverage</li>
                  <li>Facebook Page </li>
                  <li>Product Review Program </li>
                  <li>Customer Reviews </li>
                  <li>Gift ideas</li>
                </ul>
              </div>
              <div className="Footer_Middle2">
                <p className="Footer_Middle_title">Help</p>
                <ul className="Footer_Middle_links">
                  <li>My Account </li>
                  <li>Cancellation & Refund</li>
                  <li>Shipping & Delivery Policy </li>
                  <li>Terms & Conditions </li>
                  <li>Disclaimer Policy </li>
                  <li> Privacy Policy</li>
                  <li> About Us</li>
                </ul>
              </div>
              <div className="Footer_Middle3">
                <p className="Footer_Middle_title">Occasions</p>
                <ul className="Footer_Middle_links">
                  <li>Birthday </li>
                  <li>Anniversary</li>
                  <li>Wedding </li>
                  <li>Friendship Day </li>
                  <li>Mother's Day </li>
                  <li>Father's Day</li>
                  <li>Corporate Events</li>
                </ul>
              </div>
              <div className="Footer_Middle4">
                <p className="Footer_Middle_title">Festivals</p>
                <ul className="Footer_Middle_links">
                  <li>Christmas </li>
                  <li>New Years 2024</li>
                  <li>Bhai Dooj </li>
                  <li>Teacher's Day </li>
                  <li>Diwali </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="Footer_Bottom">
            <div className="Footer_Bottom_Main">
              <p className="Footer_Bottom_title">copyright@smilegifts</p>
              <p className="Footer_Bottom_title">
                Developed by
                <span className="Blazon_Link">Blazon</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </FooterAlign>
  );
};

export default Footer7;

const FooterAlign = styled.div`
  .Footer_Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Footer_Section .Footer_Top {
    width: 100%;
    /* height: 250px; */
    flex-shrink: 0;
    background: #3d418b;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main {
    padding: 40px 0 40px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 {
    width: 40%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2,
  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 {
    width: 25%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .Logo_Image {
    border: 1px solid #fff;
    width: 152.941px;
    height:80%;
    flex-shrink: 0;
  }


  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .content {
    width: 345px;
    height: 127px;
    flex-shrink: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .Online_Card {
    width: 305.504px;
    height: 32px;
    flex-shrink: 0;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2 .Footer_Top2_Title {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2 .Mobile_Number {
    padding-left: 5px;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2 .svg_Mobile {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 .Footer_Top3_title {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 .Footer_Top3_links {
    padding-left: 5px;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Section .Footer_Middle {
    width: 100%;
    flex-shrink: 0;
  }

  .Footer_Middle .Footer_Middle_Main {
    padding: 10px 40px 10px 40px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .Footer_Middle .Footer_Middle_Main .Footer_Middle1,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle2,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle3,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle4 {
    width: 20%;
  }

  .Footer_Middle .Footer_Middle_Main .Footer_Middle1 .Footer_Middle_title,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle2 .Footer_Middle_title,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle3 .Footer_Middle_title,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle4 .Footer_Middle_title {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Middle .Footer_Middle_Main .Footer_Middle1 .Footer_Middle_links,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle2 .Footer_Middle_links,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle3 .Footer_Middle_links,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle4 .Footer_Middle_links {
    padding-left: 5px;
    color: #929090;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Bottom {
    width: 100%;
    height: auto;
    padding: 10px 0 0 0;
    flex-shrink: 0;
    background: #f6a82d;
  }

  .Footer_Bottom_Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 200px 0 85px;
  }

  .Footer_Bottom .Footer_Bottom_Main .Footer_Bottom_title {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Bottom .Footer_Bottom_Main .Blazon_Link {
    padding-left: 5px;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  @media screen and (max-width: 767px) {
    .Footer_Section .Footer_Top .Footer_Top_Main {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .content {
      width: 100%;
    }

    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1,
    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2,
    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      padding: 10px 40px ;
    }

    .Footer_Middle .Footer_Middle_Main {
      display: flex;
      flex-wrap: wrap;
    }

    .Footer_Middle .Footer_Middle_Main .Footer_Middle1,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle2,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle3,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle4 {
      width: 50%;
      text-align: left;
      margin-bottom: 20px;
    }

    .Footer_Bottom .Footer_Bottom_Main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .Footer_Middle .Footer_Middle_Main .Footer_Middle1,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle2,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle3,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle4 {
      width: 50%;
      text-align: center;
      margin-bottom: 20px;

    }
  }
`;
