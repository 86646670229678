import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import API from "../../ApiService/ApiService";
import {Button} from "antd";
import {Link} from "react-router-dom";

const OfferbannerSection = ({offer_banner}) => {
  // console.log("offer---> banner", offer_banner);
  const api = new API();

  return (
    <OfferSectionAlign>
      <div className="wrapper">
        <div className="Offer_Section_Main">
          <div className="Offer_Section">
            <Link to={offer_banner?.banner_id?.button_link}>
              <img src={api.rootUrl1 + offer_banner?.banner_id?.image} />
            </Link>
          </div>
        </div>
      </div>
    </OfferSectionAlign>
  );
};

export default OfferbannerSection;

const OfferSectionAlign = styled.div`
  .wrapper {
    padding: 0 20px;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      padding: 0;
    }
  }
`;
