import React from 'react'
import { Button, Result } from 'antd';
import { useNavigate } from "react-router-dom"

const PageUnderConstruction = () => {
    const navigate = useNavigate()
  return (
    <Result
    status="500"
    title="Page Under Construction"
    extra={<Button type="primary" onClick={()=>navigate("/")}>Back Home</Button>}
  />
  )
}

export default PageUnderConstruction