import React from 'react'
import styled from 'styled-components'
import salesBanner from "../../Assets/Images/salesBanner.png"
import API from '../../ApiService/ApiService'
import { Link } from 'react-router-dom'

const Sales = ({homecustom}) => {
  const api = new API()
  return (
    <Section>
        <div className="main_container">
            <Link to={homecustom?.button_link}><img src={api.rootUrl1 + homecustom?.image} alt="sales banner" /></Link>
        </div>
    </Section>
  )
}

export default Sales

const Section = styled.section`
.main_container{
    padding: 0;
}
`;