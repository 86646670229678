import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {Button} from "antd";
import image from "../../Assets/Images/GirlsHub Template/Trendingofmonth.png";
import API from "../../ApiService/ApiService";

let user = "";

const TrendingOfMonth1 = ({trending}) => {
  // console.log("trending", trending)
  const api = new API();

  const Banner = [
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
  ];

  return (
    <TrendingOfMonthStyle>
      <div className="home_wrapper">
        <div className="Trending_Main">
          <div className="Left_Section">
            <div className="Left_Title">
              <span className="Title">{trending?.section_title}</span>
            </div>
            <div className="Left_Description">
              <span className="description">{trending?.section_content}</span>
            </div>
            <div className="Left_Btn">
              <Button className="Button" onClick={() => (window.location.href = `/${trending?.link}`)}>
                {trending?.link_text}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="9"
                  viewBox="0 0 33 9"
                  fill="none"
                >
                  <path
                    d="M32.8536 4.69047C33.0488 4.49521 33.0488 4.17862 32.8536 3.98336L29.6716 0.80138C29.4763 0.606118 29.1597 0.606118 28.9645 0.80138C28.7692 0.996642 28.7692 1.31322 28.9645 1.50849L31.7929 4.33691L28.9645 7.16534C28.7692 7.3606 28.7692 7.67719 28.9645 7.87245C29.1597 8.06771 29.4763 8.06771 29.6716 7.87245L32.8536 4.69047ZM0.5 4.83691H32.5V3.83691H0.5V4.83691Z"
                    fill="black"
                  />
                </svg>
              </Button>
            </div>
           
          </div>
          <div className="Right_Section" >
            {trending?.product_id?.map((item, index) => (
              <Link to={`/product/${item?._id}`} key={index} style={{ textDecoration: "none" }}>
                <div
                className="Right_Main"
                key={index}
                style={{
                  // border: "1px solid red",
                  lineHeight: "30px",
                }}                
                >
                  <div className="Right_Image">
                    <img src={api?.rootUrl1 + item?.images} alt="Banner" />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </TrendingOfMonthStyle>
  );
};

export default TrendingOfMonth1;

const TrendingOfMonthStyle = styled.div`
  .home_wrapper {
    padding: 0 40px;
  }

  .Trending_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }

  .Trending_Main .Left_Section {
    width: 30%;
  }

  .Trending_Main .Right_Section {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  .Trending_Main .Left_Section .Left_Title {
    color: #000;
    font-family: Moneta;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;
    text-align: left;
  }

  .Trending_Main .Left_Section .Left_Description {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    padding-bottom: 10px;
  }

  .Trending_Main .Left_Section .Left_Btn .Button {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    border: none;
    outline: none;
    text-align: left;
  }
`;
