const menuFunction = (menu) => {

  let filter = menu?.map((item) => {
    if (item?.children) {
      return {
        label: item?.name ,
        key: item?._id,
        children: item?.children?.length > 0 && menuFunction(item?.children),
      };
    }
    return {
      label: item?.name,                   
      key: item?._id,
    };
  })
  return filter;
};
export default menuFunction;
