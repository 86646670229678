export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
export const api_website = 'https://shop.dyuken.com/api/v1/website/';
export const api_admin = 'https://shop.dyuken.com/api/v1/admin/';
export const base_url = 'https://shop.dyuken.com/';
export const app_title = 'ecDigi Technologies'; 

// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'http://192.168.100.158:7000/api/v1/website/';
// export const api_admin = 'http://192.168.100.158:7000/api/v1/admin/';
// export const base_url = 'http://192.168.100.158:7000/';
// export const app_title = 'ecDigi Technologies';

