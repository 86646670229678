import React from 'react'
import {Card} from "antd"
import {Link} from 'react-router-dom'
import styled from 'styled-components';

import { styles } from '../ApiService/Data'

const SideBar = () => {
  return (
    <>
    <SideBarSection>
     <div className='Side_BarMain'>
     <ul >
        <li>
          <Link className="link" to="/my-profile" style={{textDecoration:'none'}}>
            My Profile
          </Link>
        </li>
        <li>
          <Link className="link" to="/delivery-address" style={{textDecoration:'none'}}>
            Delivery Address
          </Link>
        </li>
        <li>
          <Link className="link" to="/my-order" style={{textDecoration:'none'}}>
            My Order
          </Link>
        </li>
        <li>
          <Link className="link" to="/change-password" style={{textDecoration:'none'}}>
            Change Password
          </Link>
        </li>
      </ul>
     </div>
    </SideBarSection>
    <br />
    </>

  );
}

export default SideBar;

const SideBarSection = styled.section`
  min-width: 295px;
  border: 1px solid red;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  min-height: 300px;
  padding: 20px;
  line-height: 50px;
  ul {
    list-style: none !important;
    li {
      list-style: none !important;
      margin-bottom: 10px;
      color: black;
      .link {
        color: black;
        
      }
    }
  }

  @media screen and (max-width: 768px) {
    .Side_BarMain {
      min-width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

   .Side_BarMain ul {
    list-style: none !important;

    li {
      list-style: none !important;
      margin-bottom: 10px;
      color: black;
      .link {
        color: black;
        list-style: none !important;
      }
    }
  }
  }


`;
