import React from "react";
import styled from "styled-components";
import Logo from "../../Assets/Images/gowri-logo 1.png";
import { Link } from "react-router-dom"

import Icon1 from "../../Assets/Images/1.png";
import Icon2 from "../../Assets/Images/2.png";
import Icon3 from "../../Assets/Images/3.png";
import Icon4 from "../../Assets/Images/4.png";
import Icon5 from "../../Assets/Images/5.png";
import Icon6 from "../../Assets/Images/6.png";
import Icon7 from "../../Assets/Images/7.png";
import Icon8 from "../../Assets/Images/8.png";
import Icon9 from "../../Assets/Images/9.png";
import Icon10 from "../../Assets/Images/10.png";
import { Divider } from "antd";

const Footer8 = () => {
  return (
    <Section>
      <div className="main_container">
        <div className="footer_left">
          <img src={Logo} alt="gowri plates" />
          <p>
            Our plastic buffet plates are thoughtfully designed to blend
            seamlessly with various dining settings, adding a touch of elegance
            and sophistication to your table.
          </p>
        </div>

        <div className="quick_links_container">
          <h4>Quick Links</h4>
          <ul className="quick_links">
            <li><Link  className="link" to="/" style={{textDecoration: "none", color: "#898787"}}>Home</Link></li>
            <li><Link  className="link" to="/about" style={{textDecoration: "none", color: "#898787"}}>About Us</Link></li>
            <li><Link className="link" href="#faq" style={{textDecoration: "none", color: "#898787"}}>FAQ</Link></li>
            <li><Link className="link" to="/products" style={{textDecoration: "none", color: "#898787"}}>Products</Link></li>
            <li><Link to="/contact" style={{textDecoration: "none", color: "#898787"}}>Contact Us</Link></li>
          </ul>
        </div>
        <div className="other_links_container">
          <h4>Others</h4>
          <ul className="other_links">
            <li><Link className="link" to="/terms-and-condition" style={{textDecoration: "none", color: "#898787"}}>Terms and Conditions</Link></li>
            {/* <li><Link className="link"  style={{textDecoration: "none"}}>Privacy Policy</Link></li> */}
            <li><Link className="link"  style={{textDecoration: "none", color: "#898787"}} to="/delivery-policy">Delivery Policy</Link></li>
            <li><Link className="link" to="/return-policy" style={{textDecoration: "none", color: "#898787"}}>Return Policy</Link></li>
            <li><Link className="link" to="/refund-policy" style={{textDecoration: "none", color: "#898787"}}>Refund Policy</Link></li>
            <li><Link className="link" to="/cancellation-policy" style={{textDecoration: "none", color: "#898787"}}>Cancellation Policy</Link></li>
          </ul>
        </div>
        <div className="plates_container">
          <div>
            <p className="sunny_glow">Sunny Glow</p>
            <img src={Icon2} alt="icon1" />
          </div>
          <div>
            <p className="orange">Orange</p>
            <img src={Icon7} alt="icon1" />
          </div>
          <div>
            <p className="parakeet_green">Parakeet Green</p>
            <img src={Icon4} alt="icon1" />
          </div>
          <div>
            <p className="crimson_blaze">Crimson Blaze</p>
            <img src={Icon9} alt="icon1" />
          </div>
          <div>
            <p className="printsine_snow">Printsine Snow</p>
            <img src={Icon10} alt="icon1" />
          </div>
          <div>
            <p className="rose_blush">Rose Blush</p>
            <img src={Icon6} alt="icon1" />
          </div>
          <div>
            <p className="allure_black">Allure Black</p>
            <img src={Icon1} alt="icon1" />
          </div>
          <div>
            <p className="blue_bliss">Blue Bliss</p>
            <img src={Icon3} alt="icon1" />
          </div>
          <div>
            <p className="silver_skate">Silver Skate</p>
            <img src={Icon5} alt="icon1" />
          </div>
          <div>
            <p className="royal_plum">Royal plum</p>
            <img src={Icon8} alt="icon1" />
          </div>
        </div>
      </div>
      <Divider />
      <div className="container bottom_footer">
        <p>Gowri Buffet Plates © 2023</p>
        <p>Web Designed by Blazon</p>
      </div>
    </Section>
  );
};

export default Footer8;

const Section = styled.section`
  padding: 80px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .main_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    width: 1200px;

    .footer_left {
      color: #898787;
      font-family: Brevia;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 32px */
      img {
        padding-bottom: 25px;
      }
    }

    .quick_links_container {
      margin-top: 80px;
      h2 {
        color: #000;
        font-family: Brevia;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .quick_links {
        padding-left: 0;
        li {
          color: #898787;
          font-family: Brevia;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
    .other_links_container {
      margin-top: 80px;
      h2 {
        color: #000;
        font-family: Brevia;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .other_links {
        padding-left: 0;
        li {
          color: #898787;
          font-family: Brevia;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .plates_container {
      margin-top: 80px;
      /* width: 450px; */
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      /* flex-direction: column;
        align-items: center;
        justify-content: center; */

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 10px 0;

        img {
          width: 30px;
          margin-left: 10px;
        }

        p {
          font-family: Brevia;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin: 0;
          padding: 0;
        }

        .sunny_glow {
          color: #e3d31d;
        }
        .orange {
          color: #f28720;
        }
        .parakeet_green {
          color: #27b367;
        }
        .crimson_blaze {
          color: #e43932;
        }
        .printsine_snow {
          color: #e1e1e1;
        }
        .rose_blush {
          color: #d69d99;
        }
        .allure_black {
          color: #1f1b16;
        }
        .blue_bliss {
          color: #2b64b0;
        }
        .silver_skate {
          color: #b3d2d2;
        }
        .royal_plum {
          color: #d377b0;
        }
      }
    }
  }

  .bottom_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: #9f9c9c;
      font-family: Brevia;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  @media screen and (max-width: 1024px) {
    .main_container {
      width: 950px !important;
      gap: 20px !important;
      grid-template-columns: 1fr 0.6fr 0.7fr 2fr !important;
    }
  }
  @media screen and (max-width: 768px) {
    .main_container {
      width: 650px !important;
      gap: 20px !important;
      .footer_left{
        grid-column-start: 1 !important;
        grid-column-end: 4 !important;
      }
      .quick_links_container {
        grid-column-start: 4 !important;
        grid-column-end: 6 !important;
      }
      .other_links_container {
        grid-column-start: 6 !important;
        grid-column-end: 8 !important;
      }
      .plates_container {
        margin-top: 0 !important;
        grid-column-start: 1 !important;
        grid-column-end: 8 !important;

        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .main_container {
      width: 440px !important;
      gap: 20px !important;
      padding: 0 15px;
      
      .footer_left{
        grid-column-start: 1 !important;
        grid-column-end: 8 !important;
      }
      .quick_links_container {
        margin-top: 0 !important;
        grid-column-start: 1 !important;
        grid-column-end: 4 !important;

      }
      .other_links_container {
        margin-top: 0 !important;
        grid-column-start: 4 !important;
        grid-column-end: 8 !important;
      }
      .plates_container {
        margin-top: 0 !important;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .main_container {
      width: 340px !important;
      gap: 20px !important;
      padding: 0 15px;

    }
  }
`;
