import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Button, Modal, Input, Select, Form, message, Row, Col } from "antd";
import API from "../ApiService/ApiService";

import {
  updateAddressStart,
  updateAddressSuccess,
  updateAddressFail,
} from "../Redux/slices/updateAddress";
import {
  statesGetAllStart,
  statesGetAllSuccess,
  statesGetAllFail,
} from "../Redux/slices/states";
import {
  citiesGetAllStart,
  citiesGetAllSuccess,
  citiesGetAllFail,
} from "../Redux/slices/cities";

const { Option } = Select;

const api = new API();

const UpdateAddress = ({ getUserAddress, address, showModal, setShowModal }) => {
  // const [countries, setCountries] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState("India");
  // const [selectedState, setSelectedState] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const id = address?._id;

  const formRef = React.createRef();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const stateList = useSelector((state) => state?.states?.states?.states);
  const cityList = useSelector((state) => state?.cities?.cities);

  useEffect(() => {
    getCountry();
    getDistrict();
    getCity();
    getState();
  }, [selectedCountry, selectedState, selectedDistrict]);


  const getCountry = () => {
    api.getCountry().then((res) => {
      setCountries(res?.data?.data);
    });
  };
  // console.log("countries", countries);

  const getState = () => {
    api.getState(selectedCountry ? selectedCountry : "").then((res) => {
      setStates(res?.data?.data);
    });
  };
  // console.log("states", states);

  const getDistrict = () => {
    api.getdistrict(selectedState).then((res) => {
      setDistrict(res?.data?.data);
    });
  };
  // console.log("district", district);

  const getCity = () => {
    api.getArea(selectedDistrict).then((res) => {
      setCities(res?.data?.data);
    });
  };
  // console.log("cities", cities);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleDistrcitChange = (value) => {
    setSelectedDistrict(value);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  // console.log("address", address);

  useEffect(() => {
    if (address) {
      form.setFieldsValue({
          ...address,
          buyer_country: address?.buyer_country?._id,
          buyer_state: address?.buyer_state?._id,
          buyer_district: address?.buyer_district?._id,
          buyer_city: address?.buyer_city?._id,
      });
      setSelectedCountry(address?.buyer_country?._id)
      setSelectedState(address?.buyer_state?._id)
      setSelectedDistrict(address?.buyer_district?._id)

  }
  }, [address]);


  const handleUpdate = async (values) => {
    setShowModal(false);

    const res = await api.update(
      dispatch,
      [updateAddressStart, updateAddressSuccess, updateAddressFail],
      "buyerbillingdetails",
      id,
      values
    );
    if (res?.data?.success) {
      message.success(`update successfully`);
    } else {
      message.warning("Check your Data");
    }
    getUserAddress();
  };

  const customFormItemStyle = {
    marginBottom: "8px", // Adjust the margin as per your requirement
  };

  return (
    <ModalWrapper>
      <Modal
        title="Update Address"
        centered
        open={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
        width={900}
        footer={[
          <Button
            type="primary"
            form="myForm"
            key="submit"
            htmlType="submit"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="myForm"
          name="basic"
          form={form}
          onFinish={handleUpdate}
          autoComplete="off"
          ref={formRef}
          layout="vertical"
          className="custom-form"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Mobile Number"
                name="buyer_delivery_contact_number"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: "Phone number must be 10 digits.",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Alternate Mobile Number"
                name="buyer_delivery_alternate_number"
                style={customFormItemStyle}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Address 1"
                name="buyer_address_line_1"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Address 2"
                name="buyer_address_line_2"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Pincode"
                name="buyer_pincode"
                style={customFormItemStyle}
                rules={[
                  {
                    pattern: /^\d{6}$/,
                    message: "Pincode must be a 6-digit number.",
                  },
                  {
                    required: true,
                    message: "Please input your pincode",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Landmark"
                name="landmark"
                style={customFormItemStyle}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="buyer_country"
                style={customFormItemStyle}
              >
                <Select
                  // value={selectedCountry}
                  onChange={handleCountryChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countries?.map((country) => (
                    // console.log("country--->", country),
                    <Option key={country?._id} value={country?._id}>
                      {country?.country}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="State"
                name="buyer_state"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please select state",
                  },
                ]}
              >
                <Select 
                showSearch 
                allowClear 
                onChange={handleStateChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                  {states?.map((state) => (
                    <Option key={state?._id} value={state?._id}>
                      {state?.state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="District"
                name="buyer_district"
                rules={[
                  {
                    required: true,
                    message: "Please select city",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  onChange={handleDistrcitChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {district?.map((district) => (
                    // console.log("district--->", district),
                    <Option key={district?._id} value={district?._id}>
                      {district?.district}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="buyer_city"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please select city",
                  },
                ]}
              >
                <Select 
                showSearch 
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                  {cities?.map((city) => (
                    <Option key={city?._id} value={city?._id}>
                      {city?.area}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </ModalWrapper>
  );
};

export default UpdateAddress;

const ModalWrapper = styled.div`
  .custom-form .ant-form-item {
    margin-bottom: 0px;
  }
`;
