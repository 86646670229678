import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
  name: "user",
  initialState: {
    user: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    registerGetAllStart: (state) => {
      state.isFetching = true;
    },
    registerGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
    },
    registerGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    userGetAllStart: (state) => {
      state.isFetching = true;
    },
    userGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
    },
    userGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    logoutUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
  logoutUser,
  registerGetAllStart,
  registerGetAllSuccess,
  registerGetAllFail,
} = user?.actions;
export default user.reducer;
