import React from "react";
import Instagram1 from "../Instagram/Instagram1";

const InstagramSectionMain = ({Home_product, banner, followusoninstagram}) => {
  switch (followusoninstagram) {
    case "instagram":
      return <Instagram1 followusoninstagram={followusoninstagram} />;

    default:
      return <Instagram1 followusoninstagram={followusoninstagram} />;
  }
};

export default InstagramSectionMain;
