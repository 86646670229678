import { createSlice } from "@reduxjs/toolkit";

const product = createSlice({
  name: "product",
  initialState: {
    product: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    productGetAllStart: (state) => {
      state.isFetching = true;
    },
    productGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.product = action.payload;
    },
    productGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} = product?.actions;
export default product.reducer;
