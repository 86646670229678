import React from 'react'
import FAQ1 from '../FAQ/FAQ1';

const FAQMain = ({faq_type, homecustom}) => {
    switch (faq_type) {
        case "faq-1":
          return <FAQ1 homecustom={homecustom} />;
        default:
          return <FAQ1 homecustom={homecustom} />;
      }
}

export default FAQMain