import React from "react";
import GiftStoriesSection from "../GiftStories/GiftStoriesSection";
const GiftStoriesSectionMain = ({category ,gift_stories_sec, company, socialmedia}) => {
  switch (gift_stories_sec) {
    case "GSC1":
      return <GiftStoriesSection company={company} socialmedia={socialmedia} category={category} />;

    default:
      return <GiftStoriesSection company={company} socialmedia={socialmedia} category={category} />;
  }
};

export default GiftStoriesSectionMain;
