import React from "react";
import image1 from "../../Assets/Images/GirlsHub Template/banner-1.png";
import image2 from "../../Assets/Images/GirlsHub Template/banner-2.png";
import styled from "styled-components";
import API from "../../ApiService/ApiService";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";

const HCGirlsHub = (banner) => {
  const api = new API();
  // console.log("banner", banner?.banner?.banner_left?.button_link);
  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
            <div className="Left_Section">
          <Link to={banner?.banner?.banner_left?.button_link}>
              <img src={api.rootUrl1 + banner?.banner?.banner_left?.image || null} alt="Banner 1" />
          </Link>
            </div>
            <div className="Right_Section">
          <Link to={banner?.banner?.banner_right?.button_link}>
              <img src={api.rootUrl1 + banner?.banner?.banner_right?.image || null} alt="Banner 2" />
          </Link>
            </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default HCGirlsHub;

const BannerAlign = styled.div`
  .home_wrapper {
    display: flex;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .HCFurniture_Main .Left_Section,
  .HCFurniture_Main .Right_Section {
    width: 50%;
  }
`;
