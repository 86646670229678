import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Button,
  Table,
  Tag,
  Popconfirm,
  Modal,
  Form,
  Image, Typography,
  message,
  Row,
  Col
} from 'antd';
import {
  PlusCircleOutlined, ArrowLeftOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import crud_service from '../../../ApiService/CrudService';
import PageUnderConstruction from '../PageUnderConstruction';

const PrivacyPolicy = () => {
  const [getpage, setGetpage] = useState([]);

  //api service 
  const crud = new crud_service();
  const navigate = useNavigate();
  const PrivacyPolicy = useSelector((state) => state?.privacypolicy?.privacypolicy);
  const htmlContent = PrivacyPolicy?.data[0]?.description
  // console.log("PrivacyPolicy", PrivacyPolicy?.data);
  return (
    <MainSection>
      {PrivacyPolicy?.data ?
        <div className='about_container'>
          <Row className='about_detail'>
            <Col xs={24} lg={24} sm={24} xxl={24} className='our_mission'>
              <h4 style={{ textAlign: "center" }}>{PrivacyPolicy?.data[0]?.title}</h4>
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </Col>
          </Row>
        </div> : <PageUnderConstruction />}
    </MainSection>
  )
}

export default PrivacyPolicy;

const MainSection = styled.section`
width: 100%;
  .about_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .about_detail{
      max-width: 1200px;
      line-height: 30px;
      color: black;
    }

    .our_mission{
      width: 100%;
      margin: 50px 0px;
      /* border: 1px solid red; */
    }
  }
`;