import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const TC3 = ({ category }) => {
  return (
    <>
    <TopCategory>
        <section className="CA_4_Category">
          <div className="wrapper_full" style={{ padding: "0 20px" }}>
          <H2>Top Categories</H2>
            <div className="CA_4_Align">
              {category?.slice(0, 5).map((e, i) => {
                return (
                  <div key={`cat_des_${i}`} className="CA_4_Items">
                    <div>
                      <h4>{e?.name}</h4>
                      <Link
                        to={`/${e?.name
                          .toLowerCase()
                          .replace(/[-\s]+/g, "-")
                          .replace(/[-\s]+/g, "-")}`}
                        className="CA_4_Link"
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </TopCategory>
    </>
  )
}

export default TC3;

const H2 = styled.h1`
  font-size: ${styles?.h2};
  font-family: ${styles?.font} !important;
  color: ${colorCustom?.color ? colorCustom?.color : styles?.color} !important;
  line-height: 1.4;
  margin: 0 0 25px !important;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;
const TopCategory = styled.div`
  section.CA_4_Category {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  section.CA_4_Category .CA_4_Align {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
  }

  section.CA_4_Category * {
    display: inline-block;
    width: 100%;
    position: relative;
  }

  section.CA_4_Category .CA_4_Align .CA_4_Items {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    position: relative;
    min-height: 250px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items:nth-child(1) {
    background: ${styles?.color1};
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items:nth-child(2) {
    background: ${styles?.color2};
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items:nth-child(3) {
    background: ${styles?.color3};
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items:nth-child(4) {
    background: ${styles?.color4};
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items:nth-child(5) {
    background: ${styles?.color5};
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items div {
    display: inline-block;
    height: fit-content;
    width: 100%;
    text-align: center;
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items div h4 {
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 20px;
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items div .CA_4_Link {
    color: ${styles?.color};
    text-transform: uppercase;
    position: relative;
    padding: 0 0 6px;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 14px;
    width: fit-content;
    text-decoration: none;
  }
  section.CA_4_Category .CA_4_Align .CA_4_Items div .CA_4_Link::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${styles?.color};
    display: inline-block;
    left: 0;
    bottom: 0;
  }

  @media screen and (max-width: 992px) {
    section.CA_4_Category .CA_4_Align {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 768px) {
    section.CA_4_Category .CA_4_Align {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
