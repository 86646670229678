import React from "react";
import BestSeller1 from "../BestSeller/BestSeller1";
import BestSeller2 from "../BestSeller/BestSeller2";

const BestSellerMain = ({ best_seller, best_selling, homecustom }) => {
  switch (best_seller) {
    case "BestSeller1":
      return <BestSeller1 best_selling={best_selling} />;
    case "BestSeller2":
      return <BestSeller2 homecustom={homecustom} />;
   
    default:
      return <BestSeller2 homecustom={homecustom} />;
  }
};

export default BestSellerMain;
