import { createSlice } from "@reduxjs/toolkit";

const CetgoryMenu = createSlice({
  name: "cetgorymenu",
  initialState: {
    CetgoryMenu: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    CetgoryMenuGetAllStart: (state) => {
      state.isFetching = true;
    },
    CetgoryMenuGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.CetgoryMenu = action.payload;
    },
    CetgoryMenuGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  CetgoryMenuGetAllStart,
  CetgoryMenuGetAllSuccess,
  CetgoryMenuGetAllFail,
} = CetgoryMenu?.actions;
export default CetgoryMenu.reducer;
