import { Button } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { Link } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/default.png";
import { base_url } from "../../config";

const Hc23 = ({ props }) => {
  return (
    <>
      <Section>
        <Wrapper>
          <HeadText>
            <H2>{props?.title}</H2>
            <Button>
              View All
              <RightCircleOutlined />
            </Button>
          </HeadText>
          <Align>
            {props?.content?.map((data) => {
              return (
                <Boxs key={data?._id}>
                  <BG>
                    <Img src={data?.image ? base_url + data?.image : Default} />
                  </BG>
                  <Content>
                    <SubTitle>{data?.sub_title}</SubTitle>
                    <Title>{data?.title}</Title>
                    <Description>
                      {data?.description && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data?.description,
                          }}
                        ></p>
                      )}
                    </Description>
                    <Link to={data?.link}>
                      <Button>{data?.link_text}</Button>
                    </Link>
                  </Content>
                </Boxs>
              );
            })}
          </Align>
        </Wrapper>
      </Section>
    </>
  );
};

export default Hc23;

const Section = styled.section`
  width: 100%;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
`;
const Wrapper = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: auto;
  box-sizing: border-box;
`;
const HeadText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const H2 = styled.h2`
  color: ${styles.color};
  font-size: ${styles.h2};
`;
const Align = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  position: relative;
  margin: 25px 0 0 0;
`;
const Boxs = styled.div`
  width: 100%;
  display: inline-block;
`;
const Img = styled.img`
  max-width: 100%;
`;
const BG = styled.div`
  border: 1px solid ${styles.light};
  border-bottom: 0;
`;
const Content = styled.div`
  border: 1px solid ${styles.light};
  border-top: 0;
  padding: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    width: fit-content;
  }
`;
const SubTitle = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const Title = styled.div`
  font-size: 30px;
  color: ${styles.color};
  font-weight: 600;
`;
const Description = styled.div`
  line-height: 1.7;
  font-size: 15px;
`;
