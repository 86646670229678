import React from 'react'
import { Button, Result } from 'antd';
import {Link} from 'react-router-dom';
const ResultPage = () => {
  return (
    <>
    <Result
    status="success"
    title="Successfully Purchased"
    subTitle="Order number: 2017182818828182881"
    extra={[
        <Link to='/my-order'>
        <Button type="primary" key="console">
        My Orders
      </Button>
        </Link>
      ,
      <Link to={'/products'}>
      <Button key="buy">Buy Again</Button>
      </Link>
    ]}
  />
    </>
  )
}

export default ResultPage;