import { createSlice } from "@reduxjs/toolkit";

const homeCustomSlice = createSlice({
  name:'homecustom',
  initialState:{
    homecustom:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    homeCustomGetAllStart:(state)=>{
      state.isFetching = true;
    },
    homeCustomGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.homecustom = action.payload;
    },
    homeCustomGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {homeCustomGetAllStart,homeCustomGetAllSuccess,homeCustomGetAllFail} = homeCustomSlice?.actions;
export default homeCustomSlice.reducer;



