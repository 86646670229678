import React from "react";
import { styled } from "styled-components";
import { Row, Col, Tooltip, theme } from "antd";
import { base_url } from "../../config";
import { Link } from "react-router-dom";
import { styles } from "../../ApiService/Data";
import {
  BsFillGeoAltFill,
  BsFillEnvelopeFill,
  BsFillTelephoneFill,
} from "react-icons/bs";
import address from "../../Assets/Images/Agri/location.png";
import call from "../../Assets/Images/Agri/call.png";
import mail from "../../Assets/Images/Agri/mail.png";
import logo from "../../Assets/Images/logo.png";

const Footer6 = ({ company, socialmedia }) => {
  const FooterLinks = [
    { path: "/", title: "Home" },
    { path: "/about", title: "About Us" },
    { path: "/contact", title: "Contact Us" },
  ];

  const OtherLinks = [
    { path: "/privacy-policy", title: "Privacy Policy" },
    { path: "/terms", title: "Terms and Conditions" },
    { path: "/refund-policy", title: "Refund Policy" },
    { path: "/delivery-policy", title: "Delivery Policy" },
    { path: "/return-policy", title: "Return Policy" },
    { path: "/cancellation-policy", title: "Cancellation Policy" },
  ];

  const year = new Date().getFullYear();

  return (
    <FootWrapper>
      <div className="warpper">
        <Row gutter={[16, 16]} justify="center" className="row">
          <Col xl={6} lg={6} md={12} sm={12} xs={24}>
            <Link to="/">
              <img style={{ height: "60px" }} src={company?.logo ? base_url + company?.logo : logo} alt="Logo" />
            </Link>
            <div className="socialLinksContainer">
              <h3>SOCIAL LINKS</h3>
              <ul className="socialLinks">
                {socialmedia?.map((e, index) => (
                  <li key={index}>
                    <a
                      href={e?.social_media_link}
                      target="_blank"
                      title={e?.social_media_name}
                    >
                      <img
                        src={base_url + e?.social_media_icon}
                        alt={e?.social_media_name}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={24} className="quickLinks">
            {" "}
            <h2>QUICK LINKS</h2>
            <ul>
              {FooterLinks?.map((link) => (
                <Link className="link" key={link?.title} to={link?.path}>
                  <li title={link?.title}>{link?.title}</li>
                </Link>
              ))}
            </ul>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={24} className="usefulLinks">
            <h2>USEFUL LINKS</h2>
            <ul>
              {OtherLinks?.map((link) => (
                <Link className="link" key={link?.title} to={link?.path}>
                  <li title={link?.title} key={link?.title}>
                    {link?.title}
                  </li>
                </Link>
              ))}
            </ul>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={24}>
            <h2>CONTACT US</h2>
            <ul className="contactsList">
              <li className="address">
                <BsFillGeoAltFill style={{ fontSize: "45px" }} />
                <p>
                  {company?.company_addresss}, {company?.city},<br />
                  Pincode -{company?.pin_code},{company?.state}
                </p>
              </li>
              <li className="phone">
                <BsFillTelephoneFill />
                <a
                  href={"tel:" + company?.contact_number}
                  style={{ textDecorationColor: "white", color: "white" }}
                  title="Call Now"
                >
                  {company?.contact_number}
                </a>
                ,{" "}
                <a
                  href={"tel:+91" + company?.mobile_number}
                  style={{ textDecorationColor: "white", color: "white" }}
                  title="Call Now"
                >
                  {company?.mobile_number}
                </a>
              </li>
              <li className="email">
                <BsFillEnvelopeFill style={{ fontSize: "20px" }} />
                <a
                  href={"mailto:" + company?.email_id}
                  style={{ textDecorationColor: "white", color: "white" }}
                  title="Mail Us"
                >
                  {company?.email_id}
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="copyrights">
          <p className="copyrightStatement">
            © {year} copyrights by{" "}
            <a
              href="https://ecdigi.com/"
              target="_blank"
              title="ecDigi Technologies"
            >
              ecDigi Technologies.
            </a>
          </p>
          <p>
            Design by{" "}
            <a
              href="https://www.blazon.in/"
              target="_blank"
              title="Blazon Technology"
            >
              Blazon
            </a>
          </p>
        </div>
        <br />
      </div>
    </FootWrapper>
  );
};

export default Footer6;

const FootWrapper = styled.section`
  min-height: 40vh;
  width: 100%;
  background-color: #222222;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  color: white;

  .warpper {
    /* border: 1px solid red; */
    max-width: 1200px;
    padding: 0 20px;
    width: 100%;
  }
  .logo {
    filter: brightness(0) invert(1);
  }

  .row {
    width: 100%;

    .quickLinks {
      ul {
        width: 35%;

        li {
          width: 100%;
        }
      }
    }
  }
  h2,
  h3 {
    color: white;
    font-family: ${styles?.r_regular} !important;
  }
  ul {
    padding-left: 0;
  }
  li {
    color: white;
    margin: 10px 0;
    letter-spacing: 1px;
    font-size: 16px;
  }
  .link {
    text-decoration: none;
  }
  .contactsList {
    li:nth-child(2) {
      margin: 22px 0;
    }
  }
  .socialLinksContainer {
    margin-top: 25px;
  }
  .socialLinks {
    display: flex;
    li:nth-child(1) {
      margin-right: 25px;
    }
    img {
      height: 40px;
      width: 40px;
    }
  }

  .address {
    display: flex;
    p {
      margin-left: 15px;
    }
  }

  .email {
    a {
      margin-left: 15px;
    }
  }
  .phone {
    a {
      margin-left: 15px;
    }
  }

  .copyrights {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      a {
        text-decoration: none;
        color: ${styles.white};
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      a:hover {
      }
    }
  }

  @media screen and (max-width:580px) {
    .copyrights {
      flex-direction: column;
      align-items: start;
  }
  }
`;
