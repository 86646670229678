import React from "react";
import HomeContact1 from "../HomeContacts/HomeContact1";


const HomeContactMain = ({ home_contact, touchwith_us }) => {
  switch (home_contact) {
    case "home_contact_1":
      return <HomeContact1 touchwith_us={touchwith_us} />;
    default:
      return <HomeContact1 touchwith_us={touchwith_us} />;
  }
};

export default HomeContactMain;
