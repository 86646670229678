import { createSlice } from "@reduxjs/toolkit";

const district = createSlice({
  name: "district",
  initialState: {
    cities: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    districtGetAllStart: (state) => {
      state.isFetching = true;
    },
    districtGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.cities = action.payload;
    },
    districtGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { districtGetAllStart, districtGetAllSuccess, districtGetAllFail } =
district?.actions;
export default district.reducer;
