import React from "react";
import styled from "styled-components";
import API from "../../../ApiService/ApiService";
import { Link } from "react-router-dom";

const BannerFurniture = ({homecustom}) => {
  const api = new API();

  // console.log("BannerFurniture---->", homecustom);
  return (
    <div className="home_wrapper">
      <div className="BannerSingle">
        <Link to={homecustom?.button_link}>
          <img src={api.rootUrl1 + homecustom?.image} />
        </Link>
      </div>
    </div>
  );
};

export default BannerFurniture;
