import React from 'react'
import styled from 'styled-components';
import {font,theme} from '../../ApiService/Theme'
import {Link} from 'react-router-dom'
import f_call from '../../Assets/Images/icons/f_phone.png'
import fmail from '../../Assets/Images/icons/f_email.png'
import faddress from '../../Assets/Images/icons/f_location.png'
import { base_url } from '../../config';
const Footer1 = ({ company,socialmedia }) => {
  const year = new Date().getFullYear();
  const quick_links = [
    {
      path: "", 
      title: "Privacy Policy"
    },
    {
      path: "", 
      title: "Terms and Conditions"
    },
    {
      path: "", 
      title: "Refund Policy"
    },
    {
      path: "", 
      title: "Delivery Policy"
    },
    {
      path: "", 
      title: "Return Policy"
    }
    ,
    {
      path: "", 
      title: "Cancellation Policy"
    }
  ];
  return (
    <>
      <FooterSection>
        <div className='footer_section'>
          <div className='wrapper'>
            <H2>Get in Touch</H2>
            <div className='contact_box_section'>
              <ul className='c_box'>
                <li>
                  <span>
                    <img src={f_call} alt="Call" />
                  </span>
                  <p><a href={"tel:" + company?.contact_number}>{company?.contact_number}</a>,  <a href={"tel:+91" + company?.mobile_number}>
                    {company?.mobile_number}
                  </a></p>
                </li>
                <li>
                  <span>
                    <img src={fmail} alt="Mail" />
                  </span>
                  <p><a href={"mailto:" + company?.email_id}>

                    {company?.email_id}
                  </a></p>
                </li>
                <li>
                  <span>
                    <img src={faddress} alt="Address" />
                  </span>
                  <p>{company?.company_addresss},{" "}
                    {company?.city},{" "}{company?.state} - 
                    {company?.pin_code}
                    ,
                    {company?.address?.state?.name}</p>
                </li>
              </ul>
              <div className='logo_row'>
                <img src={base_url+company?.logo} alt="logo" />
              </div>
              <div className='social_media'>
                <ul>
                  {
                    socialmedia?.map((e,i) => {
                      return (
                        <li key={i}>
                          <a href={e?.social_media_link} title={e?.social_media_name} target="_blank">
                            <img src={base_url + e?.social_media_icon} alt={e?.social_media_name} /></a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className='quicklinks_row'>
                <ul>
                  {quick_links?.map((e, i) => (
                    <li key={i}>
                      <Link to={e.path}>{e.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className='copy_text'>
            <div className='wrapper'>
              <div className='copy_align'>
                <div className='copy_left'>
                  <p>All Rights Reserved. {company?.company_name}</p>
                </div>
                <div className='copy_right'>
                  <p>© {year} Designed by <a href="https://ecdigi.com/" target="_blank" title='ecDigi Technologies'>ecDigi Technologies.</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterSection>
    </>
  )
}

export default Footer1;


const H2 = styled.h2`
   font-size:30px;
   margin : 0 0 35px;
   text-transform: uppercase;
   font-family: ${font?.regular} !important;
   letter-spacing: 0.7px;

   @media screen and (max-width:768px) {
    text-align: center;
   }

`

const FooterSection = styled.section`
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 40px 0 0 0;
    background: ${theme?.light_gray};

    .footer_section {
        width: 100%;
        display: inline-block;
        position: relative;
    }
    .footer_section h2 {
        text-align: center;
    }

    .footer_section ul.c_box {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 30px;
    }
    .footer_section ul.c_box li {
        background: #fff;
    padding: 25px 24px;
    border-radius: 10px;
    box-shadow: 0 0 12px rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    p {
        margin: 0;
    }
    }
    .footer_section ul.c_box li span {
   display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 65px;
    border-radius: 100%;
    border: 1px solid #000;
    padding: 17px;
}

.logo_row {
    width: 100%;
    display: inline-block;
    margin: 100px 0 0 0;

    img {
        height: 75px;
        margin: auto;
    }
}
.social_media {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0 0;
}
.social_media ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
}
.social_media ul img {
    height: 35px;
}
.quicklinks_row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 25px 0 0 0;
}
.quicklinks_row ul {
    display: flex;
    align-items: center;
    gap: 20px 15px;
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
}
a {
    color: ${theme?.color};
}

.copy_text {
        width: 100%;
        display: inline-block;
        padding: 10px 0;
        border-top: 1px solid #c7c7c7;
        margin: 50px 0 0 0;
        p {
            line-height: 1.5;
            color: ${theme?.color};
            margin: 0;
        }
    }
    .copy_text .copy_align {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    .copy_text .copy_align p {
        font-size: 14px;
    }


@media screen and (max-width: 768px) {
    .footer_section ul.c_box {
        grid-template-columns: repeat(1,1fr);
    }
    .copy_text .copy_align {
        flex-direction: column;
    gap: 10px;
    text-align: center;
    }
}



`