import React from 'react'
import styled from 'styled-components'
import { font, theme } from '../../ApiService/Theme'
import { Link } from "react-router-dom";
import { base_url } from '../../config'
import default_image from '../../Assets/Images/default.png';
const Hc52 = ({ props }) => {
  return (
    <Hc3Section>
      <div className='h3_section'>
        <div className='wrapper'>
          <H2>{props?.section_value ? props?.section_value : ""}</H2>
          <div className='hc3_align'>
          <div className='hc3_2'>
              <ul>
              {
                  props?.content?.slice(2, 3).map((e, i) => {
                    return (
                      <li key={i}>
                      <div className='hc3_box'>
                        <img src={e?.image ? base_url + e?.image : default_image} alt={e?.title ? e?.title : ""} />
                        <div className='hc3_content'>
                          <h4>{e?.title ? e?.title : ""}</h4>
                          <Link to={e?.link ? e?.link : void 0}><button>{e?.link_text ? e?.link_text : "Shop Now"}</button></Link>
                        </div>
                      </div>
                    </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className='hc3_1'>
              <ul>
                {
                  props?.content?.slice(0, 2).map((e, i) => {
                    return (
                      <li key={i}>
                      <div className='hc3_box'>
                        <img src={e?.image ? base_url + e?.image : default_image} alt={e?.title ? e?.title : ""} />
                        <div className='hc3_content'>
                          <h4>{e?.title ? e?.title : ""}</h4>
                          <Link to={e?.link ? e?.link : void 0}><button>{e?.link_text ? e?.link_text : "Shop Now"}</button></Link>
                        </div>
                      </div>
                    </li>
                    )
                  })
                }

              </ul>
            </div>
           
            <div className='hc3_3'>
              <ul>
              {
                  props?.content?.slice(3, 5).map((e, i) => {
                    return (
                      <li key={i}>
                      <div className='hc3_box'>
                        <img src={e?.image ? base_url + e?.image : default_image} alt={e?.title ? e?.title : ""} />
                        <div className='hc3_content'>
                          <h4>{e?.title ? e?.title : ""}</h4>
                          <Link to={e?.link ? e?.link : void 0}><button>{e?.link_text ? e?.link_text : "Shop Now"}</button></Link>
                        </div>
                      </div>
                    </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Hc3Section>
  )
}

export default Hc52
const H2 = styled.h2`
   font-size:30px;
   margin : 0 0 35px;
   text-transform: uppercase;
   font-family: ${font?.regular} !important;
   letter-spacing: 0.7px;

   @media screen and (max-width:768px) {
    text-align: center;
   }
`
const Hc3Section = styled.section`
   display:inline-block;
   width:100%;
   position: relative;

   .h3_section {
    display: inline-block;
    width: 100%;
    position: relative;
   }
   .h3_section .hc3_align {
    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 25px;
   }
   .h3_section .hc3_align ul {
    list-style: none;
    padding: 0;
    margin: 0;
   }
   .h3_section .hc3_align .hc3_1, .h3_section .hc3_align .hc3_3 {
    flex:1;
   }
   .h3_section .hc3_align .hc3_2 {
    flex: 2;
   
   }
   .h3_section .hc3_align .hc3_1 ul, .h3_section .hc3_align .hc3_3 ul {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 25px;
   }
   .h3_section .hc3_align .hc3_1 ul .hc3_box, .h3_section .hc3_align .hc3_3 ul li .hc3_box, .h3_section .hc3_align .hc3_2 ul .hc3_box {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
   }
   .h3_section .hc3_align .hc3_2 ul, .h3_section .hc3_align .hc3_2 ul li, .h3_section .hc3_align .hc3_2 ul li .hc3_box,.h3_section .hc3_align .hc3_2 ul li .hc3_box img  {
    height: 100%;
   }
   .h3_section .hc3_align .hc3_2 ul li .hc3_box img {
    object-fit: cover;
   }
   .h3_section .hc3_align img {
    padding: 0 0 0px;
    width: 100%;
   }
   .h3_section ul li {
    position: relative;
   }
   .h3_section ul li .hc3_box .hc3_content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        text-align: left;
    }
    .h3_section ul li .hc3_box .hc3_content h4 {
        font-size: 23px;
    font-family: ${font?.regular} !important;
    text-transform: uppercase;
    color: ${theme?.while};
    font-weight: 600;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    margin: 0 0 5px;
    width: 100%;
    }
    .h3_section ul li .hc3_box .hc3_content button {
        width: fit-content;
    margin: 0px 0 0 0;
    border: 0;
    border-radius: 8px;
    padding: 6px 15px;
   display: flex;
    text-align: center;
    background: ${theme?.bg};
    color: ${theme?.while};
    font-family: ${font?.regular} !important;
    }


    @media screen and (max-width:768px) {
        .h3_section .hc3_align {
            flex-direction: column;
        }

        .h3_section ul li .hc3_box .hc3_content {
            text-align: center;
        }

        .h3_section ul li .hc3_box .hc3_content button { 
            margin: auto;
        }

    }











`