import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { Link } from "react-router-dom";
import Default from "../../Assets/Images/default.png";
import API from "../../ApiService/ApiService";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { base_url } from "../../config";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user)?.setColor;

const FP1 = ({ featuredproduct_type }) => {

  // console.log('feature product', featuredproduct_type);

  const items = [
    {
      label: "Featured",
      key: "1",
      children: (
        <div className="Temp1_FP_Box_Align">
          {featuredproduct_type?.map?.((e, i) => {
            let img;
            if (e?.images?.length > 0) img = e?.images;

            let link = "";
            let n = e?.category?.length;
            if (n > 0) {
              link = `${e?.category[0]?.category_name
                ?.toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")}/${e?.product_description
                ?.toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")}?pid=${e?._id}`;
            }

            return (
              <div className="Temp1_FP_Box" key={`fp_t1${i}`}>
                <div className="Temp1_FP_Img">
                  <Link to={link}>
                    <img
                      src={
                        e?.prospecdocs[0]?.product_images[0]?.image_path
                          ? base_url +  e?.prospecdocs[0]?.product_images[0]?.image_path
                          : Default
                      }
                    />
                  </Link>
                </div>
                <h4>{e?.product_description}</h4>
                <p>{e?.category[0].category_name}</p>
                <span className="Temp1_FP_Price">₹{e?.prospecdocs[0]?.sp}</span>
              </div>
            );
          })}
        </div>
      ),
    },
  ];



  return (
    <>
    <FPSection>
      {featuredproduct_type?.length > 1 ? (
        <section className="Temp1_FP_Section">
          <div className="Wrapper_Full">
            <div className="Temp1_FP_Align">
              <Tabs items={items} />
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      </FPSection>
    </>
  )
}

export default FP1;

const FPSection = styled.div`


.Temp1_FP_Section {
  width: 100%;
  display: inline-block;
  position: relative;
}
.Temp1_FP_Section .Temp1_FP_Align {
  width: 100%;
  display: inline-block;
  position: relative;
}
.Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align {
  display: inline-block;
  width: 100%;
  position: relative;
}
.Temp1_FP_Section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.Temp1_FP_Section .ant-tabs-tab-btn {
  font-size: ${styles?.h2};
  font-family: ${styles?.bold} !important;
  color: ${colorCustom?.color ? colorCustom?.color : styles?.color} !important;
  line-height: 1.4;
}
.Temp1_FP_Section .ant-tabs-tab-btn {
  color: ${styles?.gray};
}
.Temp1_FP_Section .ant-tabs-tab-btn:focus,
.Temp1_FP_Section .ant-tabs-tab-remove:focus,
.Temp1_FP_Section .ant-tabs-tab-btn:active,
.Temp1_FP_Section .ant-tabs-tab-remove:active {
  color: ${colorCustom?.color} !important;
}
.Temp1_FP_Section .ant-tabs-ink-bar {
  background: ${colorCustom?.color};
}
.Temp1_FP_Section .ant-tabs-nav {
  margin: 0 0 35px !important;
}
.Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 20px;
}
.Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align .Temp1_FP_Box {
  padding: 15px;
  border: 1px solid ${styles?.border};
  border-radius: 5px;
  width: 100%;
  display: grid;
}
.Temp1_FP_Section
  .Temp1_FP_Align
  .Temp1_FP_Box_Align
  .Temp1_FP_Box
  .Temp1_FP_Img {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin: 0 0 25px;
}
.Temp1_FP_Section
  .Temp1_FP_Align
  .Temp1_FP_Box_Align
  .Temp1_FP_Box
  .Temp1_FP_Img
  a {
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.Temp1_FP_Section
  .Temp1_FP_Align
  .Temp1_FP_Box_Align
  .Temp1_FP_Box
  .Temp1_FP_Img
  img {
  transition: all 0.6s ease-in-out;
  border-radius: 5px;
  margin: auto;
  display: block;
}
.Temp1_FP_Section
  .Temp1_FP_Align
  .Temp1_FP_Box_Align
  .Temp1_FP_Box:hover
  .Temp1_FP_Img
  img {
  transition: all 0.6s ease-in-out;
  transform: scale(1.2);
  margin: auto;
}
.Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align .Temp1_FP_Box h4 {
  font-size: ${styles?.h5};
  text-align: center;
  font-family: ${styles?.medium};
}
.Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align .Temp1_FP_Box p {
  color: ${styles?.gray};
  font-size: ${styles?.p};
  font-family: ${styles?.regular};
  text-align: center;
}
.Temp1_FP_Section
  .Temp1_FP_Align
  .Temp1_FP_Box_Align
  .Temp1_FP_Box
  span.Temp1_FP_Price {
  font-size: ${styles?.h5};
  text-align: center;
  font-family: ${styles?.medium};
  display: flex;
  width: fit-content;
  margin: auto;
}


@media screen and (max-width:992px) {
  .Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:480px) {
  
  .Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align .Temp1_FP_Box h4 {
        font-size: ${styles?.p};
    }
    .Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align {
        gap: 30px 15px;
    }
    .Temp1_FP_Section .Temp1_FP_Align .Temp1_FP_Box_Align .Temp1_FP_Box {
        padding: 10px;
    }

}

`;

