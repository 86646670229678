import React, {useEffect, useState} from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import CheckoutAddress from "../Components/Checkout/CheckoutAddress";
import CheckoutStepper from "../Components/Checkout/CheckoutStepper";

const PriceList = ({setTotalAmount, totalAmount}) => {
  // const [totalAmount, setTotalAmount] = useState(0);
  const [cart, setCart] = useState([]);
  const api = new API();

  // useEffect(() => {
  //   calculateTotalAmount();
  // }, [cart]);

  useEffect(() => {
    getAllCart();
  }, []);

  const getAllCart = () => {
    api.getcart().then((res) => {
      // console.log("price---->", res?.data?.data);
      setCart(res?.data?.data);
    });
  };

  // const calculateTotalAmount = () => {
  //   let totalPrice = 0;

  //   for (const item of cart) {
  //     const itemPrice = item?.title?.quantity * item?.title?.special_price;
  //     totalPrice += itemPrice;
  //   }

  //   setTotalAmount(totalPrice);
  // };

  return (
    <PriceListSection>
      <div className="cart_right">
       
          <table>
            <tbody>
              <tr>
                <td>Selling Price ({cart?.cart_item?.length} items)</td>
                 
                <td>{Number(cart?.amount).toFixed(2)}</td>
              </tr>
              {cart?.cart_item?.map((item)=> item?.discount_amount ) ? <tr><td>Discount</td><td>{Number(cart?.discount_amount).toFixed(2)}</td></tr>  :<tr><td></td></tr> }
              <tr>
                <td>Tax</td>
                <td>{Number(cart?.totalTax).toFixed(2)}</td>
              </tr> 
              <tr>
                <td>Delivery Charges</td>
                <td>{Number(cart?.total_delivery_charge).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td>{Number(cart?.calculated_total).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>

      </div>
    </PriceListSection>
  );
};

export default PriceList;

const PriceListSection = styled.section`
  margin: 45px 0 0 0;
  padding-right: 20px;

  .cart_right {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 25px 15px;
    max-width: 100%;
    margin: auto;
  }
  table {
    width: 100%;
  }
  tbody {
    width: 100%;
  }
  tbody tr {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  tbody tr:not(:last-child) {
    margin: 0 0 20px;
  }
  tbody tr td:nth-child(1) {
    font-family: var(--semibold);
    text-align: left;
    padding: 0 10px 20px 0px;
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr td:nth-child(2) {
    font-family: var(--regular);
    text-align: right;
    padding: 0 0 20px 10px;
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr:last-child td:nth-child(1),
  tbody tr:last-child td:nth-child(2) {
    border-bottom: 0px;
  }
  tbody tr:last-child td:nth-child(1) {
    padding: 0 10px 0px 0px;
  }
  tbody tr:last-child td:nth-child(2) {
    padding: 0 0 0px 10px;
  }

  @media screen and (max-width: 500px) {
    .cart_right table {
      width: 100% !important;
    }
  }
`;
