import { createSlice } from "@reduxjs/toolkit";

const singleProduct = createSlice({
  name: "singleProduct",
  initialState: {
    product: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    getSigleProductStart: (state) => {
      state.isFetching = true;
    },
    getSigleProductSuccess: (state, action) => {
      state.isFetching = false;
      state.product = action.payload;
    },
    getSigleProductFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getSigleProductStart,
  getSigleProductSuccess,
  getSigleProductFail,
} = singleProduct?.actions;
export default singleProduct.reducer;

