import React from "react";
import OfferbannerSection from "../OfferBanner/OfferbannerSection";
import OfferBanner2 from "../OfferBanner/OfferBanner2";

const OfferBannerMain = ({offer_type, offer_banner, offer}) => {

// console.log("offer_banner", offer_type)

  switch (offer_type) {
    case "OB1":
      return <OfferbannerSection offer_banner={offer_banner}  />;
    case "OB2":
      return <OfferBanner2 offer={offer} />;

    default:
      return <OfferbannerSection offer_banner={offer_banner}  />;
  }
};

export default OfferBannerMain;
