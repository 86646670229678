import { createSlice } from "@reduxjs/toolkit";

const refundpolicy = createSlice({
  name: "refundpolicy",
  initialState: {
    refundpolicy: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    registerGetAllStart: (state) => {
      state.isFetching = true;
    },
    registerGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.refundpolicy = action.payload;
    },
    registerGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    refundpolicyGetAllStart: (state) => {
      state.isFetching = true;
    },
    refundpolicyGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.refundpolicy = action.payload;
    },
    refundpolicyGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    logoutrefundpolicy: (state, action) => {
      state.refundpolicy = action.payload;
    },
  },
});

export const {
  refundpolicyGetAllStart,
  refundpolicyGetAllSuccess,
  refundpolicyGetAllFail,
  logoutrefundpolicy,
  registerGetAllStart,
  registerGetAllSuccess,
  registerGetAllFail,
} = refundpolicy?.actions;
export default refundpolicy.reducer;
