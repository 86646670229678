import React from "react";
import styled from "styled-components";

import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import {Rate} from "antd";

import vector from "../../Assets/Images/Vector.png";
import group69 from "../../Assets/Images/Group 69.png";

const ClientReview2 = ({homecustom}) => {
  // console.log("homecustom--->", homecustom);

  return (
    <Section>
      <div className="bg_container">
        <img className="background_img1" src={vector} />
      </div>
      <div className="bg_container1">
        <img className="background_img1" src={group69} />
      </div>
      <div className="container">
        <div className="review_left_container">
          <h2>{homecustom?.section_title}</h2>
          {/* <div className="review_bts">
            <button className="left_btn">
              <ArrowLeftOutlined />
            </button>
            <button className="right_btn">
              <ArrowRightOutlined />
            </button>
          </div> */}
        </div>
        <div className="review_right_container">
          <div className="review_left">
            {homecustom?.review_content?.map((item, index) => (
              <div className="review" key={index}>
                <Rate className="rate" disabled defaultValue={item?.rating} />
                <p className="review_desc">{item?.description}</p>
                <p className="review_author">{item?.reviewer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ClientReview2;

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 80px 0;

  .bg_container {
    z-index: -1;
    position: absolute;
    top: 40px;
    right: 30px;
    height: 150px;
    opacity: 0.2;
  }
  .bg_container1 {
    z-index: -1;
    position: absolute;
    bottom: 10px;
    right: 480px;
    height: 150px;
    opacity: 1;
  }

  .bg_container .background_img1 {
    height: 200px;
  }
  .bg_container1 .background_img1 {
    height: 80px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 70px;
    height: 450px;

    .review_left_container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      text-align: end;

      h2 {
        color: #000;
        text-align: right;
        font-family: Brevia;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .review_bts {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;

        button {
          height: 45px;
          width: 45px;
          background-color: transparent;
          border-radius: 50%;
          padding-bottom: 8px;
          border: 0.5px solid black;
        }
      }
    }

    .review_right_container {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      .review_left {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        height: 100%;
      }
      .review_right {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        gap: 20px;
        height: 100%;
      }

      .review {
        padding: 20px;
        border-radius: 24px;
        background: #fcfbfb;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      }

      .rate {
        margin-bottom: 10px;
      }

      .review_desc {
        width: 300px;
        flex-shrink: 0;
        color: #000;
        font-family: Brevia;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 32px */
      }

      .review_author {
        color: #000;
        font-family: Brevia;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 32px */
        margin: 0;
      }
    }
  }
`;
