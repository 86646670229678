import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import {Button} from "antd";
import OfferBanner from "../../Assets/Images/GirlsHub Template/Offer_banner.png";
import API from "../../ApiService/ApiService";

const OfferBanner2 = ({offer}) => {
  // console.log("offer", offer);

  const api = new API();

  const navigate = useNavigate();

  return (
    <OfferbannerALign>
      <div className="home_wrapper">
        {offer?.banner?.map((item, index) =>(
        <Link to={item?.button_link} style={{textDecoration: "none"}}>
          <div className="Offer_Banner_Main" key={index}>
            {/* <div className="OfferTitle"></div>
            <div className="Offer_Coupon"></div>
            <div className="Shop_Button">
            </div> */}
            <img src={api?.rootUrl1 + item?.image} />
          </div>
        </Link>
        ))}
      </div>
    </OfferbannerALign>
  );
};

export default OfferBanner2;

const OfferbannerALign = styled.div`

 .Offer_Banner_Main {
 
 }

`;
