import { createSlice } from "@reduxjs/toolkit";

const company = createSlice({
  name:'company',
  initialState:{
    company:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    companyGetAllStart:(state)=>{
      state.isFetching = true;
    },
    companyGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.company = action.payload;
    },
    companyGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {companyGetAllStart,companyGetAllSuccess,companyGetAllFail} = company.actions;
export default company.reducer;



