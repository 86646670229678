import React from "react";
import {useSelector} from "react-redux";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {Row, Col, Button, Breadcrumb} from "antd";
import {styled} from "styled-components";
import {HomeOutlined, RightOutlined} from "@ant-design/icons";
import Default from "../../Assets/Images/default.png";
import {base_url} from "../../config";
import API from "../../ApiService/ApiService";
import styles from "../../ApiService/Data";

const AllCategories = () => {
  const category = useSelector((state) => state?.category?.category?.data);
  const navigate = useNavigate();
  const api = new API();

  const itemNav = [
    {
      href: "",
      title: (
        <>
          <Link to="/">
            <HomeOutlined style={{position: "relative", top: "-5px"}} />
            &nbsp; Home{" "}
          </Link>
        </>
      ),
    },
    {
      title: "All Category",
    },
  ];

  return (
    <div className="wrapper">
      <div className="shop_head_bar_right" style={{marginTop: "5%"}}>
        <Breadcrumb items={itemNav} />
      </div>
      <br />
      <h3>All Categories</h3>
      {category?.length > 1 ? (
        <TopCategory>
          <div className="top_category_section">
            <div className="wrapper">
              <ul>
                {category?.map((item, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={`/Products?catName=${item?.name
                          .replace(" ", "-")
                          .toLowerCase()
                          .replace(/ /g, "")
                          .replace(/[^\w-]+g/, "")}`}
                      >
                        <div className="top_box">
                          <span>
                            <img
                              src={
                                item?.image ? api.rootUrl1 + item?.image : Default
                              }
                              alt="Popular Categories"
                            />
                          </span>
                          <h4>{item?.name}</h4>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </TopCategory>
      ) : (
        ""
      )}
    </div>
  );
};

export default AllCategories;

const H2 = styled.h2`
  font-size: 32px;
  margin: 0 0 50px;
  font-family: ${styles?.q_bold} !important;
  text-align: center;
  padding: 55px 0 0 0;
  &::before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    height: 46px;
    width: 46px;
    background-size: contain;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0px);
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const TopCategory = styled.section`
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;

  .top_category_section {
    width: 100%;
    display: inline-block;
    position: relative;
  }
  .top_category_section ul {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 25px;
  }
  .top_category_section ul li {
    width: 100%;
    display: inline-block;
    border-radius: 5px;
  }
  .top_category_section ul li span {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 1px solid #898989;
    /* border-radius: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 22px;
  }

  .top_box img {
    padding: 20px;
    /* border-radius: 50%; */
    object-fit: cover;
    /* border: 1px solid #898989; */
  }

  .Top_Section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top_category_section ul li h4 {
    text-align: center;
    color: ${styles?.color};
    font-size: 15px;
    font-family: ${styles?.q_bold};
    text-transform: uppercase;
  }

  @media screen and (max-width: 992px) {
    .top_category_section ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (max-width: 768px) {
    .top_category_section ul {
      grid-template-columns: repeat(3, 1fr);
      gap: 25px 15px;
    }
  }

  @media screen and (max-width: 480px) {
    .top_category_section ul {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 15px;
    }
  }
`;
