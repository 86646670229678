import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/banner_default.png";
import { CaretRightFilled, CaretLeftFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { base_url } from '../../config'
import { styles } from "../../ApiService/Data";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Banner5 = ({ banner }) => {
  // console.log("Banner------------------------->", banner)

  const CustomDot = ({ onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"}>
        <button onClick={() => onClick()}></button>
      </li>
    );
  };
  const CustomRight = ({ onClick }) => (
    <button className="bannerarrow right" onClick={onClick}>
      <CaretRightFilled />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="bannerarrow left" onClick={onClick}>
      <CaretLeftFilled />
    </button>
  );
  const [images, setImage] = useState([]);
  const api = new API();

  return (
    <>
     <BannerSection>
        <section className="Temp2_Banner">
          <Carousel
            infinite
            arrows={false}
            customLeftArrow={<CustomLeft />}
            customRightArrow={<CustomRight />}
            pauseOnHover
            showDots
            autoPlay
            partialVisible={true}
            itemClass="image-item"
            responsive={responsive}
            autoPlaySpeed={5000}
            slidesToSlide={1}
            swipeable
            dotListClass="bannerListDot1"
            customDot={<CustomDot />}
          >
            {banner?.map((e, i) => {
              return (
                <Link to={e?.link} key={`ban_${i}`}>
                  <img
                    draggable={false}
                    style={{ width: "100%", height: "100%" }}
                    src={base_url + e?.image}
                  />
                </Link>
              );
            })}
          </Carousel>
        </section>
      </BannerSection>
    </>
  )
}

export default Banner5;

const BannerSection = styled.div`
  .Temp2_Banner {
    display: inline-block !important;
    width: 100% !important;
    position: relative !important;
  }

  section.Temp2_Banner .bannerListDot1 {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
    top: auto;
    bottom: 15px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 9px;
  }

  section.Temp2_Banner .bannerListDot1 li button {
    height: 8px !important;
    width: 25px !important;
    border: 2px solid #08b56c !important;
    border-radius: 2px !important;
    padding: 0 !important;
    background: transparent;
  }
  section.Temp2_Banner .bannerListDot1 li.active button {
    background: #08b56c !important;
  }
`;
